import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { general, h } from '../../../helpers/index';
import Divider from 'material-ui/Divider/Divider';
import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar/Avatar';
import Chip from 'material-ui/Chip/Chip';
import { toArray } from 'react-emoji-render';
import ReactHtmlParser from 'react-html-parser';
import FontAwesome from "react-fontawesome";
import CommonAttachmentDialog from "../../Common/CommonAttachmentDialog";
import Linkify from 'linkify-it';
import PartialConnectClientSP from "./TaskChatItemComponents/PartialConnectClientSP";
import PartialBizfileEntityUpdate from "./TaskChatItemComponents/PartialBizfileEntityUpdate";
import SanitizeHtml from 'sanitize-html';
import { startLoader, stopLoader } from '../../../actions/config';

const selfChatStyle = {
    backgroundColor: '#DBFAFF'
};

const linkify = new Linkify();

/*======================================================================================================================
 * define react component
 *====================================================================================================================*/
export class PartialTaskChatItem extends React.Component {

    constructor () {
        super();
        this.state = {

        }
    }

    componentDidMount() {
      // this.getTaskLastReadBy();
    }

    // getTaskLastReadBy = () => {
    //   const userId = this.props.currentUser.user_id;
    //
    //   const query = {
    //     "task_fk": this.props.taskMessage.task_task_id,
    //     "user_fk": this.props.currentUser.user_id,
    //     "message_fk": this.props.taskMessage.task_message_message_id,
    //     "created_by": this.props.currentUser.user_id,
    //     "updated_by": this.props.currentUser.user_id
    //   };
    //   TaskModel.createTaskLastReadBy(query, (data) => {
    //       var apiRes = api.handleApiResponse(data, false);
    //       if (apiRes.status === 'ok') {
    //         let lastReadbyUserNameList = [];
    //
    //         const query = {
    //           "task_fk": this.props.taskMessage.task_task_id
    //         };
    //         TaskModel.getTaskLastReadBy(query, (data) => {
    //           var apiRes = api.handleApiResponse(data, false);
    //           if (apiRes.status === 'ok') {
    //             const taskLastReadByUserIdList = apiRes.data.taskLastReadBy.map((item, i) => {
    //               return item.user_fk;
    //             });
    //             const taskLastReadByUserIdFilterList = _.uniq(taskLastReadByUserIdList);
    //             taskLastReadByUserIdFilterList.map((item, i) => {
    //               const requestData = {
    //                 user_id: item
    //               };
    //               UserModel.getUser(requestData, (data) => {
    //                 var apiRes = api.handleApiResponse(data, false);
    //                 if (apiRes.status === 'ok') {
    //                   const userName = `${apiRes.data.user.first_name} ${apiRes.data.user.last_name}`;
    //                   if (!lastReadbyUserNameList.includes(userName)) {
    //                     lastReadbyUserNameList.push(userName);
    //                   }
    //                   const lastReadbyUserNameSortedList = lastReadbyUserNameList.sort();
    //                   const lastReadbyUserNameListStr = lastReadbyUserNameSortedList.join(', ');
    //                   this.setState({
    //                     [userId]: lastReadbyUserNameListStr
    //                   });
    //                 }
    //               });
    //             });
    //           }
    //         });
    //       }
    //   });
    // }

    /* Linkify documentation: http://markdown-it.github.io/linkify-it/doc/#LinkifyIt.prototype.test */
    handleUrlMessage (content) {
        if (linkify.test(content)) {
            let matchUrls = linkify.match(content);
            let textStr = content;
            for (var i=0; i < matchUrls.length; i++) {
                let matchUrl = matchUrls[i];
                let rawText = matchUrl.raw;
                let url = matchUrl.url;
                let htmlUrl = `<a target="_blank" href="${url}">${rawText}</a>`;
                textStr = textStr.replace(rawText, htmlUrl);
            }
            return textStr;
        } else {
            return content;
        }
    }

    autoDownloadDoc = (attachmentFileUrl) => {
        h.general.downloadFile(attachmentFileUrl);
    }

    renderDownloadAllFiles = (taskMessage) => {
        let downloadAllFiles = null;

        if (taskMessage !== undefined) {
            const attachmentList = taskMessage.attachments;
            if (h.notEmpty(attachmentList)) {
                downloadAllFiles = <div onClick={() => this.handleDownloadAllFiles(attachmentList)}><a href="#">Download all files</a></div>;
            }
        }

        return downloadAllFiles;
    }

    handleDownloadAllFiles = (attachmentList) => {
        if (attachmentList && attachmentList.length > 0) {
            const fileUrls = attachmentList.map((item) => {
                const fileUrl = item.file_url;
                return fileUrl;
            });
            this.props.startLoader();
            h.file.downloadRemoteFileUrlsAsZip(fileUrls)
                .then((result) => {return {err: null, result: result};})
                .catch((err) => {return {err: err, result: null};})
                .then((response) => {
                    let {err, result} = response;
                    this.props.stopLoader();
                });
        }
    }

    renderListItem () {
        const { constants, profilePic, name, message, date, isSelf, index, userFrom, task, taskMessage, messageIndex,
            toggleChatInputDisplay, reloadTaskMessages, taskSwimLaneStatus, closeDialog } = this.props;
        let isSelfType = isSelf && Boolean(isSelf) === true;
        let content = toArray(general.nl2br(h.general.fixEmoji(message)));
        let formattedMessage = [];
        for (let i = 0; i < content.length; i++) {
            if (general.isEmpty(content[i].props)) {
                if (typeof content[i] === 'string') {
                    // var finalUrl = this.handleUrlMessage(content[i]);
                    // formattedMessage.push(ReactHtmlParser(finalUrl));
                    formattedMessage.push(ReactHtmlParser(SanitizeHtml(content[i], {
                        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br'],
                        allowedAttributes: { 'a': ['href'] }
                    })));
                }
            } else {
                content[i].props.style.fontSize = 22;
                formattedMessage.push(content[i]);
            }
        }

        const userId = this.props.currentUser.user_id;
        let commonAttachmentDialog = null;
        const taskType = task.task_type;
        const taskSubType =  task.task_sub_type;

        if (
            h.cmpInt(messageIndex, 0) &&
            h.cmpStr(taskType, constants.TASK_TYPES.SYSTEM_GENERATED) &&
            !h.isEmpty(taskSubType)
        ) {
            switch(taskSubType) {
                case constants.TASK_SUB_TYPES.SYSTEM_GENERATED.CLIENT_CONNECT_SP:
                    return (<PartialConnectClientSP
                        index={index}
                        isSelf={isSelf}
                        name={name}
                        date={date}
                        profilePic={profilePic}
                        formattedMessage={formattedMessage}
                        userFrom={userFrom}
                        task={task}
                        taskMessage={taskMessage}
                        toggleChatInputDisplay={toggleChatInputDisplay}
                        reloadTaskMessages={reloadTaskMessages}
                    />);
                case 'bizfile_update_entity':
                    return (<PartialBizfileEntityUpdate
                        index={index}
                        isSelf={isSelf}
                        name={name}
                        date={date}
                        profilePic={profilePic}
                        formattedMessage={formattedMessage}
                        userFrom={userFrom}
                        task={task}
                        taskMessage={taskMessage}
                        toggleChatInputDisplay={toggleChatInputDisplay}
                        reloadTaskMessages={reloadTaskMessages}
                        taskSwimLaneStatus={taskSwimLaneStatus}
                        closeDialog={closeDialog}
                        droppedFiles={this.props.droppedFiles}
                        resetDroppedFiles={this.props.resetDroppedFiles}
                    />);
                default:
            }
        } else {
            return (
                <ListItem
                    disabled={true}
                    className="chat-list-item"
                    innerDivStyle={isSelfType ? selfChatStyle : {}}
                    leftAvatar={<Avatar src={profilePic} />}
                    children={<div key={index}>
                        <label style={{fontWeight: 'bold'}}>{name}</label> <small>{index > 0 ? 'replied ' : 'asked '} {date}</small>
                        <div style={{display: 'table', fontSize: 12}}>
                            <small className="text-muted mb-2">{userFrom}</small>
                            {/*<Chip labelStyle={{lineHeight: 1.5}}></Chip>*/}
                        </div>
                        <br/>
                        <span style={{fontSize: 14}}>{formattedMessage}</span><br/><br/>
                        {/*{*/}
                        {/*  this.state[userId] ?*/}
                        {/*  <div className="text-muted float-right" style={{fontSize: 14}}>*/}
                        {/*    <FontAwesome*/}
                        {/*        className="mr-2"*/}
                        {/*        name="envelope-open"*/}
                        {/*      />*/}
                        {/*    Last read by: {this.state[userId]}*/}
                        {/*  </div>*/}
                        {/*  :*/}
                        {/*  null*/}
                        {/*}*/}
                        {
                            taskMessage !== undefined && h.notEmpty(taskMessage.attachments) ?
                                <div style={{fontSize: 14}}>
                                    {this.renderDownloadAllFiles(taskMessage)}
                                </div>
                                :
                                null
                        }
                        {h.notEmpty(taskMessage) &&
                        h.notEmpty(taskMessage.attachments) &&
                        <div style={{
                            marginTop: 8,
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}>
                            {taskMessage.attachments.map((attachment) => {
                                var fileExt = h.general.getFileExt(attachment.file_name) || '';
                                var isImage = false;
                                var isPdf = false;
                                switch (fileExt) {
                                    case 'jpg':
                                    case 'jpeg':
                                    case 'png':
                                        isImage = true;
                                        break;
                                    // case 'pdf':
                                    //     isPdf = true;
                                    //     break;
                                }
                                if (h.cmpStr(fileExt, 'jpg') || h.cmpStr(fileExt, 'jpeg') || h.cmpStr(fileExt, 'png') || h.cmpStr(fileExt, 'bmp') || h.cmpStr(fileExt, 'pdf')) {
                                    commonAttachmentDialog = (
                                        <CommonAttachmentDialog attachmentUrl={attachment.file_url} isWrapper={true}>
                                            <Chip style={{margin: 4, cursor: 'pointer'}}>
                                                {h.cmpBool(isImage, true) &&
                                                <Avatar src={decodeURIComponent(attachment.file_url)}/>}

                                                {/*{h.cmpBool(isPdf, true) &&*/}
                                                {/*<embed src={attachment.file_url} style={{height: (window.innerHeight - 200), padding: 10}} />}*/}

                                                {h.cmpBool(isImage, false) && h.cmpBool(isPdf, false) &&
                                                <Avatar icon={<FontAwesome name='file-o' style={{marginLeft: 8.2, marginTop: 6.5}}/>} />}

                                                {attachment.file_name}
                                            </Chip>
                                        </CommonAttachmentDialog>
                                    )
                                } else {
                                    commonAttachmentDialog = (
                                        <CommonAttachmentDialog attachmentUrl={attachment.file_url} isWrapper={true}>
                                            <Chip style={{margin: 4, cursor: 'pointer'}}>
                                                {h.cmpBool(isImage, true) &&
                                                <Avatar src={attachment.file_url} />}

                                                {/*{h.cmpBool(isPdf, true) &&*/}
                                                {/*<embed src={attachment.file_url} style={{height: (window.innerHeight - 200), padding: 10}} />}*/}

                                                {h.cmpBool(isImage, false) && h.cmpBool(isPdf, false) &&
                                                <Avatar icon={<FontAwesome name='file-o' style={{marginLeft: 8.2, marginTop: 6.5}}/>} />}

                                                {attachment.file_name}
                                            </Chip>
                                        </CommonAttachmentDialog>
                                    )
                                }
                                return commonAttachmentDialog;
                            })}
                        </div>}

                    </div>}
                />
            );
        }
    }

    render () {
        // const { profilePic, name, message, date, isSelf, index, userFrom, task, taskMessage } = this.props;
        // var isSelfType = isSelf && Boolean(isSelf) === true ? true : false;
        // var content = toArray(general.nl2br(h.general.fixEmoji(message)));
        // var formattedMessage = [];
        // for (var i = 0; i < content.length; i++) {
        //     if (general.isEmpty(content[i].props)) {
        //         if (typeof content[i] === 'string') {
        //             var finalUrl = this.handleUrlMessage(content[i]);
        //             formattedMessage.push(ReactHtmlParser(finalUrl));
        //         }
        //     } else {
        //         content[i].props.style.fontSize = 22;
        //         formattedMessage.push(content[i]);
        //     }
        //     // if (general.isEmpty(content[i].props)) {
        //     //     formattedMessage.push(ReactHtmlParser(content[i]));
        //     // } else {
        //     //     content[i].props.style.fontSize = 22;
        //     //     formattedMessage.push(content[i]);
        //     // }
        // }
        // const userId = this.props.currentUser.user_id;
        // let commonAttachmentDialog = null;

        return (
            <div>
                <Divider/>
                {this.renderListItem()}
            </div>
        );
    }
}

PartialTaskChatItem.propTypes = {
    currentUser: PropTypes.object.isRequired,
    profilePic: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    isSelf: PropTypes.bool,
    index: PropTypes.number.isRequired,
    userFrom: PropTypes.string.isRequired,
    taskMessage: PropTypes.object
};

const mapStateToProps = state => ({
    currentUser: state.user,
    constants: state.constants
});

const mapDispatchToProps = {
    startLoader,
    stopLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartialTaskChatItem));
