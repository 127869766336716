import 'core-js/es6/promise';
import React from 'react';
import $ from 'jquery';
import * as generalHelper from './general';
import * as authHelper from './auth';
import { general, auth } from '../helpers/index';
import { config } from '../configs/config';
import https from "https";
import Axios from 'axios';

/**
 * initialize http interceptors
 */
export class HttpInterceptors extends React.Component {

    componentWillMount() {
        //Initializing Global counter variables for keeping a track of incoming and outgoing requests/responses
        this.requestsSent = 0;
        this.responsesRecieved = 0;
        this.silentRequest = 0;
    }

    componentWillUnmount() {
        //Clearing Global counter variables for keeping a track of incoming and outgoing requests/responses
        this.requestsSent = 0;
        this.silentRequest = 0;
        this.responsesRecieved = 0;
    }
    /**
     * @param {string} method
     * @param {string} url
     * @param {*} data The URL encoded values of the body of the rqeuest
     */
    isAnalyticsRequest(method, url, data) {
        let isAnalyticsRequest = false;
        const KEY_IS_ANALYTICS_TRACKING = 'is_analytics_tracking';
        if(method === 'POST') {
            switch(typeof data) {
                case 'string': {
                    if(data.indexOf(KEY_IS_ANALYTICS_TRACKING) != -1) {
                        isAnalyticsRequest = true;
                    }
                    break;
                }
                case 'object': {
                    if(data && data[KEY_IS_ANALYTICS_TRACKING]) {
                        isAnalyticsRequest = true;
                    }
                }
            }
        }
        return isAnalyticsRequest;

    }
    /**
     * @param {string} method
     * @param {string} url
     * @param {*} data The URL encoded values of the body of the rqeuest
     */
    isSilentRequest(method, url, data) {
        let isSilentRequest = false;
        const KEY_IS_SILENT_REQUEST = 'is_silent_request';
        switch(typeof data) {
            case 'string': {
                if(data.indexOf(KEY_IS_SILENT_REQUEST) != -1) {
                    isSilentRequest = true;
                }
                break;
            }
            case 'object': {
                if(data && data[KEY_IS_SILENT_REQUEST]) {
                    isSilentRequest = true;
                }
            }
        }
        if(!isSilentRequest) {
            if (url && (url.indexOf(KEY_IS_SILENT_REQUEST) != -1 )) {
                isSilentRequest = true;
            }
        }
        return isSilentRequest;
    }
    componentDidMount () {
        //DO NOT READ ANY STATE INFORMATION BEFORE THIS LINE, OR THE INFORMATION WILL BE STALE.
        Axios.interceptors.request.use((config) => {
            // let current_user_service_provider_id = authHelper.getCookie(authHelper.cookieCurrentServiceProviderId);
            let accessToken = authHelper.getAccessToken();
            config.headers = config.headers ? config.headers : {};
            config.headers['x-access-token'] = accessToken;
            config.httpsAgent = new https.Agent({
                rejectUnauthorized: false
            });
            //Attach service provider id to the request

            // let query_start = config.url.indexOf('?');
            // if(query_start === -1) { //No query string so construct
            //     config.url = config.url + `?current_user_service_provider_id=${current_user_service_provider_id}`;
            // }
            // else { //Already have query string so append
            //     config.url = config.url + `&current_user_service_provider_id=${current_user_service_provider_id}`;
            // }

            return config;
        });

        var self = this;
        var beforeSendSettings = {};
        $.ajaxSetup({
            beforeSend: function (xhr, settings) {
                const method = settings.type;
                const url = settings.url;
                const data = settings.data;
                //Increment the counter of requests send irrespective of their type and status
                let isAnalyticsRequest = self.isAnalyticsRequest(method, url, data);
                let isSilentRequest = self.isSilentRequest(method, url, data);
                if(!isAnalyticsRequest) {
                    if(isSilentRequest) {
                        self.silentRequest = self.silentRequest + 1;
                    }
                    else {
                        self.props.updateLoading(true);
                        self.requestsSent = self.requestsSent + 1;
                    }
                }

                // if(isAnalyticsRequest) {
                //     //Do nothing
                // } else if ((!general.isEmpty(settings.data) && settings.data.indexOf('is_silent_request') >= 0) ||
                //     (!general.isEmpty(settings.url) && settings.url.indexOf('is_silent_request') >= 0)
                // ) {
                //     //Just increment the counter of silent requests for subtracting it from normal requests below when responses come
                //     self.silentRequest = self.silentRequest + 1;
                // } else {
                //     self.requestsSent = self.requestsSent + 1;
                // }

                /**
                 * setup header with access token for ajax requests
                 */
                var accessToken = authHelper.getAccessToken();
                if (accessToken && accessToken !== '') {
                    xhr.setRequestHeader("x-access-token", accessToken);
                }
                // silent request no need loading spinner to be shown up
                // value of get at the url, and value of post at the data
                // if ((!general.isEmpty(settings.data) && settings.data.indexOf('is_silent_request') >= 0) ||
                //     (!general.isEmpty(settings.url) && settings.url.indexOf('is_silent_request') >= 0) || isAnalyticsRequest
                // ) {
                //     //If its silent request don't make it contribute to loading of spinner
                // } else {
                //     self.props.updateLoading(true);
                // }
                beforeSendSettings = settings;
            },
            error: function(xhr, status, error) {
                self.props.updateLoading(false);
            },
            timeout: function() {
                self.props.updateLoading(false);
            },
            complete: function (xhr, type) {
                //Increment the counter of responses recieved irrespective of their type and status
                const data = this.data;
                const method = this.type;
                const url = this.url;
                let isAnalyticsRequest = self.isAnalyticsRequest(method, url, data);
                let isSilentRequest = self.isSilentRequest(method, url, data);
                if(!isAnalyticsRequest && !isSilentRequest) {
                    self.responsesRecieved = self.responsesRecieved + 1;
                }
                // if(!general.isEmpty(this.data)){
                //     if (this.data.indexOf('is_analytics_tracking') >= 0){
                //         //Do Nothing
                //     } else {
                //         self.responsesRecieved = self.responsesRecieved + 1;
                //     }
                // } else {
                //     self.responsesRecieved = self.responsesRecieved + 1;
                // }

                var responseJson = xhr.responseJSON || {};
                // check for error response from server side
                if (!general.isEmpty(xhr.responseText)) {
                    // console.log(xhr.responseText);
                    var jsonResponse = JSON.parse(xhr.responseText);
                    // check that there are new access token for storing
                    if (jsonResponse.data && jsonResponse.data.access_token) {
                        auth.setAccessToken(responseJson.data.access_token);
                    }

                    if (jsonResponse.message_code) {
                        // redirects user to login page if user is not authenticated
                        if (jsonResponse.message_code === '2-user-013') {
                            // window.location.href = config.webappUrl;
                            self.props.isAuthenticated(false);
                        }
                        // redirects user to dashboard if user has no permission
                        else if (jsonResponse.message_code === '2-generic-010') {
                            console.log('no permission');
                            self.props.noPermission(false);
                        }
                        // else {
                            // window.location.href = config.webappUrl;
                        // }
                    }

                    // hide loading indicator when server response is an error
                    if (general.cmpStr(jsonResponse.status, 'error')) {
                        self.props.updateLoading(false);
                    }

                }
                else {
                    self.props.isAuthenticated(true);
                    self.props.noPermission(true);
                }


                let pendingResponses = self.requestsSent - self.responsesRecieved;

                // console.log('Network Params Recieved are: ');
                // console.log('=======================')
                // console.log('Actual Requests', self.requestsSent);
                // console.log('Silent Requests', self.silentRequest);
                // console.log('Total Responses Recieved', self.responsesRecieved);
                // console.log('Actual Filtered Responses', responsesContributingToSpinner);
                // console.log('\n\n');

                // value of get at the url, and value of post at the data
                if ((!general.isEmpty(beforeSendSettings.data) && beforeSendSettings.data.indexOf('is_persistent_loading') >= 0) ||
                    (!general.isEmpty(beforeSendSettings.url) && beforeSendSettings.url.indexOf('is_persistent_loading') >= 0)
                ) {

                } else {
                    //When number of outgoing matches number of incoming requests only then stop loading
                    if(pendingResponses === 0) {
                        //All conditions fullfilled so reset counters to 0
                        self.requestsSent = 0;
                        self.silentRequest = 0;
                        self.responsesRecieved = 0;

                        //stop showing the loading spinner
                        self.props.updateLoading(false);
                    }
                }
            }
        });
    }

    render () {
        return (
            <div></div>
        );
    }
}

/**
 * handles api response
 * @param response
 * @param {boolean} [showMessage]
 * @returns {{status: string, data: {}, message: string, message_code: string}}
 */
export function handleApiResponse (response, showMessage) {
    showMessage = typeof showMessage !== typeof undefined ? showMessage : true;
    var originalResponse = response;
    response = response && response.responseJSON ? response.responseJSON : response;
    var resStatus = response && response.status ? response.status : '',
        resData = response && response.data ? response.data : {},
        resMessage = response && response.message ? response.message : '',
        resMessageCode = response && response.message_code ? response.message_code : '',
        returnObject = {
            status: resStatus,
            data: resData,
            message: resMessage,
            message_code: resMessageCode
        };

    // res.responseJSON will determine if response is a XHR error
    if (!originalResponse) {
        if (showMessage) {
            generalHelper.alert('Opps something went wrong', 'error');
        }
        return returnObject;
    }

    // success response
    if (String(resStatus) === "ok") {
        if (showMessage && resMessage) {
            generalHelper.alert(resMessage, 'success');
        }
    }
    // error response
    else {
        if (showMessage && resMessage) {
            generalHelper.alert(resMessage, 'error');
        }
    }

    // store new access token
    // if (returnObject.data && returnObject.data.access_token) {
    //     authHelper.setAccessToken(returnObject.data.access_token);
    // }

    return returnObject;
}
