module.exports = {
    "MAIL_ADVERTISING": "timeline-eventTitle-001",
    "MAIL_GOVERNMENT": "timeline-eventTitle-002",
    "MAIL_BANK": "timeline-eventTitle-003",
    "MAIL_OTHER": "timeline-eventTitle-004",
    "ACRA_COMPANY_INCORPORATION": "timeline-eventTitle-005",
    "ACRA_SSIC_INDUSTRY_CODES_UPDATED": "timeline-eventTitle-006",
    "ACRA_REGISTERED_BUSINESS_ADDRESS_UPDATED": "timeline-eventTitle-007",
    "ACRA_FINANCIAL_YEAR_END_AMENDED": "timeline-eventTitle-008",
    "ACRA_APPOINTMENT_OF_DIRECTOR": "timeline-eventTitle-009",
    "ACRA_RESIGNATION_OF_DIRECTOR": "timeline-eventTitle-010",
    "ACRA_COMPANY_NAME_CHANGE": "timeline-eventTitle-011",
    "ACRA_APPOINTMENT_OF_NEW_DIRECTOR": "timeline-eventTitle-012",
    "ACRA_RESIGNATION_OF_COMPANY_SECRETARY": "timeline-eventTitle-013",
    "ACRA_APPOINTMENT_OF_NEW_COMPANY_SECRETARY": "timeline-eventTitle-014",
    "ACRA_NOTICE_OF_INCORPORATION": "timeline-eventTitle-015",
    "ACRA_BUSINESS_PROFILE": "timeline-eventTitle-016",
    "ACRA_ANNUAL_RETURN": "timeline-eventTitle-017",
    "FORM_OF_DIRECTOR_PARTICULARS": "timeline-eventTitle-018",
    "RESIGNATION_OF_COMPANY_SECRETARY": "timeline-eventTitle-019",
    "RESIGNATION_OF_DIRECTOR": "timeline-eventTitle-020",
    "FORM45_CONSENT_TO_ACT_AS_DIRECTOR": "timeline-eventTitle-021",
    "SHARE_CERTIFICATE": "timeline-eventTitle-022",
    "IRAS_TAX_FILING_FORM_C": "timeline-eventTitle-023",
    "IRAS_TAX_FILING_FORM_C_S": "timeline-eventTitle-024",
    "FIRST_BOARD_RESOLUTION": "timeline-eventTitle-025",
    "APPOINTMENT_OF_NEW_DIRECTOR": "timeline-eventTitle-026",
    "APPOINTMENT_OF_NEW_COMPANY_SECRETARY": "timeline-eventTitle-027",
    "CHANGE_IN_FINANCIAL_YEAR_END": "timeline-eventTitle-028",
    "AUTHORISATION_OF_EMPLOYEE_STOCK_OPTION_PLAN": "timeline-eventTitle-029",
    "ISSUANCE_OF_OPTIONS_CERTIFICATE": "timeline-eventTitle-030",
    "CUSTOM_CORPORATE_ACTIONS": "timeline-eventTitle-031",
    "JOINED_ZAVE": "timeline-eventTitle-032",
    "ANNUAL_GENERAL_MEETING_AGM": "timeline-eventTitle-033",
    "AGREEMENT_OR_CONTRACTS": "timeline-eventTitle-034",
    "APPOINTMENT_OF_AUDITOR_OR_ACCOUNTANT": "timeline-eventTitle-035",
    "RESIGNATION_OF_AUDITOR_OR_ACCOUNTANT": "timeline-eventTitle-036",
    "BANK_ACCOUNT_OPENING": "timeline-eventTitle-037",
    "CHANGE_IN_COMPANY_DETAILS": "timeline-eventTitle-038",
    "CHANGE_OF_REGISTERED_BUSINESS_ADDRESS": "timeline-eventTitle-039",
    "ASIC_NOTIFICATION_OF_AGENT_CEASING_TO_ACT": "timeline-eventTitle-040",
    "CR_ANNUAL_RETURN": "timeline-eventTitle-041",
    "ASIC_ANNUAL_STATEMENTS": "timeline-eventTitle-042",
    "ARTICLES_OF_ASSOCIATION": "timeline-eventTitle-043",
    "CR_COMPANY_INCORPORATION_APPLICATION": "timeline-eventTitle-044",
    "CR_COMPANY_NAME_CHANGE_APPLICATION": "timeline-eventTitle-045",
    "CR_CONSENT_TO_ACT_AS_DIRECTOR": "timeline-eventTitle-046",
    "ASIC_DIVIDENDS_DISTRIBUTIONS": "timeline-eventTitle-047",
    "ASIC_NOTIFICATION_OF_ADDITIONAL_INFORMATION": "timeline-eventTitle-048",
    "ASIC_NOTIFICATION_OF_RESIGNATION": "timeline-eventTitle-049",
    "ASIC_NOTIFICATION_OR_INTENTION_OF_SHARE_BUYBACK": "timeline-eventTitle-050",
    "ASIC_DEREGISTRATION": "timeline-eventTitle-051",
    "ASIC_CHANGE_TO_THE_ULTIMATE_HOLDING_COMPANY": "timeline-eventTitle-052",
    "ASIC_COMPANY_NAME_CHANGE": "timeline-eventTitle-053",
    "ASIC_EXTEND_COMPANY_NAME_RESERVATION": "timeline-eventTitle-054",
    "ASIC_RESERVE_A_NEW_NAME_FOR_THIS_COMPANY": "timeline-eventTitle-055",
    "CR_APPOINTMENT_OF_NEW_COMPANY_SECRETARY": "timeline-eventTitle-056",
    "CR_RESIGNATION_OF_COMPANY_SECRETARY": "timeline-eventTitle-057",
    "CR_CHANGE_OF_COMPANY_SECRETARY_S_PARTICULARS": "timeline-eventTitle-058",
    "ASIC_CHANGE_TO_COMPANY_STATUS": "timeline-eventTitle-059",
    "ASIC_REQUEST_FOR_CORRECTION": "timeline-eventTitle-060",
    "CR_APPOINTMENT_OF_DIRECTOR": "timeline-eventTitle-061",
    "CR_RESIGNATION_OF_DIRECTOR": "timeline-eventTitle-062",
    "CR_CHANGE_OF_DIRECTOR_S_PARTICULARS": "timeline-eventTitle-063",
    "ASIC_WITHDRAWAL_OF_DOCUMENT": "timeline-eventTitle-064",
    "ASIC_ENTITY_ADDRESS_CHANGE": "timeline-eventTitle-065",
    "CR_FINANCIAL_YEAR_END_AMENDED": "timeline-eventTitle-066",
    "CR_CERTIFICATE_OF_INCORPORATION": "timeline-eventTitle-067",
    "CR_CERTIFICATE_OF_CHANGE_OF_NAME": "timeline-eventTitle-068",
    "ASIC_APPOINTMENT_OF_OFFICEHOLDER": "timeline-eventTitle-069",
    "ASIC_CESSATION_OF_OFFICEHOLDER": "timeline-eventTitle-070",
    "ASIC_RESIGNATION_OF_OFFICERS": "timeline-eventTitle-071",
    "ASIC_OFFICER_SHAREHOLDER_NAME_CHANGE": "timeline-eventTitle-072",
    "CR_REGISTERED_BUSINESS_ADDRESS_UPDATED": "timeline-eventTitle-073",
    "CR_SHARE_ALLOTMENT": "timeline-eventTitle-074",
    "ASIC_SHARE_UNIT_CANCELLATION": "timeline-eventTitle-075",
    "ASIC_SHARE_UNIT_ISSUANCE": "timeline-eventTitle-076",
    "ASIC_TRANSFER_OF_SHARES_UNITS": "timeline-eventTitle-077",
    "COMMERCIAL_CONTRACTS": "timeline-eventTitle-079",
    "DEED_OF_VARIATION": "timeline-eventTitle-080",
    "LOANS_TO_DIRECTORS": "timeline-eventTitle-081",
    "POWERS_OF_ATTORNEY": "timeline-eventTitle-082",
    "SELF_MANAGED_SUPER_FUND_DEED": "timeline-eventTitle-083",
    "TRUST_DEEDS": "timeline-eventTitle-084",
    "WILLS": "timeline-eventTitle-085",
    "CASH_IN_SPECIE_CONTRIBUTION": "timeline-eventTitle-086",
    "CASH_IN_SPECIE_WITHDRAWAL": "timeline-eventTitle-087",
    "SELF_MANAGED_SUPER_FUND_WINDING_UP_EXIT": "timeline-eventTitle-088",
    "FINANCIAL_STATEMENTS": "timeline-eventTitle-089",
    "MANAGEMENT_REPORTING": "timeline-eventTitle-090",
    "REGULATORY_REPORTING": "timeline-eventTitle-091",
    "SHARE_ALLOTMENT": "timeline-eventTitle-092",
    "SHARE_TRANSFER": "timeline-eventTitle-093",
    "EMPLOYEE_DECLARATION_FORMS": "timeline-eventTitle-095",
    "PAYROLL_TAX": "timeline-eventTitle-096",
    "ATO_FRINGE_TAX_BENEFITS": "timeline-eventTitle-097",
    "ATO_EARLY_STAGE_INVESTORS_TAX_INCENTIVES": "timeline-eventTitle-098",
    "ATO_R_AND_D_TAX_INCENTIVES": "timeline-eventTitle-099",
    "ATO": "timeline-eventTitle-100",
    "LODGEMENT_OF_BAS": "timeline-eventTitle-101",
    "LODGEMENT_OF_IAS": "timeline-eventTitle-102",
    "LODGEMENT_OF_TAX_RETURN": "timeline-eventTitle-103",
    "SSM_ANNUAL_RETURN": "timeline-eventTitle-104",
    "SSM_APPOINTMENT_OF_DIRECTOR": "timeline-eventTitle-105",
    "SSM_APPOINTMENT_OF_NEW_COMPANY_SECRETARY": "timeline-eventTitle-106",
    "SSM_APPOINTMENT_OF_NEW_DIRECTOR": "timeline-eventTitle-107",
    "SSM_BUSINESS_PROFILE": "timeline-eventTitle-108",
    "SSM_COMPANY_INCORPORATION": "timeline-eventTitle-109",
    "SSM_COMPANY_NAME_CHANGE": "timeline-eventTitle-110",
    "SSM_FINANCIAL_YEAR_END_AMENDED": "timeline-eventTitle-111",
    "SSM_NOTICE_OF_INCORPORATION": "timeline-eventTitle-112",
    "SSM_REGISTERED_BUSINESS_ADDRESS_UPDATED": "timeline-eventTitle-113",
    "SSM_RESIGNATION_OF_COMPANY_SECRETARY": "timeline-eventTitle-114",
    "SSM_RESIGNATION_OF_DIRECTOR": "timeline-eventTitle-115",
    "SSM_SSIC_INDUSTRY_CODES_UPDATED": "timeline-eventTitle-116",
    "FORM48A_CONSENT_TO_ACT_AS_DIRECTOR": "timeline-eventTitle-124",
    "LHDN_FORM_E_C": "timeline-eventTitle-125",
    "EXTRAORDINARY_GENERAL_MEETING_EGM": "timeline-eventTitle-126",
    "MEMBERS_RESOLUTION": "timeline-eventTitle-127",
    "SHAREHOLDERS_RESOLUTION": "timeline-eventTitle-128",
};
