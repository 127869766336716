module.exports = {
    "ACRA_ANNUAL_RETURN": {
        "reference": "ACRA_ANNUAL_RETURN",
        "displayName": "ACRA - Annual Return (FYE DD/MM/YY)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_APPOINTMENT_OF_DIRECTOR": {
        "reference": "ACRA_APPOINTMENT_OF_DIRECTOR",
        "displayName": "ACRA - Appointment of Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_APPOINTMENT_OF_NEW_COMPANY_SECRETARY": {
        "reference": "ACRA_APPOINTMENT_OF_NEW_COMPANY_SECRETARY",
        "displayName": "ACRA - Appointment of new Company Secretary",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_APPOINTMENT_OF_NEW_DIRECTOR": {
        "reference": "ACRA_APPOINTMENT_OF_NEW_DIRECTOR",
        "displayName": "ACRA - Appointment of new Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_BUSINESS_PROFILE": {
        "reference": "ACRA_BUSINESS_PROFILE",
        "displayName": "ACRA - Business Profile",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_COMPANY_INCORPORATION": {
        "reference": "ACRA_COMPANY_INCORPORATION",
        "displayName": "ACRA - Company Incorporated",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_COMPANY_NAME_CHANGE": {
        "reference": "ACRA_COMPANY_NAME_CHANGE",
        "displayName": "ACRA - Company Name Change",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_FINANCIAL_YEAR_END_AMENDED": {
        "reference": "ACRA_FINANCIAL_YEAR_END_AMENDED",
        "displayName": "ACRA - Financial Year End amended",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_NOTICE_OF_INCORPORATION": {
        "reference": "ACRA_NOTICE_OF_INCORPORATION",
        "displayName": "ACRA - Notice of Incorporation",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_REGISTERED_BUSINESS_ADDRESS_UPDATED": {
        "reference": "ACRA_REGISTERED_BUSINESS_ADDRESS_UPDATED",
        "displayName": "ACRA - Registered Business Address updated",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_RESIGNATION_OF_COMPANY_SECRETARY": {
        "reference": "ACRA_RESIGNATION_OF_COMPANY_SECRETARY",
        "displayName": "ACRA - Resignation of Company Secretary",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_RESIGNATION_OF_DIRECTOR": {
        "reference": "ACRA_RESIGNATION_OF_DIRECTOR",
        "displayName": "ACRA - Resignation of Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "ACRA_SSIC_INDUSTRY_CODES_UPDATED": {
        "reference": "ACRA_SSIC_INDUSTRY_CODES_UPDATED",
        "displayName": "ACRA - (SSIC) Industry Codes updated",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "SG"
    },
    "AGREEMENT_OR_CONTRACTS": {
        "reference": "AGREEMENT_OR_CONTRACTS",
        "displayName": "Agreement or Contracts",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "HK"
    },
    "ANNUAL_GENERAL_MEETING_AGM": {
        "reference": "ANNUAL_GENERAL_MEETING_AGM",
        "displayName": "Annual General Meeting (AGM)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "HK"
    },
    "APPOINTMENT_OF_AUDITOR_OR_ACCOUNTANT": {
        "reference": "APPOINTMENT_OF_AUDITOR_OR_ACCOUNTANT",
        "displayName": "Appointment of Auditor or Accountant",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "HK"
    },
    "APPOINTMENT_OF_NEW_COMPANY_SECRETARY": {
        "reference": "APPOINTMENT_OF_NEW_COMPANY_SECRETARY",
        "displayName": "Appointment of new Company Secretary",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "AU"
    },
    "APPOINTMENT_OF_NEW_DIRECTOR": {
        "reference": "APPOINTMENT_OF_NEW_DIRECTOR",
        "displayName": "Appointment of New Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "AU"
    },
    "ARTICLES_OF_ASSOCIATION": {
        "reference": "ARTICLES_OF_ASSOCIATION",
        "displayName": "Articles of Association",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "ASIC_ANNUAL_STATEMENTS": {
        "reference": "ASIC_ANNUAL_STATEMENTS",
        "displayName": "ASIC - Annual Statements",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_APPOINTMENT_OF_OFFICEHOLDER": {
        "reference": "ASIC_APPOINTMENT_OF_OFFICEHOLDER",
        "displayName": "ASIC - Appointment of officeholder",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_CESSATION_OF_OFFICEHOLDER": {
        "reference": "ASIC_CESSATION_OF_OFFICEHOLDER",
        "displayName": "ASIC - Cessation of officeholder",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_CHANGE_TO_COMPANY_STATUS": {
        "reference": "ASIC_CHANGE_TO_COMPANY_STATUS",
        "displayName": "ASIC - Change to company status",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_CHANGE_TO_THE_ULTIMATE_HOLDING_COMPANY": {
        "reference": "ASIC_CHANGE_TO_THE_ULTIMATE_HOLDING_COMPANY",
        "displayName": "ASIC - Change to the ultimate holding company",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_COMPANY_NAME_CHANGE": {
        "reference": "ASIC_COMPANY_NAME_CHANGE",
        "displayName": "ASIC - Company name change",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_DEREGISTRATION": {
        "reference": "ASIC_DEREGISTRATION",
        "displayName": "ASIC - Deregistration",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_DIVIDENDS_DISTRIBUTIONS": {
        "reference": "ASIC_DIVIDENDS_DISTRIBUTIONS",
        "displayName": "ASIC - Dividends / Distributions",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_ENTITY_ADDRESS_CHANGE": {
        "reference": "ASIC_ENTITY_ADDRESS_CHANGE",
        "displayName": "ASIC - Entity address change",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_EXTEND_COMPANY_NAME_RESERVATION": {
        "reference": "ASIC_EXTEND_COMPANY_NAME_RESERVATION",
        "displayName": "ASIC - Extend company name reservation",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_NOTIFICATION_OF_ADDITIONAL_INFORMATION": {
        "reference": "ASIC_NOTIFICATION_OF_ADDITIONAL_INFORMATION",
        "displayName": "ASIC - Notification of additional information",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_NOTIFICATION_OF_AGENT_CEASING_TO_ACT": {
        "reference": "ASIC_NOTIFICATION_OF_AGENT_CEASING_TO_ACT",
        "displayName": "ASIC - Notification of agent ceasing to act",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_NOTIFICATION_OF_RESIGNATION": {
        "reference": "ASIC_NOTIFICATION_OF_RESIGNATION",
        "displayName": "ASIC - Notification of resignation",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_NOTIFICATION_OR_INTENTION_OF_SHARE_BUYBACK": {
        "reference": "ASIC_NOTIFICATION_OR_INTENTION_OF_SHARE_BUYBACK",
        "displayName": "ASIC - Notification or intention of share buyback",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_OFFICER_SHAREHOLDER_NAME_CHANGE": {
        "reference": "ASIC_OFFICER_SHAREHOLDER_NAME_CHANGE",
        "displayName": "ASIC - Officer / Shareholder name change",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_REQUEST_FOR_CORRECTION": {
        "reference": "ASIC_REQUEST_FOR_CORRECTION",
        "displayName": "ASIC - Request for correction",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_RESERVE_A_NEW_NAME_FOR_THIS_COMPANY": {
        "reference": "ASIC_RESERVE_A_NEW_NAME_FOR_THIS_COMPANY",
        "displayName": "ASIC - Reserve a new name for this company",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_RESIGNATION_OF_OFFICERS": {
        "reference": "ASIC_RESIGNATION_OF_OFFICERS",
        "displayName": "ASIC - Resignation of officers",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_SHARE_UNIT_CANCELLATION": {
        "reference": "ASIC_SHARE_UNIT_CANCELLATION",
        "displayName": "ASIC - Share/unit cancellation",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_SHARE_UNIT_ISSUANCE": {
        "reference": "ASIC_SHARE_UNIT_ISSUANCE",
        "displayName": "ASIC - Share/unit issuance",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_TRANSFER_OF_SHARES_UNITS": {
        "reference": "ASIC_TRANSFER_OF_SHARES_UNITS",
        "displayName": "ASIC - Transfer of shares/units",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ASIC_WITHDRAWAL_OF_DOCUMENT": {
        "reference": "ASIC_WITHDRAWAL_OF_DOCUMENT",
        "displayName": "ASIC - Withdrawal of document",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "AU"
    },
    "ATO": {
        "reference": "ATO",
        "displayName": "ATO - Workers Compensation (WorkCover)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "ATO_EARLY_STAGE_INVESTORS_TAX_INCENTIVES": {
        "reference": "ATO_EARLY_STAGE_INVESTORS_TAX_INCENTIVES",
        "displayName": "ATO - Early Stage Investors Tax Incentives",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "ATO_FRINGE_TAX_BENEFITS": {
        "reference": "ATO_FRINGE_TAX_BENEFITS",
        "displayName": "ATO - Fringe Benefits Tax",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "ATO_R_AND_D_TAX_INCENTIVES": {
        "reference": "ATO_R_AND_D_TAX_INCENTIVES",
        "displayName": "ATO - R&D Tax Incentives",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "AUTHORISATION_OF_EMPLOYEE_STOCK_OPTION_PLAN": {
        "reference": "AUTHORISATION_OF_EMPLOYEE_STOCK_OPTION_PLAN",
        "displayName": "Authorisation of Employee Stock Option Plan",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "SHAREHOLDER_RESOLUTION",
        "country_code": "HK"
    },
    "BANK_ACCOUNT_OPENING": {
        "reference": "BANK_ACCOUNT_OPENING",
        "displayName": "Bank Account Opening",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "HK"
    },
    "CASH_IN_SPECIE_CONTRIBUTION": {
        "reference": "CASH_IN_SPECIE_CONTRIBUTION",
        "displayName": "Cash / In specie contribution",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "CASH_IN_SPECIE_WITHDRAWAL": {
        "reference": "CASH_IN_SPECIE_WITHDRAWAL",
        "displayName": "Cash / In specie withdrawal",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "CHANGE_IN_COMPANY_DETAILS": {
        "reference": "CHANGE_IN_COMPANY_DETAILS",
        "displayName": "Change in company details",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "AU"
    },
    "CHANGE_IN_FINANCIAL_YEAR_END": {
        "reference": "CHANGE_IN_FINANCIAL_YEAR_END",
        "displayName": "Change in Financial Year End",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "HK"
    },
    "CHANGE_OF_REGISTERED_BUSINESS_ADDRESS": {
        "reference": "CHANGE_OF_REGISTERED_BUSINESS_ADDRESS",
        "displayName": "Change of Registered Business Address",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "HK"
    },
    "COMMERCIAL_CONTRACTS": {
        "reference": "COMMERCIAL_CONTRACTS",
        "displayName": "Commercial contracts",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "CR_ANNUAL_RETURN": {
        "reference": "CR_ANNUAL_RETURN",
        "displayName": "CR - Annual Return",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_APPOINTMENT_OF_DIRECTOR": {
        "reference": "CR_APPOINTMENT_OF_DIRECTOR",
        "displayName": "CR - Appointment of Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_APPOINTMENT_OF_NEW_COMPANY_SECRETARY": {
        "reference": "CR_APPOINTMENT_OF_NEW_COMPANY_SECRETARY",
        "displayName": "CR- Appointment of new Company Secretary",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_CERTIFICATE_OF_CHANGE_OF_NAME": {
        "reference": "CR_CERTIFICATE_OF_CHANGE_OF_NAME",
        "displayName": "CR - Certificate of Change of Name",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_CERTIFICATE_OF_INCORPORATION": {
        "reference": "CR_CERTIFICATE_OF_INCORPORATION",
        "displayName": "CR- Certificate of Incorporation",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_CHANGE_OF_COMPANY_SECRETARY_S_PARTICULARS": {
        "reference": "CR_CHANGE_OF_COMPANY_SECRETARY_S_PARTICULARS",
        "displayName": "CR - Change of Company Secretary's Particulars",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_CHANGE_OF_DIRECTOR_S_PARTICULARS": {
        "reference": "CR_CHANGE_OF_DIRECTOR_S_PARTICULARS",
        "displayName": "CR - Change of Director's Particulars",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_COMPANY_INCORPORATION_APPLICATION": {
        "reference": "CR_COMPANY_INCORPORATION_APPLICATION",
        "displayName": "CR - Company Incorporation Application",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_COMPANY_NAME_CHANGE_APPLICATION": {
        "reference": "CR_COMPANY_NAME_CHANGE_APPLICATION",
        "displayName": "CR - Company Name Change Application",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_CONSENT_TO_ACT_AS_DIRECTOR": {
        "reference": "CR_CONSENT_TO_ACT_AS_DIRECTOR",
        "displayName": "CR - Consent to Act as Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_FINANCIAL_YEAR_END_AMENDED": {
        "reference": "CR_FINANCIAL_YEAR_END_AMENDED",
        "displayName": "CR - Financial Year End amended",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_REGISTERED_BUSINESS_ADDRESS_UPDATED": {
        "reference": "CR_REGISTERED_BUSINESS_ADDRESS_UPDATED",
        "displayName": "CR - Registered Business Address updated",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_RESIGNATION_OF_COMPANY_SECRETARY": {
        "reference": "CR_RESIGNATION_OF_COMPANY_SECRETARY",
        "displayName": "CR - Resignation of Company Secretary",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_RESIGNATION_OF_DIRECTOR": {
        "reference": "CR_RESIGNATION_OF_DIRECTOR",
        "displayName": "CR - Resignation of Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CR_SHARE_ALLOTMENT": {
        "reference": "CR_SHARE_ALLOTMENT",
        "displayName": "CR - Share Allotment",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "HK"
    },
    "CUSTOM_CORPORATE_ACTIONS": {
        "reference": "CUSTOM_CORPORATE_ACTIONS",
        "displayName": "Custom Corporate Actions",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "CUSTOM_CORPORATE_ACTIONS",
        "country_code": "COMMON"
    },
    "DEED_OF_VARIATION": {
        "reference": "DEED_OF_VARIATION",
        "displayName": "Deed of variation",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "EMPLOYEE_DECLARATION_FORMS": {
        "reference": "EMPLOYEE_DECLARATION_FORMS",
        "displayName": "ATO - Employee Declaration forms",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "FINANCIAL_STATEMENTS": {
        "reference": "FINANCIAL_STATEMENTS",
        "displayName": "Financial Statements",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "REPORTING",
        "country_code": "HK"
    },
    "FIRST_BOARD_RESOLUTION": {
        "reference": "FIRST_BOARD_RESOLUTION",
        "displayName": "First Board Resolution",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "AU"
    },
    "FORM_OF_DIRECTOR_PARTICULARS": {
        "reference": "FORM_OF_DIRECTOR_PARTICULARS",
        "displayName": "Form of Director's Particulars (First Name Last Name)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "SG"
    },
    "FORM45_CONSENT_TO_ACT_AS_DIRECTOR": {
        "reference": "FORM45_CONSENT_TO_ACT_AS_DIRECTOR",
        "displayName": "Form 45 - Consent to Act as Director (First Name Last Name)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "SG"
    },
    "IRAS_TAX_FILING_FORM_C": {
        "reference": "IRAS_TAX_FILING_FORM_C",
        "displayName": "IRAS - Tax Filing (Form C)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "SG"
    },
    "IRAS_TAX_FILING_FORM_C_S": {
        "reference": "IRAS_TAX_FILING_FORM_C_S",
        "displayName": "IRAS - Tax Filing (Form C-S)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "SG"
    },
    "ISSUANCE_OF_OPTIONS_CERTIFICATE": {
        "reference": "ISSUANCE_OF_OPTIONS_CERTIFICATE",
        "displayName": "Issuance of Options Certificate to (First Name Last Name)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "SINGLE_DIRECTOR_APPROVAL",
        "country_code": "HK"
    },
    "JOINED_ZAVE": {
        "reference": "JOINED_ZAVE",
        "displayName": "Joined Zave",
        "category": "GENERIC",
        "type": "GENERIC",
        "country_code": "COMMON"
    },
    "LOANS_TO_DIRECTORS": {
        "reference": "LOANS_TO_DIRECTORS",
        "displayName": "Loans to directors",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "LODGEMENT_OF_BAS": {
        "reference": "LODGEMENT_OF_BAS",
        "displayName": "ATO - Lodgement of BAS",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "LODGEMENT_OF_IAS": {
        "reference": "LODGEMENT_OF_IAS",
        "displayName": "ATO - Lodgement of IAS",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "LODGEMENT_OF_TAX_RETURN": {
        "reference": "LODGEMENT_OF_TAX_RETURN",
        "displayName": "ATO - Lodgement of Tax Return",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "MAIL_ADVERTISING": {
        "reference": "MAIL_ADVERTISING",
        "displayName": "Mail (Advertising)",
        "category": "MAIL_GENERIC",
        "type": "MAIL",
        "country_code": "COMMON"
    },
    "MAIL_BANK": {
        "reference": "MAIL_BANK",
        "displayName": "Mail (Bank)",
        "category": "MAIL_GENERIC",
        "type": "MAIL",
        "country_code": "COMMON"
    },
    "MAIL_GOVERNMENT": {
        "reference": "MAIL_GOVERNMENT",
        "displayName": "Mail (Government)",
        "category": "MAIL_GENERIC",
        "type": "MAIL",
        "country_code": "COMMON"
    },
    "MAIL_OTHER": {
        "reference": "MAIL_OTHER",
        "displayName": "Mail (Other)",
        "category": "MAIL_GENERIC",
        "type": "MAIL",
        "country_code": "COMMON"
    },
    "MANAGEMENT_REPORTING": {
        "reference": "MANAGEMENT_REPORTING",
        "displayName": "Management Reporting",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "REPORTING",
        "country_code": "HK"
    },
    "PAYROLL_TAX": {
        "reference": "PAYROLL_TAX",
        "displayName": "ATO - Payroll Tax",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "AU"
    },
    "POWERS_OF_ATTORNEY": {
        "reference": "POWERS_OF_ATTORNEY",
        "displayName": "Powers of attorney",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "REGULATORY_REPORTING": {
        "reference": "REGULATORY_REPORTING",
        "displayName": "Regulatory Reporting",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "REPORTING",
        "country_code": "HK"
    },
    "RESIGNATION_OF_AUDITOR_OR_ACCOUNTANT": {
        "reference": "RESIGNATION_OF_AUDITOR_OR_ACCOUNTANT",
        "displayName": "Resignation of Auditor or Accountant",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "HK"
    },
    "RESIGNATION_OF_COMPANY_SECRETARY": {
        "reference": "RESIGNATION_OF_COMPANY_SECRETARY",
        "displayName": "Resignation of Company Secretary (First Name Last Name)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "SG"
    },
    "RESIGNATION_OF_DIRECTOR": {
        "reference": "RESIGNATION_OF_DIRECTOR",
        "displayName": "Resignation of Director (First Name Last Name)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "SG"
    },
    "SELF_MANAGED_SUPER_FUND_DEED": {
        "reference": "SELF_MANAGED_SUPER_FUND_DEED",
        "displayName": "Self Managed Super Fund - Deed",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "SELF_MANAGED_SUPER_FUND_WINDING_UP_EXIT": {
        "reference": "SELF_MANAGED_SUPER_FUND_WINDING_UP_EXIT",
        "displayName": "Self Managed Super Fund - Winding up / exit",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "SHARE_ALLOTMENT": {
        "reference": "SHARE_ALLOTMENT",
        "displayName": "Share Allotment",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "SHAREHOLDER_RESOLUTION",
        "country_code": "HK"
    },
    "SHARE_CERTIFICATE": {
        "reference": "SHARE_CERTIFICATE",
        "displayName": "Share Certificate #[Number]",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "HK"
    },
    "SHARE_TRANSFER": {
        "reference": "SHARE_TRANSFER",
        "displayName": "Share Transfer",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "SHAREHOLDER_RESOLUTION",
        "country_code": "HK"
    },
    "TRUST_DEEDS": {
        "reference": "TRUST_DEEDS",
        "displayName": "Trust Deeds",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "WILLS": {
        "reference": "WILLS",
        "displayName": "Wills",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "AU"
    },
    "SSM_ANNUAL_RETURN": {
        "reference": "SSM_ANNUAL_RETURN",
        "displayName": "SSM - Annual Return (FYE DD/MM/YY)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_APPOINTMENT_OF_DIRECTOR": {
        "reference": "SSM_APPOINTMENT_OF_DIRECTOR",
        "displayName": "SSM - Appointment of Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_APPOINTMENT_OF_NEW_COMPANY_SECRETARY": {
        "reference": "SSM_APPOINTMENT_OF_NEW_COMPANY_SECRETARY",
        "displayName": "SSM - Appointment of new Company Secretary",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_APPOINTMENT_OF_NEW_DIRECTOR": {
        "reference": "SSM_APPOINTMENT_OF_NEW_DIRECTOR",
        "displayName": "SSM - Appointment of new Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_BUSINESS_PROFILE": {
        "reference": "SSM_BUSINESS_PROFILE",
        "displayName": "SSM - Business Profile",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_COMPANY_INCORPORATION": {
        "reference": "SSM_COMPANY_INCORPORATION",
        "displayName": "SSM - Company Incorporated",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_COMPANY_NAME_CHANGE": {
        "reference": "SSM_COMPANY_NAME_CHANGE",
        "displayName": "SSM - Company Name Change",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_FINANCIAL_YEAR_END_AMENDED": {
        "reference": "SSM_FINANCIAL_YEAR_END_AMENDED",
        "displayName": "SSM - Financial Year End amended",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_NOTICE_OF_INCORPORATION": {
        "reference": "SSM_NOTICE_OF_INCORPORATION",
        "displayName": "SSM - Notice of Incorporation",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_REGISTERED_BUSINESS_ADDRESS_UPDATED": {
        "reference": "SSM_REGISTERED_BUSINESS_ADDRESS_UPDATED",
        "displayName": "SSM - Registered Business Address updated",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_RESIGNATION_OF_COMPANY_SECRETARY": {
        "reference": "SSM_RESIGNATION_OF_COMPANY_SECRETARY",
        "displayName": "SSM - Resignation of Company Secretary",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_RESIGNATION_OF_DIRECTOR": {
        "reference": "SSM_RESIGNATION_OF_DIRECTOR",
        "displayName": "SSM - Resignation of Director",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "SSM_SSIC_INDUSTRY_CODES_UPDATED": {
        "reference": "SSM_SSIC_INDUSTRY_CODES_UPDATED",
        "displayName": "SSM - (SSIC) Industry Codes updated",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "COMPANY_REGISTRY",
        "country_code": "MY"
    },
    "FORM48A_CONSENT_TO_ACT_AS_DIRECTOR": {
        "reference": "FORM48A_CONSENT_TO_ACT_AS_DIRECTOR",
        "displayName": "Form 48A - Consent to Act as Director (First Name Last Name)",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "GENERAL_COMPLIANCE",
        "country_code": "MY"
    },
    "LHDN_FORM_E_C": {
        "reference": "LHDN_FORM_E_C",
        "displayName": "LHDN - Form e-C",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "TAX_DEPARTMENT",
        "country_code": "MY"
    },
    "EXTRAORDINARY_GENERAL_MEETING_EGM": {
        "reference": "EXTRAORDINARY_GENERAL_MEETING_EGM",
        "displayName": "Extraordinary General Meeting",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "COMMON"
    },
    "MEMBERS_RESOLUTION": {
        "reference": "MEMBERS_RESOLUTION",
        "displayName": "Members Resolution",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "COMMON"
    },
    "SHAREHOLDERS_RESOLUTION": {
        "reference": "SHAREHOLDERS_RESOLUTION",
        "displayName": "Shareholders Resolution",
        "category": "CORPORATE_ACTION_GENERIC",
        "type": "BOARD_RESOLUTION",
        "country_code": "COMMON"
    },
};
