import $ from 'jquery';
import { config } from '../configs/config';

/**
 * gets company information
 * @param data
 * @param callback
 */
export function getCompanyInfoSGP (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-business-info',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check entity url name by entity name
 * @param data
 * @param callback
 */
export function getEntityUrlName (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-url-by-entity-name',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check company home url availability
 * @param data
 * @param callback
 */
export function checkHomeUrl (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/check-url-availability',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * gets company profile
 * @param data
 * @param callback
 */
export function getCompanyProfile (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-profile',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * updates company profile
 * @param data
 * @param callback
 */
export function updateCompanyProfile (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/company/update-profile',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * gets company rep
 * @param data
 * @param callback
 */
export function getCompanyRep (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-rep',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * updates company profile
 * @param data
 * @param callback
 */
export function updateCompanyRep (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/company/update-rep',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * gets company users
 * @param data
 * @param callback
 */
export function getUsers (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-users',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * creates company user
 * @param data
 * @param callback
 */
export function createUser (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/company/create-user',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * deletes company user
 * @param data
 * @param callback
 */
export function deleteUser (data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + '/v1.0.0/company/delete-user?user_id=' + data.user_id + '&entity_id=' + data.entity_id,
        dataType: "json",
        // data: data,
        error: callback
    }).done(callback);
}

/**
 * resend user invitation
 * @param data
 * @param callback
 */
export function resendUserInvite (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/company/resend-user-invite',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * gets company bookkeepers
 * @param data
 * @param callback
 */
export function getBookkeepers (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-bookkeepers',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get bill, expensess, and task notification by entity id
 * @param data
 * @param callback
 */
export function getNotifications(data, callback) {
    data.is_silent_request = 1;
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-notifications',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get contacts
 * @param data
 * @param callback
 */
export function getContacts (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-contacts',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * search for existing entities using company name
 * @param data
 * @param callback
 */
export function searchCompanyName (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/search-company-name',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get entity director information by token
 * @param data
 * @param callback
 */
export function getDirectorByToken (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/director/token',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * submit entity director kyc form
 * @param data
 * @param callback
 */
export function submitDirector (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/company/entity/director/submit',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update entity director kyc form
 * @param data
 * @param callback
 */
export function updateDirector (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/company/entity/director/update',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get entity shareholder information by token
 * @param data
 * @param callback
 */
export function getShareholderByToken (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/shareholder/token',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * submit entity shareholder kyc form
 * @param data
 * @param callback
 */
export function submitShareholder (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/company/entity/shareholder/submit',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update entity shareholder kyc form
 * @param data
 * @param callback
 */
export function updateShareholder (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/company/entity/shareholder/update',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * entity request demo for Zave services
 * @param data
 * @param callback
 */
export function serviceDemo (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/company/entity/service/demo',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * entity request trial for Zave services
 * @param data
 * @param callback
 */
export function serviceTrial (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/company/entity/service/trial',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity directors by entity id
 * @param data
 * @param callback
 */
export function getDirectorsByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/get-directors',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity shareholders by entity id
 * @param data
 * @param callback
 */
export function getShareholdersByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/get-shareholders',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity bank accounts
 * @param data
 * @param callback
 */
export function getBankAccounts (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/bank-accounts',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity
 * @param {*} data 
 * @param {*} callback 
 */
export function getEntity(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-entity',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get service provider
 * @param data
 * @param callback
 */
export function getServiceProvider(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/serviceprovider',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get entity service provider invite
 * @param data
 * @param callback
 */
export function getEntitySPInvite(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/service-provider-invite',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create entity service provider invite
 * @param data
 * @param callback
 */
export function createEntitySPInvite(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/company/entity/service-provider-invite',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}