import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

const BUTTON_TYPE = {
	RAISED_BUTTON_PRIMARY: 1,
	RAISED_BUTTON_SECONDARY: 2,
	FLAT_BUTTON_PRIMARY: 3,
	FLAT_BUTTON_SECONDARY: 4
};

export class PartialUIButton extends React.Component {

	render () {
		let { props } = this;
		let { theme, buttonType = 1 } = props;

		let style = {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			height: 40,
		};
		let buttonStyle = {
			borderRadius: 20,
			height: 40,
			lineHeight: '38px'
		};
		let labelStyle = {
			textTransform: 'captialize',
			fontSize: 14
		};
		let overlayStyle = {
			borderRadius: 20
		};

		let inStyle = props.style || {};
		style = Object.assign({}, inStyle, style);

		let inLabelStyle = props.labelStyle || {};
		labelStyle = Object.assign({}, inLabelStyle, labelStyle);

		//Raised button only
		let inButtonStyle = props.buttonStyle || {};
		buttonStyle = Object.assign({}, inButtonStyle, buttonStyle);

		//Raised button only
		let inOverlayStyle = props.overlayStyle || {};
		overlayStyle = Object.assign({}, inOverlayStyle, overlayStyle);

		switch (buttonType) {
			default:
			case BUTTON_TYPE.RAISED_BUTTON_PRIMARY: //[DEFAULT]
				return <RaisedButton
					{...props}
					style={style}
					buttonStyle={buttonStyle}
					labelStyle={labelStyle}
					overlayStyle={overlayStyle}
				/>;
			case BUTTON_TYPE.RAISED_BUTTON_SECONDARY:
				buttonStyle.backgroundColor = theme.palette.sidebarActiveColor;
				buttonStyle.borderColor = theme.palette.primary1Color;
				buttonStyle.borderStyle = 'solid';
				buttonStyle.borderWidth = 1;
				labelStyle.color = theme.palette.primary1Color;
				return <RaisedButton
					{...props}
					style={style}
					buttonStyle={buttonStyle}
					labelStyle={labelStyle}
					overlayStyle={overlayStyle}
				/>;
			case BUTTON_TYPE.FLAT_BUTTON_PRIMARY:
			case BUTTON_TYPE.FLAT_BUTTON_SECONDARY:
				return <FlatButton
					{...props}
					style={style}
					labelStyle={labelStyle}
				/>;
		}
	}

}

PartialUIButton.propTypes = {
	buttonType: PropTypes.bool,
	primary: PropTypes.bool,
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func
};

const mapStateToProps = state => ({
	// constants: state.constants,
	// currentUser: state.user,
	// currentEntity: state.company.currentEntity,
	theme: state.config.theme,
	// currentServiceProvider: state.serviceProvider ? state.serviceProvider.currentServiceProvider : null
});

const mapDispatchToProps = {
	// setBreadcrumbs: ConfigActions.setBreadcrumb
};

export default connect(mapStateToProps, mapDispatchToProps)(PartialUIButton);
