import Raven from 'raven-js';
import * as general from '../helpers/general';

var log = {

    setUser: function (user) {
        var logger = Raven;
        var loggerUser = {};
        var currentContext = logger.getContext() || {};
        if (general.isEmpty(user)) return;
        if (user.user_id) {
            loggerUser["id"] = user.user_id;
        }
        if (user.email) {
            loggerUser["email"] = user.email;
        }
        if (user.first_name) {
            loggerUser["First Name"] = user.first_name;
        }
        if (user.user_id) {
            loggerUser["Last Name"] = user.last_name;
        }
        if (user.access_token_id) {
            loggerUser["Access Token ID"] = user.access_token_id;
        }
        currentContext.user = Object.assign({}, currentContext.user, loggerUser);
        logger.setUserContext(currentContext.user);
    },

    setEntity: function (entity) {
        var logger = Raven;
        var loggerEntity = {};
        var currentContext = logger.getContext() || {};
        if (general.isEmpty(entity)) return;
        if (entity.entity_id) {
            loggerEntity["Entity Id"] = entity.entity_id;
        }
        if (entity.entity_name) {
            loggerEntity["Entity Name"] = entity.entity_name;
        }
        currentContext.user = Object.assign({}, currentContext.user, loggerEntity);
        logger.setUserContext(currentContext.user);
    },

    setExtra: function (data) {
        var logger = Raven;
        var currentContext = logger.getContext() || {};
        if (general.isEmpty(data)) return;
        currentContext.user = Object.assign({}, currentContext.user, data);
        logger.setUserContext(currentContext.user);
    },

    log: function (data) {
        // if (!general.cmpStr(general.getEnv(), 'development')) {
        //     var level = 'info';
        //     var subPath = data && data.subPath ? '/' + data.subPath : '';
        //     Raven.captureMessage(window.location.pathname + subPath, {
        //         level: level,
        //         tags: {
        //             path: window.location.pathname + subPath,
        //         }
        //     });
        // }
    }

};

export default log;