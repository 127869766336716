export async function load() {
    if(!window.recaptcha) {
        var scriptContainer = document.getElementById('recaptcha-script-container');
        var script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        scriptContainer.appendChild(script);
    }
    await new Promise((resolve, reject) => {
        setTimeout(() => resolve(), 1000);
    });
}
const recaptchaHelper = {
    load
};
export default recaptchaHelper;