import * as UserModel from '../models/user';
import jwt from 'jsonwebtoken';
import { getEnv, findGetParameter } from '../helpers/general.js';

export const cookiePrefix = 'M3Y1Y9CRK0ZJL26LGU06_' + getEnv() + '_ANWebApp_';
export const cookieAccessToken = 'AlphaTango';
export const cookieRememberMe = 'RomeoMight';
export const cookieEmail = 'Echo';
export const cookieLoggedInBefore = 'LimaIndiaBravo';
export const cookieCurrentEntityId = 'CharlieEchoIndia';
export const cookieIncorporationFormId = 'IndiaFoxtrotIndia';

/**
 * checks if user is authenticated by checking for access token in cookie
 * @returns {boolean}
 */
export function isAuthenticated () {
    var accessToken = this.getAccessToken();
    if (accessToken && accessToken !== '') {
        return true;
    } else {
        return false;
    }
}

/**
 * sign out function
 * @param callback
 */
export function signOut (callback) {
    var data = { device_type: 'web' };
    UserModel.signout(data, callback);
}

/**
 * get all cookies and set into an array
 * @returns {{}}
 */
export function getCookies () {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i=0; i<pairs.length; i++){
        var pair = pairs[i].split("=");
        cookies[pair[0].trim()] = unescape(pair[1]);
    }
    return cookies;
}

/**
 * store a cookie using key and value
 * @param key
 * @param value
 */
export function setCookie (key, value) {
    // while (helpers.general.notEmpty(this.getCookie(key))) {
        this.deleteCookie(key);
    // }
    document.cookie = this.cookiePrefix + key + '='+ value +';';
}

/**
 * get cookie value by key
 * @param key
 * @returns {string}
 */
export function getCookie (key) {
    var cookies = this.getCookies(),
        cookieKey = this.cookiePrefix + key,
        accessToken = cookies[cookieKey] ? cookies[cookieKey] : '';
    return accessToken;
}

/**
 * delete a cookie by key
 * @param key
 */
export function deleteCookie (key) {
    document.cookie = this.cookiePrefix + key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * delete a cookie by key with no prefix
 * @param key
 */
export function deleteCookieRaw (key) {
    document.cookie = key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * store access token
 * @param accessToken
 */
export function setAccessToken (accessToken) {
    this.deleteAccessToken();
    this.setCookie(this.cookieAccessToken, accessToken);
}

/**
 * delete access token
 */
export function deleteAccessToken () {
    this.deleteCookie(this.cookieAccessToken);
}

/**
 * get access token
 * @returns {string}
 */
export function getAccessToken () {
    return this.getCookie(this.cookieAccessToken);
}

/**
 * get user information from jwt
 * @returns {{}}
 */
export function getUserInfo (accessToken) {
    var accessToken = accessToken || this.getAccessToken();
    var decoded = jwt.decode(accessToken);
    return decoded && decoded.atd && decoded.atd.user ? decoded.atd.user : {};
}

/**
 * redirect user with non-authenticated session to login page
 * @param {string} [redirectDomain] Domain to overwrite default redirect behaviour
 */
export function redirectToLogin (redirectDomain) {
    // redirect user back to login page
    let redirectRoute = findGetParameter('redirect_route');
    let currentPathnameEncoded = encodeURIComponent(window.location.pathname);
    let currentSearchEncoded = encodeURIComponent(window.location.search);
    let redirectTo = `${redirectDomain || ''}/login`;
    if (!redirectRoute) redirectTo = `${redirectTo}?redirect_route=${currentPathnameEncoded}&redirect_search=${currentSearchEncoded}`;
    window.location.href = redirectTo;
}