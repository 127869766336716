const ZaveCommon = require('zave_common');
export default function expense (state = {}, action) {
    let stateData = { ...state };
    switch (action.type) {
        case 'SET_CURRENT_CLAIMS':
            return {
                ...state,
                currentClaims: action.currentClaims.data,
                currentClaimsGroup: action.currentClaims.group,
                // currentClaimsSummary: action.currentClaims.summary
            };
        case 'SET_SUBMITTED_CLAIMS':
            return {
                ...state,
                submittedClaims: action.submittedClaims.data,
                submittedClaimsGroup: action.submittedClaims.group,
                // submittedClaimsSummary: action.submittedClaims.summary
            };
        case 'SET_AUTHORISED_CLAIMS':
            return {
                ...state,
                authorisedClaims: action.authorisedClaims.data,
                authorisedClaimsGroup: action.authorisedClaims.group,
                // authorisedClaimsSummary: action.authorisedClaims.summary
            };
        case 'SET_PREVIOUS_CLAIMS':
            return {
                ...state,
                previousClaims: action.previousClaims.data,
                previousClaimsGroup: action.previousClaims.group,
                // previousClaimsSummary: action.previousClaims.summary
            };
        case 'SET_EXPENSES_SUMMARY':
            return {
                ...state,
                currentClaimsSummary: {},
                submittedClaimsSummary: {
                    total: action.expensesSummary.submitted_expenses_total,
                    count: action.expensesSummary.submitted_expenses_count
                },
                authorisedClaimsSummary: {
                    total: action.expensesSummary.authorised_expenses_total,
                    count: action.expensesSummary.authorised_expenses_count
                },
                previousClaimsSummary: {
                    total: action.expensesSummary.previous_expenses_total,
                    count: action.expensesSummary.previous_expenses_count
                }
            };
        case 'SET_EXPENSE_USERS':
            stateData[action.entityId] = stateData[action.entityId] || { expenseUsers: [] };
            stateData[action.entityId].expenseUsers = action.expenseUsers;
            return stateData;
        // case 'SET_EXPENSE_BY_USER':
        //     stateData[action.entityId] = stateData[action.entityId] || { expenseUsers: [] };
        //     stateData[action.entityId].expenses = stateData[action.entityId].expenses || {};
        //     stateData[action.entityId].expenses[action.userId] = stateData[action.entityId].expenses[action.userId] || {};
        //     stateData[action.entityId].expenses[action.userId][action.expenseType] = stateData[action.entityId].expenses[action.userId][action.expenseType] || [];
        //     return stateData;
        default:
            return state;
    }
}
