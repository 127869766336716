/**
 * Sort list of expense users in descending order
 * @param array expenseUsers
 * @param string key
 * @returns expenseUsers
 */
export function sortExpenseUsers (expenseUsers, key) {
	expenseUsers.sort((a, b) => {
		if (a.expense_summary && b.expense_summary) {
			if (Number(a.expense_summary[key]) < Number(b.expense_summary[key])) {
				return 1;
			} else if (Number(a.expense_summary[key]) > Number(b.expense_summary[key])) {
				return -1;
			} else {
				return 0;
			}
		} else if (!a.expense_summary) {
			return 1;
		} else if (!b.expense_summary) {
			return -1;
		} else {
			return 0;
		}
	});
	return expenseUsers;
}
