export default function analytics (state = {}, action) {
    switch (action.type) {
        case 'SET_LAST_ENTITY':
            return {
                ...state,
                lastTrackedActionEntity: action.last_entity
            };
        default:
            return state;
    }
}
