export async function load() {
    if(!window.Stripe) {
        var scriptContainer = document.getElementById('stripe-script-container');
        var script = document.createElement('script');
        script.id = 'stripe-js';
        script.src = 'https://js.stripe.com/v3/';
        scriptContainer.appendChild(script);
    }
    await new Promise((resolve, reject) => {
        setTimeout(() => resolve(), 1000); //Wait for 1 second before attempting to load the system
    });
}
const stripeHelper = {
    load
};
export default stripeHelper;