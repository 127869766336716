export const setBankTransactions = (bankTransactions) => ({
    type: 'SET_BANK_TRANSACTIONS',
    bankTransactions: bankTransactions
});

export const setVerifications = (verifications) => ({
    type: 'SET_VERIFICATIONS',
    verifications: verifications
});

export const setVerificationMessages = (verificationMessages) => ({
    type: 'SET_VERIFICATION_MESSAGES',
    verificationMessages: verificationMessages
});