module.exports = {
    INDIVIDUAL_GENERAL: { //Generally accepted individual identity documents for all jurisdictions
        PASSPORT_PHOTO_PAGE: 'passport_photo_page',
        PROOF_OF_ADDRESS: 'proof_of_address',
        NRIC_FRONT: 'nric_front',
        NRIC_BACK: 'nric_back'
    },
    CORPORATE_GENERAL: { //Generally accepted corporate identity documents for all jurisdictions
        ACRA_BIZFILE: 'acra_bizfile',
        COMPANY_CONSTITUTION: 'company_constitution',
        CERTIFICATE_OF_INCORPORATION: 'certificate_of_incorporation',
        CERTIFICATE_OF_INCUMBENCY: 'certificate_of_incumbency',
        COMPANY_CONSTITUTION_BYLAWS: 'company_constitution_bylaws'
    }
};