import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { general, api, form } from '../../../helpers/index';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import Popover from 'material-ui/Popover';
import Chip from 'material-ui/Chip';
import EditorAttachFile from 'material-ui/svg-icons/editor/attach-file';
import ImageTagFaces from 'material-ui/svg-icons/image/tag-faces';
import AutoComplete from 'material-ui/AutoComplete';
import * as TaskModel from '../../../models/task';
import * as UploadModel from '../../../models/upload';
import * as PrivateTaskModel from '../../../models/privateTask';
import * as TaskUserModel from '../../../models/taskUser';
import * as TaskUserGroupModel from '../../../models/taskUserGroup';
import { Picker } from 'emoji-mart';
import $ from 'jquery';
// import taskBotAvatar from '../../../assets/images/taskbot/zaveBeeDP.png';
import Avatar from 'material-ui/Avatar';
import PartialUIButton from '../UI/Button';

// import { toArray } from 'react-emoji-render';
import { helpers, h } from "../../../helpers";
import { uniq } from 'lodash';
import ZaveCommon from 'zave_common';
import Dialog from 'material-ui/Dialog';

export class PartialTaskChatInput extends React.Component {

    constructor () {
        super();
        var additionalFields = {
            attachedFiles: [],
            isCreateTaskButtonDisabled: false,
            isSendTaskButtonDisabled: false,
            userSelectTalkToBot: false,
            fields: {
                type: { value: 'public', dirty: true },
                subject: { value: '', dirty: true },
                message: { value: '' }
            },
            entityNameAndEntityUserGroupDataSource: [],
            serviceProviderNameAndServiceProviderUserGroupDataSource: [],
            selectedEntityNameAndEntityUserGroupList: [],
            selectedServiceProviderNameAndServiceProviderUserGroupList: [],
            showBroadcastSuccessErrorDialog: { visibility: false, type: 'none' },
            taskBroadcastChipEntities: []
        };
        this.state = general.initState(additionalFields);
    }

    componentWillMount() {
        const { preAttachedFiles } = this.props;    
        if(h.notEmpty(preAttachedFiles)) {
            this.setState({ attachedFiles: [preAttachedFiles] });
        } 
    }

    componentWillReceiveProps (nextProps) {
        // h.log(nextProps.droppedFiles);
        // h.log('old: ' + JSON.stringify(this.props.droppedFiles));
        // h.log('new: ' + JSON.stringify(nextProps.droppedFiles));
        if (h.notEmpty(nextProps.droppedFiles) &&
            !h.cmpStr(JSON.stringify(this.props.droppedFiles), JSON.stringify(nextProps.droppedFiles))) {
            const event = {
                target: {
                    files: nextProps.droppedFiles
                }
            };
            this.onUploadFile(event);
        }
    }

    componentDidMount () {
        const { constants, existingMessage = null, existingSubject = null } = this.props;
        var { fields } = this.state;
        
        //append existing message if any and if its null set it to empty string
        fields.message.value = existingMessage || '';
        fields.subject.value = existingSubject || '';
        
        //Variables for triggering suggestions algorithm
        this.targetSubject = existingSubject || '';
        this.targetMessage = existingMessage || ''
            
        //If existing message is not null then only set dirty to true for validation logic to succeed
        if(!general.isEmpty(existingMessage))
            fields.message.dirty = true;
        
        // default selection of task type to public
        fields.type.value = constants.TASK_PRIVACY_TYPES.PUBLIC;    
        this.setState({fields: fields}, () => {
            let { triggerSource = { source: '', payload: ''} } = this.props;
            if(helpers.general.compareString(triggerSource.source, 'entity-mapping')) {
                this.setState({ taskBroadcastChipEntities: triggerSource.payload.entitiesSelected });
            }
        });
    }
    
    openConciergeWorkflow(selectedIntentIndex) {
        //Added check if props really exist
        if(!general.isEmpty(this.props.openConciergeWorkflow)) {
            //Pass values of subject and message as history to retain them during navigation lifecycle between different forms
            this.props.openConciergeWorkflow(this.state.fields.message.value, this.state.fields.subject.value, selectedIntentIndex)
        }
    }
    
    showTaskConciergeSuggestions() {
        if (!general.isEmpty(this.props.suggestionsArray) && this.props.suggestionsArray.length>0) {
            return (
                <div style={{ paddingTop: 10, paddingBottom: 10, display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#c83636', fontSize: 16 }}>Click the following for concierge assistance : </span>
                    <div style={{ marginLeft: 10 }}>
                        <ul className="suggestions-list" onClick={(e) =>  this.openConciergeWorkflow(e.target.value)} style={{ margin: 0, padding: 0, cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {
                                uniq(this.props.suggestionsArray).map((data, index) => {
                                    return <li style={{ color: '#3260d6', fontWeight: 'bold', fontSize: 16 }} key={index} value={index}>{data.toLowerCase()}</li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            )
        }
    }

    onTaskTypeChange (event, index, value) {
        var key = 'type',
            fields = this.state.fields;
        fields[key].value = value;
        fields[key].dirty = true;
        this.setState({fields: fields});
        this.validate(key);
    }
    
    getSuggestions(e, target) {
        //Fetch suggestions only when client is admin user
        if(!general.isEmpty(this.props.currentEntity.entity_permissions.task_concierge)) {
            
            if(general.cmpStr(target, 'subject')) {
                this.targetSubject = e.target.value;
            }
            
            if(general.cmpStr(target, 'message')) {
                this.targetMessage = e.target.value;
            }
            
            if(!general.isEmpty(this.props.getSuggestions)) {
                this.props.getSuggestions({
                    'subject': this.targetSubject || '',
                    'message': this.targetMessage || '' 
                });
            } 
        }
    }

    onChange (e) {
        var { fields } = this.state;
        if (fields && e && e.target) {
            var key = e.target.name;
            var value = e.target.value;

            fields[key].value = value;
            fields[key].error = '';

            this.validate(key);

            this.setState({fields: fields});
        }
    }

    validate (key) {
        var fields = this.state.fields,
            value = fields[key].value || '';
            
        fields[key].dirty = true;
    
        switch (key) {
            case 'message':
                if (!value) {
                    fields.message.error = 'Please enter message before sending';
                }
                break;
            default:
        }

        this.setState({fields: fields});
    }

    showAttachmentEmojiSection(taskId) {
      return (
        <div>
            <input
              id="inputUploadFile"
               type="file"
               ref={(ref) => this.upload = ref}
               style={{ display: 'none' }}
               multiple
               onChange={(event)=> {
                   // if (helpers.general.isEmpty(taskId)) {
                   //     this.onCreateTaskWithUpload(event);
                   // } else {
                       this.onUploadFile(event);
                   // }
               }}
            />

            <div className="row">
              <div className="col-2 text-left">
                <IconButton tooltip="Upload file"
                            style={{ marginTop: 12 }}
                            onClick={(e) => this.upload.click() }>
                    <EditorAttachFile/>
                </IconButton>
                <IconButton tooltip="Send emoji"
                            style={{ marginTop: 12 }}
                            onClick={this.handlePopoverOpen.bind(this)}>
                    <ImageTagFaces/>
                </IconButton>
              </div>



              <div className="col-10 text-right">
                  {general.isEmpty(taskId) ?
                    <div>
                        {
                            h.isEmpty(this.props.onBackButtonPressed) ? 
                            <div /> : 
                            <PartialUIButton
                                style={{ marginTop: 20, marginRight: 20 }}
                                secondary={true}
                                label={"back"} onClick={() => {this.props.onBackButtonPressed()}}
                                disabled={this.state.isCreateTaskButtonDisabled}
                            />
                        
                        }
                        
                        <PartialUIButton
                            style={{ marginTop: 20 }}
                            primary={true}
                            label={"create"} onClick={this.onCreateTask.bind(this)}
                            disabled={this.state.isCreateTaskButtonDisabled}
                        />
                     </div>
                  :
                  <PartialUIButton
                      style={{marginTop: 20}}
                      primary={true}
                      label={"send"} onClick={this.onSendMessage.bind(this)}
                      disabled={this.state.isSendTaskButtonDisabled}
                  />}
              </div>
          </div>

        </div>
      );
    }
    
    handleRequestDelete(chipId) {
        let filteredArray = this.state.taskBroadcastChipEntities.filter((input) => {
            if(!helpers.general.compareString(input.entity_id, chipId)) {
                return input
            }
        })
        
        return this.setState({ taskBroadcastChipEntities: filteredArray })
    }
    
    renderChip(data) {
        return (
          <Chip
            key={data.entity_id}
            onRequestDelete={() => this.handleRequestDelete(data.entity_id)}
            style={{ margin: 4, border: '1px solid rgb(144,159,186)' }}
            backgroundColor={'#F7F8FA'}
          >
            {data.entity_name_formatted}
          </Chip>
        );
    }
    
    renderEntityNameChips() {
        let entityChips = this.state.taskBroadcastChipEntities;
        return (
            <div>
                <div style={{ marginTop: 30 }}>
                    <div className='row no-gutters' style={{ alignItems: 'center' }}>
                        <span style={{ color: '#B2B2B2' }}>CC:</span>
                        <div style={{ marginLeft: 10 }}/>
                        {entityChips.map((data) => {
                            return this.renderChip(data)
                        })}
                    </div>
                    <div style={{ width: '100%', border: `1px solid ${this.props.theme.palette.primary1Color}` }}/>
                </div>
            </div>
        )
    }
    
    
    // talkToBot() {
    //   this.props.callback({ taskDialog: false, botDialog: true });
    // }
    //
    // displayTalkToBotSection() {
    //   if(this.props.showTalkToBotOption)
    //   return (
    //     <div className="col-10 col-sm-10 col-md-10 col-lg-3" style={{ display: 'flex', borderLeft: '1px solid #F2F2F2', justifyContent: 'center' }}>
    //       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    //
    //         <Avatar
    //             size={80}
    //             src={taskBotAvatar}
    //             backgroundColor={'#FEFEFE'}
    //         />
    //
    //         <RaisedButton
    //             style={{ marginTop: 30 }}
    //             secondary={true}
    //             label={'Start Chatting'}
    //             onClick={() => this.talkToBot()}
    //             disabled={this.state.isCreateTaskButtonDisabled}
    //         />
    //         <p style={{ marginTop: 10, textAlign: 'center' }}>Create a Task using the <br />Zave Task-bot</p>
    //       </div>
    //     </div>
    //   );
    // }

    showCreateTaskMainPage(self, taskId, constants, attachedFiles, taskTypes) {
      let { triggerSource = { source: '', payload: ''} } = this.props
      return (
        <div className="row">
            <form className="col-12" onSubmit={general.isEmpty(taskId) ? this.onCreateTask.bind(this) : this.onSendMessage.bind(this)}>
                <div>
                    {/*{general.isEmpty(taskId) &&*/}
                    {/*<div>*/}
                    {/*    <form.MUSelect*/}
                    {/*        style={{marginTop: -10, marginBottom: -10, width: '100%'}}*/}
                    {/*        floatingLabelText="Privacy Setting"*/}
                    {/*        name="type"*/}
                    {/*        onChange={this.onTaskTypeChange.bind(this)}*/}
                    {/*        data={this.state.fields.type}*/}
                    {/*        options={taskTypes}*/}
                    {/*    />*/}
                    {/*</div>}*/}
                    {general.isEmpty(taskId) &&
                    <div>
                        {
                          /*general.isEmpty(taskId) && this.state.value === 2 ?
                          <div>
                              <AutoComplete
                                hintText="Entity name/User group name"
                                style={{marginTop: -10, width: '100%'}}
                                dataSource={this.state.entityNameAndEntityUserGroupDataSource}
                                fullWidth={true}
                                multiLine={false}
                                filter={AutoComplete.caseInsensitiveFilter}
                                onNewRequest={this.handleSelectEntityUserNameAndEntityUserGroupInput}
                                onUpdateInput={this.handleUpdateEntityUserNameAndEntityUserGroupInput}
                              />
                              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                  {this.state.selectedEntityNameAndEntityUserGroupList.map((item, i) => {
                                      return (
                                          <Chip
                                            key={i}
                                            onRequestDelete={(e) => this.handleRequestDeleteEntityNameAndEntityUserGroup(e)}
                                            style={{margin: '4'}}
                                          >
                                            {item}
                                          </Chip>
                                      );
                                  })}
                              </div>
                              <AutoComplete
                                hintText="Service provider name/User group name"
                                style={{marginTop: 10, marginBottom: 10, width: '100%'}}
                                dataSource={this.state.serviceProviderNameAndServiceProviderUserGroupDataSource}
                                fullWidth={true}
                                multiLine={false}
                                filter={AutoComplete.caseInsensitiveFilter}
                                onNewRequest={this.handleSelectServiceProviderNameAndServiceProviderUserGroupInput}
                                onUpdateInput={this.handleUpdateServiceProviderNameAndServiceProviderUserGroupInput}
                              />
                              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                  {this.state.selectedServiceProviderNameAndServiceProviderUserGroupList.map((item, i) => {
                                      return (
                                          <Chip
                                            key={i}
                                            onRequestDelete={(e) => this.handleRequestDeleteServiceProviderNameAndServiceProviderUserGroup(e)}
                                            style={{margin: '4'}}
                                          >
                                            {item}
                                          </Chip>
                                      );
                                  })}
                              </div>
                          </div>
                          :
                          null*/
                        }
                        {helpers.general.compareString(triggerSource.source, 'entity-mapping') ?
                            this.renderEntityNameChips()
                            : <div />
                        }
                    
                        <form.Input
                            type="text"
                            style={{marginTop: -10, marginBottom: -10, width: '100%'}}
                            floatingLabelText="Subject"
                            name="subject"
                            fullWidth={true}
                            multiLine={false}
                            onChange={(e) => {
                                this.getSuggestions(e, 'subject')
                                this.onChange(e)
                            }}
                            data={this.state.fields.subject}/>
                    </div>}
                    <div>
                        <form.Input
                            autoFocus
                            type="text"
                            style={{marginTop: -10, marginBottom: -10, width: '100%'}}
                            floatingLabelText="Message*"
                            name="message"
                            fullWidth={true}
                            multiLine={true}
                            rowsMax={10}
                            onChange={(e) => {
                                this.getSuggestions(e, 'message');
                                this.onChange(e);
                            }}
                            data={this.state.fields.message}/>
                    </div>
                    
                    {this.showTaskConciergeSuggestions()}
                    {this.showAttachmentEmojiSection(taskId)}

                    {h.notEmpty(attachedFiles) &&
                    <div>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}>
                            {attachedFiles.map(function (attachedFile, index) {
                                return (
                                    <Chip
                                        onRequestDelete={self.handleDeleteAttachment.bind(self, index)}
                                        style={{
                                            margin: 4
                                        }}
                                    >
                                        {attachedFile.file_name || 'File'}
                                    </Chip>
                                );
                            })}
                        </div>
                    </div>}
                </div>
            </form>

            <Popover
                open={this.state.isPopoverOpen}
                animated={false}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                targetOrigin={{horizontal: 'left', vertical: 'top'}}
                onRequestClose={this.handlePopoverClose.bind(this)}
            >
              <Picker set='apple'
                  emoji='grinning'
                  title='Select emoji'
                  onClick={this.handleEmojiPicked}
              />
            </Popover>

            {/*()<div className="col-1" />*/}
            {/*this.displayTalkToBotSection()*/}

          </div>
      );
    }

    handleUpdateEntityUserNameAndEntityUserGroupInput = (value) => {
      if (!h.isEmpty(value)) {
        const data = {
          "entity_id": this.props.currentEntity.entity_id,
          "search_keyword": value
        }
        PrivateTaskModel.getEntityUsersAndEntityUserGroups(data, (status, result) => {
          if (status.status === "ok") {
            const newResultList = status.data.resultList.map((item, i) => {
              return item.userName || item.userGroupName;
            });
            this.setState({
              entityNameAndEntityUserGroupDataSource: newResultList
            });
          }
        });
      } else {
        this.setState({
          entityNameAndEntityUserGroupDataSource: []
        });
      }
    }

    handleUpdateServiceProviderNameAndServiceProviderUserGroupInput = (value) => {
      if (!h.isEmpty(value)) {
        const data = {
          "service_provider_id": this.props.currentServiceProvider.service_provider_id,
          "search_keyword": value,
          "portal_type": "client"
        }
        PrivateTaskModel.getServiceProviderUsersAndServiceProviderUserGroups(data, (status, result) => {
          if (status.status === "ok") {
            const newResultList = status.data.resultList.map((item, i) => {
              return item.userName || item.userGroupName;
            });
            this.setState({
              serviceProviderNameAndServiceProviderUserGroupDataSource: newResultList,
            });
          }
        });
      } else {
        this.setState({
          serviceProviderNameAndServiceProviderUserGroupDataSource: []
        });
      }
    };

    handleSelectEntityUserNameAndEntityUserGroupInput = (selectedValue) => {
      if (!this.state.selectedEntityNameAndEntityUserGroupList.includes(selectedValue)) {
        this.setState({
          selectedEntityNameAndEntityUserGroupList: this.state.selectedEntityNameAndEntityUserGroupList.concat(selectedValue)
        });
      }
    }

    handleSelectServiceProviderNameAndServiceProviderUserGroupInput = (selectedValue) => {
      if (!this.state.selectedServiceProviderNameAndServiceProviderUserGroupList.includes(selectedValue)) {
        this.setState({
          selectedServiceProviderNameAndServiceProviderUserGroupList: this.state.selectedServiceProviderNameAndServiceProviderUserGroupList.concat(selectedValue)
        });
      }
    }

    handleRequestDeleteEntityNameAndEntityUserGroup = (e) => {
      if (e.target.previousSibling !== null) {
        const chipValue = e.target.previousSibling.innerHTML;
        const newSelectedEntityNameAndEntityUserGroupList = this.state.selectedEntityNameAndEntityUserGroupList.filter((item) => {
          return item !== chipValue;
        });
        this.setState({
          selectedEntityNameAndEntityUserGroupList: newSelectedEntityNameAndEntityUserGroupList
        });
      }
    }

    handleRequestDeleteServiceProviderNameAndServiceProviderUserGroup = (e) => {
      if (e.target.previousSibling !== null) {
        const chipValue = e.target.previousSibling.innerHTML;
        const newSelectedServiceProviderNameAndServiceProviderUserGroupList = this.state.selectedServiceProviderNameAndServiceProviderUserGroupList.filter((item) => {
          return item !== chipValue;
        });
        this.setState({
          selectedServiceProviderNameAndServiceProviderUserGroupList: newSelectedServiceProviderNameAndServiceProviderUserGroupList
        });
      }
    }
    

    render () {
        const self = this;
        const { taskId, constants } = this.props;
        const { attachedFiles } = this.state;
        let taskTypes = [
          {
              text: <div>Public<br/><span className="text-muted">All entity admins and yourself</span></div>,
              value: 'public'
          },
          {
              text: <div>Private<br/><span className="text-muted">Only yourself</span></div>,
              value: 'private'
          }
        ];
        // for (var key in constants.TASK_TYPES_CLIENT) {
        //     taskTypes.push({
        //         text: constants.TASK_TYPES_CLIENT[key].TEXT,
        //         value: constants.TASK_TYPES_CLIENT[key].VALUE
        //     });
        // }
        return (
          <div>
            {this.showCreateTaskMainPage(self, taskId, constants, attachedFiles, taskTypes)}
          </div>
        );
    }
    
    showTaskBroadcastSuccessErrorDialog(status) {
        this.props.closeDialog();
        if(h.general.cmpStr(status, 'error')) {
            this.props.isBroadcastSuccess(status);
        } else {
            this.props.isBroadcastSuccess(status);
        }
    }

    /**
     * create task
     * @param e
     */
    onCreateTask (e) {
      e.preventDefault();
      let { triggerSource = { source: '', payload: ''} } = this.props;
      // if(helpers.general.compareString(triggerSource.source, 'entity-mapping')) {
      //     return;
      // }
      
      const self = this;
        var { currentEntity, currentUser, reloadTaskMessages, closeDialog } = this.props;
        var { fields, attachedFiles } = this.state;
        var isValid = true;

        // validate that entire form has no more errors
        for (var key in this.state.fields) {
            if (!this.state.fields[key].dirty) {
                this.validate(key);
                isValid = false;
            } else if (this.state.fields[key].error && this.state.fields[key].error !== '') {
                this.validate(key);
                isValid = false;
            }
        }
        
        let data = { 
            'utterance': [
                { text: this.state.fields.subject.value, 'type': 'task_subject' },
                { text: this.state.fields.message.value, 'type': 'task_message' }
            ]
        };
        
        
        if(!general.isEmpty(this.props.onCreateWorkflowAnalytics)) {
            this.props.onCreateWorkflowAnalytics(data);
        }
        
        
        // check that form is valid
        if (isValid) {
            
            //Once the query is valid block the button till the request is completed
            this.setState({ isCreateTaskButtonDisabled: true });

            let taskTypeValue = fields.type.value;
            let privacyTypeValue = "public";
            let taskVersionValue = "1";
            if (fields.type.value === 'public') {
              taskTypeValue = 'miscellaneous';
              privacyTypeValue = 'public';
              taskVersionValue = "1";
            } else if (fields.type.value === 'private') {
              taskTypeValue = 'miscellaneous';
              privacyTypeValue = 'private';
              taskVersionValue = "2";
            }

            var query = {
                entity_id: currentEntity.entity_id,
                task_type: taskTypeValue,
                owner_type: ZaveCommon.config.constant.TASK_CREATION_SOURCE.ENTITY,
                privacy_type: privacyTypeValue,
                task_from_user_id: currentUser.user_id,
                task_subject: fields.subject.value || '',
                task_message: fields.message.value || '',
                attached_files: JSON.stringify(attachedFiles),
                task_version: taskVersionValue
            };
            
            
            //Check if dialogbox has a request to create task dialogbox
            if(helpers.general.cmpStr(triggerSource.source ,'entity-mapping')) {
                delete query.entity_id;
                query.entity_ids_array = this.state.taskBroadcastChipEntities;
                TaskModel.createTaskBroadcast(query, (data) => {
                        var apiRes = api.handleApiResponse(data, false);
                        if (apiRes.status === 'ok') {
                            closeDialog('ok');
                        } else {
                            closeDialog('error')
                        }
                        //Irrespective of what happens in response, success/error enable the button
                        self.setState({ isCreateTaskButtonDisabled: false });
                });
            } else {
                TaskModel.createTask(query, (data) => {
                    var apiRes = api.handleApiResponse(data, true);
                    if (apiRes.status === 'ok') {
                        const taskId = apiRes.data.task_id;
                        if (h.notEmpty(taskId)) {
                          const taskUserData = {
                            'task_role': "client",
                            'task_fk': taskId,
                            'user_fk': this.props.currentUser.user_id,
                            'created_by': this.props.currentUser.user_id,
                            'updated_by': this.props.currentUser.user_id
                          };
                          TaskUserModel.createTaskUser(taskUserData, function (data) {
                              var apiRes = api.handleApiResponse(data, true);
                              if (apiRes.status === 'ok') {

                              }
                          })
                          // const taskUserGroupData = {
                          //   'task_role': "client",
                          //   'task_fk': taskId,
                          //   'user_group_fk': this.props.currentUser.user_id,
                          //   'created_by': this.props.currentUser.user_id,
                          //   'updated_by': this.props.currentUser.user_id
                          // };
                          // TaskUserGroupModel.createTaskUserGroup(taskUserGroupData, function (data) {
                          //     var apiRes = api.handleApiResponse(data, true);
                          //     if (apiRes.status === 'ok') {
                          //
                          //     }
                          // })

                          reloadTaskMessages();
                          closeDialog();
                        }
                    }
                    //Irrespective of what happens in response, success/error enable the button
                    self.setState({ isCreateTaskButtonDisabled: false });
                });
            }
        }
    }

    /**
     * create task with an upload
     * @param event
     */
    onCreateTaskWithUpload (event) {
        var self = this;
        var { reloadTaskMessages, currentEntity, closeDialog } = this.props;
        var { fields } = this.state;
        var file = event.target.files[0];
        var formData = new FormData();
        formData.append('file', file)
        formData.append('upload_type', '8');
        formData.append('data_type', '2');
        formData.append('entity_id', currentEntity.entity_id);
        // formData.append('task_type', fields.type.value || ''),
        // formData.append('task_id', taskMessages[0].task_task_id);
        UploadModel.upload(formData, function (data) {
            var apiRes = api.handleApiResponse(data);
            if (apiRes.status === 'ok') {
                // refresh messages
                reloadTaskMessages();
                // clear message
                fields.message.value = '';
                self.setState({fields: fields});
                closeDialog();
            }
        });
    }

    /**
     * send message to server
     */
    onSendMessage (e) {
        e.preventDefault();
        const { currentEntity, currentUser, taskId, reloadTaskMessages } = this.props;
        var self = this;
        var { fields, attachedFiles } = this.state;
        var isValid = true;

        // validate that entire form has no more errors
        for (var key in fields) {
            if (!fields[key].dirty) {
                this.validate(key);
                isValid = false;
            } else if (fields[key].error && fields[key].error !== '') {
                this.validate(key);
                isValid = false;
            }
        }

        // check that form is valid
        if (isValid) {
          //Once the query is valid block the button till the request is completed
          this.setState({ isSendTaskButtonDisabled: true });

            var query = {
                entity_id: currentEntity.entity_id,
                task_id: taskId,
                task_from_user_id: currentUser.user_id,
                task_message: fields.message.value,
                attached_files: JSON.stringify(attachedFiles)
            };
            TaskModel.createTaskMessage(query, function (data) {
                var apiRes = api.handleApiResponse(data);
                if (apiRes.status === 'ok') {
                    // refresh messages
                    reloadTaskMessages();
                    // clear message
                    fields.message.value = '';
                    self.setState({fields: fields, attachedFiles: []});
                }
                self.setState({ isSendTaskButtonDisabled: false });
            });
        }
    }

    /**
     * upload a file task attachment
     * @param event
     */
    onUploadFile (event) {
        var self = this;
        var { currentEntity, taskMessages, reloadTaskMessages } = this.props;
        var { fields, attachedFiles } = this.state;
        var files = event.target.files;
        var formData = new FormData();
        // formData.append('files', files)

        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            formData.append("file-" + i, file);
        }

        formData.append('upload_type', '8');
        formData.append('data_type', '2');
        // formData.append('entity_id', taskMessages[0].task_entity_fk);
        formData.append('entity_id', currentEntity.entity_id);
        // formData.append('task_id', taskMessages[0].task_task_id);
        UploadModel.upload(formData, function (data) {
            var apiRes = api.handleApiResponse(data);
            if (apiRes.status === 'ok' &&
                h.notEmpty(apiRes.data) &&
                h.notEmpty(apiRes.data.uploaded_files)) {
                // merge newly uploaded file list to current attachedFiles list
                attachedFiles = attachedFiles.concat(apiRes.data.uploaded_files);
                self.setState({attachedFiles: attachedFiles});
                // clear dropped file list after upload is done
                if (h.notEmpty(self.props.resetDroppedFiles)) {
                    self.props.resetDroppedFiles();
                }
            }
        });
    }

    /**
     * handles popover open event
     * @param e
     */
    handlePopoverOpen (e) {
        // This prevents ghost click.
        e.preventDefault();
        this.setState({
            isPopoverOpen: true,
            anchorEl: e.currentTarget,
        });
    }

    /**
     * handles popover close event
     */
    handlePopoverClose () {
        this.setState({isPopoverOpen: false});
    }

    /**
     * handles emoji picker click event
     * @param emoji
     * @param event
     */
    handleEmojiPicked = (emoji, event) => {
        var { fields } = this.state;
        if (emoji.colons) {
            fields.message.value += emoji.colons;
            this.setState({isPopoverOpen: false, fields: fields});
            // focus on message text field on emoji selected
            $('input[name="message"]').focus();
        }
    }

    /**
     * handles deletion of attachment from task message
     * @param attachedFileIndex
     * @param e
     */
    handleDeleteAttachment (attachedFileIndex, e) {
        const { attachedFiles } = this.state;
        if (h.notEmpty(attachedFiles)) {
            delete attachedFiles[attachedFileIndex];
            this.upload.value = '';
            this.setState({attachedFiles: attachedFiles});
        }
    }

}

PartialTaskChatInput.propTypes = {
    currentUser: PropTypes.object.isRequired,
    currentCompany: PropTypes.object.isRequired,
    currentEntity: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    userPermissions: PropTypes.object.isRequired,
    taskId: PropTypes.string,
    messageType: PropTypes.string,
    taskMessages: PropTypes.array,
    reloadTaskMessages: PropTypes.func,
    closeDialog: PropTypes.func
};

const mapStateToProps = state => ({
    currentUser: state.user,
    currentCompany: state.company.company,
    currentEntity: state.company.currentEntity,
    theme: state.config.theme,
    constants: state.constants,
    userPermissions: state.permission,
    taskMessages: state.task.taskMessages,
    currentServiceProvider: state.company.currentServiceProvider
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartialTaskChatInput));