module.exports = {
    "COMPANY_REGISTRY": {
        "reference": "COMPANY_REGISTRY"
    },
    "BOARD_RESOLUTION": {
        "reference": "BOARD_RESOLUTION"
    },
    "TAX_DEPARTMENT": {
        "reference": "TAX_DEPARTMENT"
    },
    "SHAREHOLDER_RESOLUTION": {
        "reference": "SHAREHOLDER_RESOLUTION"
    },
    "GENERAL_COMPLIANCE": {
        "reference": "GENERAL_COMPLIANCE"
    },
    "CUSTOM_CORPORATE_ACTIONS": {
        "reference": "CUSTOM_CORPORATE_ACTIONS"
    },
    "REPORTING": {
        "reference": "REPORTING"
    },
    "SINGLE_DIRECTOR_APPROVAL": {
        "reference": "SINGLE_DIRECTOR_APPROVAL"
    },
    "GENERIC": {
        "reference": "GENERIC"
    },
    "MAIL": {
        "reference": "MAIL"
    }
};