import {h} from "../helpers";
import * as companyModel from "../models/company";

export const setCompany = (company) => ({
    type: 'SET_COMPANY',
    company: company
});

export const setEntities = (entities) => ({
    type: 'SET_ENTITIES',
    entities: entities
});

// export const setEntityUsers = (entityUsers) => ({
//     type: 'SET_ENTITY_USERS',
//     entityUsers: entityUsers
// });

export const setBankAccounts = (accounts) => ({
    type: 'SET_BANK_ACCOUNTS',
    accounts: accounts
});


export const setCurrentEntityByEntityId = (entityId, entities) => {
    return dispatch => {
        companyModel.getEntity({entity_id: entityId}, (data) => {
            let serviceProvider = null;
            const apiRes = h.api.handleApiResponse(data, false);
            if (h.cmpStr(apiRes.status, 'ok') && h.notEmpty(apiRes.data.entity)) {
                for (var entity_idx in entities) {
                    let entity = entities[entity_idx];
                    if (h.cmpStr(entity.entity_id, entityId)) {
                        entities[entity_idx] = apiRes.data.entity;
                    }
                }
            }
            else {
                console.error(`setCurrentEntityByEntityId: Failed to retrieve entity from API. Entity ID: ${entityId}`);
            }
            companyModel.getServiceProvider({entity_id: entityId}, (data) => {
                const apiRes = h.api.handleApiResponse(data, false);
                if(apiRes.status == 'ok' && h.notEmpty(apiRes.data.service_provider)) {
                    serviceProvider = apiRes.data.service_provider;
                }
                else {
                    console.error('Failed to retrieve service provider for entity.');
                }
                //Regardless of the result, dispatch the action
                dispatch({
                    type: 'SET_CURRENT_ENTITY_BY_ENTITY_ID',
                    entityId: entityId,
                    entities: entities,
                    serviceProvider
                });
            });
        });
    };
};

export const setBookkeepers = (bookkeepers) => ({
    type: 'SET_BOOKKEEPERS',
    bookkeepers: bookkeepers
});

export const setContacts = (contacts) => ({
    type: 'SET_CONTACTS',
    contacts: contacts
});

export function getContacts(entityId, callback) {
    return dispatch => {
        var query = {entity_id: entityId, is_customer: 1};
        companyModel.getContacts(query, function (data) {
            var apiRes = h.api.handleApiResponse(data, false);
            if (apiRes.status === 'ok') {
                dispatch(setContacts(apiRes.data.contacts|| []));
            }
            callback(apiRes);
        });
    }
}

export const setDirectorKyc = (director, countryCode) => ({
    type: 'SET_DIRECTOR_KYC',
    director: director,
    countryCode: countryCode
});

export const setShareholderKyc = (shareholder, countryCode) => ({
    type: 'SET_SHAREHOLDER_KYC',
    shareholder: shareholder,
    countryCode: countryCode
});

export const setEntityDirectors = (directors, countryCode) => ({
    type: 'SET_DIRECTORS',
    directors: directors,
    countryCode: countryCode
});

export const setEntityShareholders = (shareholders, countryCode) => ({
    type: 'SET_SHAREHOLDERS',
    shareholders: shareholders,
    countryCode: countryCode
});

export const setCurrentServiceProvider = (serviceProvider) => ({
    type: 'SET_CURRENT_SERVICE_PROVIDER',
    serviceProvider
});