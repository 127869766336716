module.exports = {
    'ADD_DIRECTOR_SCREEN': {
        'navigateTo': 'add_director_screen',
        'dialogBoxTitle': 'Add director'
    },
    'REMOVE_DIRECTOR_SCREEN': {
        'navigateTo': 'remove_director_screen',
        'dialogBoxTitle': 'Remove director'
    },
    'CHANGE_FINANCIAL_YEAR_END_SCREEN': {
        'navigateTo': 'change_financial_year_end_screen',
        'dialogBoxTitle': 'Change Financial Year End'
    },
    'ISSUE_SHARES_SCREEN': {
        'navigateTo': 'issue_shares_screen',
        'dialogBoxTitle': 'Issue Shares'
    },
    'TRANSFER_SHARES_SCREEN': {
        'navigateTo': 'transfer_shares_screen',
        'dialogBoxTitle': 'Transfer Shares'
    },
    'OTHERS_SCREEN': {
        'navigateTo': 'tasks_others_screen',
        'dialogBoxTitle': 'Create Task'
    }
};