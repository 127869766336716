/**
 * initialize intercom chat
 * @param appId
 * @param callback
 */
export function initIntercom (appId, callback) {
    (function (w, d, id, s, x) {
        function i () {
            i.c(arguments);
        }

        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
        s.onload = () => {
            window.intercomSettings = {
                app_id: id,
                //custom_launcher_selector:'#open_intercom_chat_support'
            };
            window.Intercom("boot", window.intercomSettings);
            callback();
        };
    })(window, document, appId);
}

/**
 * shutdown and clean up intercom chat
 */
export function shutdownIntercom () {
    if (window.Intercom) {
        window.Intercom("shutdown");
        delete window.Intercom;
        delete window.intercomSettings;
    }
}