import { compareString } from './general.js';

/**
 * Get billing subscription pricing by country code
 * @param {pricings?: Array<{amount: number, country_code: string}>} billingSubscription
 * @param {string} countryCode
 * @returns {string}
 */
export function getPriceByCountry (billingSubscription, countryCode) {
	let subscriptionPrice = '';
	if (
		billingSubscription &&
		billingSubscription.pricings &&
		billingSubscription.pricings.length > 0
	) {
		countryCode = countryCode && !['sg','au','hk','my','us'].includes(countryCode.toLowerCase()) ? 'us' : countryCode;
		for (let i = 0; i < billingSubscription.pricings.length; i++) {
			const pricing = billingSubscription.pricings[i];
			if (pricing.amount > 0 && compareString(pricing.country_code, countryCode)) {
				subscriptionPrice = pricing.amount_pretty;
			}
		}
		return subscriptionPrice;
	} else {
		return subscriptionPrice;
	}
}
