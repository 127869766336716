import $ from 'jquery';
import { config } from '../configs/config';

export function createTaskUser (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/task-user/create-task-user',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function deleteTaskUser (data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + '/v1.0.0/task-user/delete-task-user',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
