export default function trial (state = {}, action) {
    switch (action.type) {
        case 'SET_EMAIL':
            return {
                ...state,
                email: action.email
            };
        case 'SET_UEN':
            return {
                ...state,
                uen: action.uen
            };
        case 'SET_FIRST_NAME':
            return {
                ...state,
                firstName: action.firstName
            };
        case 'SET_LAST_NAME':
            return {
                ...state,
                lastName: action.lastName
            };
        case 'SET_MOBILE':
            return {
                ...state,
                mobile: action.mobile
            };
        case 'SET_ENTITY_NAME':
            return {
                ...state,
                entityName: action.entityName
            };
        case 'SET_INDUSTRY':
            return {
                ...state,
                industry: action.industry
            };
        case 'SET_IS_SUPPORTED':
            return {
                ...state,
                isSupported: action.isSupported
            };
        case 'SET_MESSAGE':
            return {
                ...state,
                message: action.message
            };
        case 'SET_IS_GOOGLE_MAIL':
            return {
                ...state,
                isGoogleMail: action.isGoogleMail
            };
        case 'SET_ENTITY_URL':
            return {
                ...state,
                entityUrl: action.entityUrl
            };
        case 'SET_OPTION_SERVICES':
            return {
                ...state,
                optionServices: action.optionServices
            };
        case 'SET_IS_EXISTING_ZAVE_USER':
            return {
                ...state,
                isExistingZaveUser: action.isExistingZaveUser
            };
        case 'SET_SERVICE_PROVIDER_ID':
            return {
                ...state,
                serviceProviderId: action.serviceProviderId
            };
        default:
            return state;
    }
}