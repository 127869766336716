export default function notification (state = {}, action) {
    switch (action.type) {
        case 'SET_NOTIFICATION':
            return {
                ...state,
                notifications: action.notifications
            }

        default:
            return state;
    }
}