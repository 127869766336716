export default function bankTransaction (state = {}, action) {
    switch (action.type) {
        case 'SET_BANK_TRANSACTIONS':
            return {
                ...state,
                bankTransactions: action.bankTransactions
            };
        case 'SET_VERIFICATIONS':
            return {
                ...state,
                verifications: action.verifications
            };
        case 'SET_VERIFICATION_MESSAGES':
            return {
                ...state,
                verificationMessages: action.verificationMessages
            };
        default:
            return state;
    }
}