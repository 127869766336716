import $ from 'jquery';
import { config } from '../configs/config';

/**
 * get system configuration
 * @param data
 * @param callback
 */
export function getConfig (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-config',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get list of currencies
 * @param data
 * @param {*} callback
 */
export function getCurrencies (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-currencies',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get system constants
 * @param data
 * @param callback
 */
export function getConstant (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-constant',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get address using postal code
 * @param data
 * @param callback
 */
export function getAddress (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-address',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get list of countries
 * @param data
 * @param callback
 */
export function getCountries (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-countries',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}