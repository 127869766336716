module.exports = {
    "CORPORATE_ACTION_GENERIC": {
        "reference": "CORPORATE_ACTION_GENERIC"
    },
    "GENERIC": {
        "reference": "GENERIC"
    },
    "MAIL_GENERIC": {
        "reference": "MAIL_GENERIC"
    }
}