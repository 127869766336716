import $ from 'jquery';
import { config } from '../configs/config';

/**
 * get tasks
 * @param data
 * @param callback
 */
export function getTasks (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/task',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get task messages
 * @param data
 * @param callback
 */
export function getTaskMessages (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/task/message',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create task
 * @param data
 * @param callback
 */
export function createTask (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/task/create',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create task broadcast
 * @param data
 * @param callback
 */
export function createTaskBroadcast (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/task/create-task-broadcast',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create task
 * @param data
 * @param callback
 */
export function createTaskMessage (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/task/message/create',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getTaskLastReadBy (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/task/get-task-last-read-by',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function createTaskLastReadBy (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/task/create-task-last-read-by',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
