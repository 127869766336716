import * as React from 'react';
import { config } from '../configs/config';
import { helpers, h } from "./index";
import accountsNinjaTheme from '../themes/accountsNinjaTheme';

var company = {

    /**
     * generates company home url based on company name
     * @param companyName
     * @returns {*}
     */
    generateCompanyUrl: function (companyName) {
        const unwantedNames = ['pte', 'llp', 'lp', 'limited', 'ltd', 'll', 'private'];
        if (typeof companyName === typeof undefined || companyName === '') {
            return '';
        }
        companyName = companyName.toLowerCase();

        // remove all dots from company name
        companyName = companyName.split('.').join('');
        // remove all commas from company name
        companyName = companyName.split(',').join('');
        // remove all brackets from company name
        companyName = companyName.split('(').join('');
        companyName = companyName.split(')').join('');

        // remove unwanted company entity types
        var companyNameStrArray = companyName.split(' ');
        for (var i = 0; i < companyNameStrArray.length; i++) {
            for (var j = 0; j < unwantedNames.length; j++) {
                if (String(companyNameStrArray[i]).toLowerCase() === String(unwantedNames[j]).toLowerCase()) {
                    delete companyNameStrArray[i];
                }
            }
        }
        // merge array back to a single string
        var newCompanyName = companyNameStrArray.join('');

        // remove unwanted characters
        newCompanyName = newCompanyName.replace(/\W/g, '');

        return newCompanyName;
    },

    /**
     * get full company home url
     * @param prefix
     * @returns {*}
     */
    getCompanyUrl: function (prefix) {
        if (typeof prefix === typeof undefined || prefix === '') {
            return '';
        }
        var companyHomeUrl = '';

        if (config.env === 'development') {
            companyHomeUrl = 'https://' + prefix + '.' + (config.webappUrl.replace('https://', ''))
        } else {
            companyHomeUrl = 'https://' + prefix + '.' + (config.webappUrl.replace('https://app.', ''))
        }

        return companyHomeUrl;
    },

    /**
     * detects for switching of entity
     * @param nextProps
     * @param currentProps
     * @param executeFunction
     * @returns {boolean}
     */
    detectEntitySwitching: function (nextProps, currentProps, executeFunction) {
        if (nextProps &&
            nextProps.currentEntity &&
            nextProps.currentEntity.entity_id &&
            currentProps &&
            currentProps.currentEntity &&
            currentProps.currentEntity.entity_id &&
            !helpers.general.cmpStr(nextProps.currentEntity.entity_id, currentProps.currentEntity.entity_id)) {
            // console.log('cookie entity id: ' + auth.getCookie(auth.cookieCurrentEntityId));
            // console.log('old entity id: ' + nextProps.currentEntity.entity_id);
            // console.log('new entity id: ' + this.props.currentEntity.entity_id);
            // console.log('we got some changes!!');
            // add delay to fix race condition where entity id is not set yet
            setTimeout(function () {
                // window.location.reload();
                executeFunction();
            }, 500);
            // true means entity switching detected
            return true;
        } else {
            // false means no entity switching detected
            return false;
        }
    },

    /**
     * format address based on address 1, address 2 and postal code data
     * @param address1
     * @param address2
     * @param postal
     */
    formatAddress: function (address1, address2, postal) {
        var formattedAddresss = '';
        var countryCode = h.general.getGetParam(this, 'countryCode') || 'SG';
        switch (countryCode) {
            default:
            case 'SG':
                if (h.general.notEmpty(address1)) {
                    formattedAddresss += (h.notEmpty(formattedAddresss) ? ' ' : '') + address1;
                }
                if (h.general.notEmpty(address2)) {
                    formattedAddresss += (h.notEmpty(formattedAddresss) ? ' ' : '') + address2;
                }
                if (h.general.notEmpty(postal)) {
                    formattedAddresss += (h.notEmpty(formattedAddresss) ? ' ' : '') + 'Singapore ' + postal;
                }
                break;
        }
        return formattedAddresss;
    },

    /**
     * format person name from first name, middle name and last name data
     * @param firstName
     * @param middleName
     * @param lastName
     * @returns {string}
     */
    formatPersonName: function (firstName, middleName, lastName) {
        var formattedName = '';
        if (h.general.notEmpty(firstName)) {
            formattedName += (h.notEmpty(formattedName) ? ' ' : '') + firstName;
        }
        if (h.general.notEmpty(middleName)) {
            formattedName += (h.notEmpty(formattedName) ? ' ' : '') + middleName;
        }
        if (h.general.notEmpty(lastName)) {
            formattedName += (h.notEmpty(formattedName) ? ' ' : '') + lastName;
        }
        return formattedName;
    },

    /**
     * helper function to format company/entity name
     * @param entity
     * @param isTruncate
     * @param truncateLength
     * @param hideCountryCode
     * @returns {*}
     */
    formatEntityName: function (entity, isTruncate, truncateLength, hideCountryCode) {
        if (h.isEmpty(entity) && h.isEmpty(entity.entity_name)) {
            return '';
        }
        isTruncate = h.notEmpty(isTruncate) && h.cmpBool(isTruncate, true) ? true : false;
        truncateLength = h.notEmpty(truncateLength) ? truncateLength : undefined;
        let entityName = entity.entity_name;
        const isIncorporated = h.notEmpty(entity.entity_status) && !h.cmpStr(entity.entity_status, 'pending') ? true : false;
        const entityNameSuffix = entity.entity_name_suffix;
        const countryCode = entity.country_code;

        if (h.cmpBool(isIncorporated, false)) {
            entityName = `(pending) ${entityName}`;
        }
        if (h.notEmpty(entityNameSuffix)) {
            entityName = `${entityName} ${entityNameSuffix}`;
        }
        if (h.cmpBool(isTruncate, true)) {
            entityName = h.general.truncate(entityName, truncateLength);
        }
        if (!h.isEmpty(countryCode)) {
            let prettyCountryCode = '';
            switch (countryCode) {
                case '00':
                    prettyCountryCode = 'Unassigned';
                    break;
                case '01':
                    prettyCountryCode = 'Other';
                    break;
                case 'VG':
                    prettyCountryCode = 'BVI';
                    break;
                default:
                    prettyCountryCode = countryCode;
            }
            if (hideCountryCode !== undefined && h.cmpBool(hideCountryCode, true)) {
              entityName = `${entityName}`;
            } else {
              entityName = `${entityName} (${prettyCountryCode})`;
            }
        }
        return entityName;
    },

    /**
     * Get entity permissions from redux store
     * @param {string} entityId
     * @param {array} entities
     */
    getEntityPermFromRedux: (entityId, entities) => {
        let entityPermissions = {};
        if (entityId && entities && entities.length > 0) {
            for (let i = 0; i < entities.length; i++) {
                const entity = entities[i];
                if (entity.entity_id === entityId) {
                    entityPermissions = entity.entity_permissions;
                }
            }
        }
        return entityPermissions;
    },
    /**
     * Get the localized entity registration number label
     */
    formatEntityRegistrationNumberLabel: (country_code) => {
        switch (country_code) {
            case 'AU': {
                return 'ACN';
            }
            case 'SG': {
                return 'UEN';
            }
            default: {
                return 'Registration Number';
            }
        }
    },

    /**
     * format status color for capture status
     * @param constant
     * @param status
     * @returns {string}
     */
    formatCaptureStatus (constant, status) {
        var formattedStatus = '';
        if (helpers.general.notEmpty(constant) &&
            helpers.general.notEmpty(constant.CAPTURE_STATUS) &&
            helpers.general.notEmpty(status)) {
            switch (status) {
                case constant.CAPTURE_STATUS.PENDING:
                    formattedStatus =
                        <span style={{color: accountsNinjaTheme.palette.accent2Color}}>
                        {helpers.general.prettifyConstant(status).toUpperCase()}
                    </span>;
                    break;
                case constant.CAPTURE_STATUS.SENT:
                    formattedStatus =
                        <span style={{color: accountsNinjaTheme.palette.successColor}}>
                        {helpers.general.prettifyConstant(status).toUpperCase()}
                    </span>;
                    break;
            }
        }
        return formattedStatus;
    },

}

export default company;
