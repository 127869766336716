import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../helpers/history';
import { helpers, general, auth } from '../helpers/index';
import { config } from '../configs/config';

import { setSubdomain, setTheme, startLoader, stopLoader } from '../actions/config';
import { setConstants } from '../actions/constants';
import { setPermission } from '../actions/permission';

import { ToastContainer } from 'react-toastify';
import Spinner from 'react-spinkit';

import classnames from 'classnames';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import Raven from 'raven-js';

// = styles =
// 3rd
import '../styles/css/bootstrap.css';
import '../styles/css/emoji-mart.css';
// custom
import '../styles/css/layout.css';
import '../styles/css/theme.css';
import '../styles/css/ui.css';
import '../styles/css/app.css';
import '../styles/css/custom.css';

// react-table style
import 'react-table/react-table.css'

import accountsNinjaTheme from '../themes/accountsNinjaTheme';
import lightTheme from '../themes/lightTheme';
import darkTheme from '../themes/darkTheme';
import grayTheme from '../themes/grayTheme';
import {api, h} from "../helpers";
import * as GeneralModel from "../models/general";
import {getTimelineAttachment} from "../models/timeline";
// import * as UserModel from "../models/user";
import UserLogin from '../components/User/UserLogin';

const PrivateRoute = lazy(() => import('../components/Common/CommonPrivateRoute'));
const Common404 = lazy(() => import('../components/Common/Common404'));
// const UserLogin = lazy(() => import('../components/User/UserLogin'));
const UserSignup = lazy(() => import('../components/User/UserSignup'));
const UserInviteSignup = lazy(() => import('../components/User/UserInviteSignup'));
const UserEmailVerification = lazy(() => import('../components/User/UserEmailVerification'));
const UserForgotPassword = lazy(() => import('../components/User/UserForgotPassword'));
const UserResetPassword = lazy(() => import('../components/User/UserResetPassword'));
const UserCreateAccount = lazy(() => import('../components/User/UserCreateAccount'));
const UserTryForFree = lazy(() => import('../components/User/UserTryForFree'));
const TrialSignup = lazy(() => import('./Trial/TrialSignup'));
const TrialEmailVerification = lazy(() => import('./Trial/TrialEmailVerification'));
const UserSettings = lazy(() => import('../components/User/UserSettings'));
const DashboardIndex = lazy(() => import('../components/Dashboard/DashboardIndex'));
const CompanySettings = lazy(() => import('../components/Company/CompanySettings'));
const FinancialIndex = lazy(() => import('../components/Financial/FinancialIndex'));
const ExpenseIndex = lazy(() => import('../components/Expense/ExpenseIndex'));
const BillIndex = lazy(() => import('../components/Bill/BillIndex'));
const TimelineIndex = lazy(() => import('../components/Timeline/TimelineIndex'));
const MarketplaceIndex = lazy(() => import('../components/Marketplace/MarketplaceIndex'));
const EntityOverview = lazy(() => import('../components/EntityOverview/EntityOverview'));
const CapTable = lazy(() => import('../components/CapTable/CapTable'));
const Director = lazy(() => import('../components/Director/Director'));
const EntityMapping = lazy(() => import('../components/EntityMapping/EntityMapping'));

// import VerificationIndex from '../components/Verification/VerificationIndex';
const TaskIndex = lazy(() => import('../components/Task/TaskIndex'));
const IncorporationIndex = lazy(() => import('../components/Incorporation/IncorporationIndex'));
const IncorporationCompliance = lazy(() => import('../components/Incorporation/IncorporationCompliance'));
const IncorporationDirectorKyc = lazy(() => import('../components/Incorporation/IncorporationDirectorKyc'));
const IncorporationShareholderCorporateKyc = lazy(() => import('../components/Incorporation/IncorporationShareholderCorporateKyc'));
const IncorporationShareholderIndividualKyc = lazy(() => import('../components/Incorporation/IncorporationShareholderIndividualKyc'));
const IncorporationMemberIdentityIndividualKyc = lazy(() => import('../components/Incorporation/IncorporationMemberIdentityIndividualKyc'));
const IncorporationMemberIdentityCorporateKyc = lazy(() => import('../components/Incorporation/IncorporationMemberIdentityCorporateKyc'));
const SubscriptionIndex = lazy(() => import('../components/Subscription/SubscriptionIndex'));
const SubmissionsIndex = lazy(() => import('./Submissions/SubmissionsIndex'));

// const store = createStore(rootReducer, composeWithDevTools(
//     applyMiddleware(thunk)
// ));

class Index extends Component {

    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            hasPermission: true,
            isLoggedIn: true,
            constants: {},
            userPermissions: {},
            retrievedPermissions: false,
            isChatLoaded: false,
            isChatLoading: false
        };
        Raven.config('https://b1e26ede4a01403fb0a42cd49fad7185@sentry.io/291610', {
            environment: helpers.general.getEnv(),
            logger: 'webapp'
        }).install();
    }

    componentDidUpdate (prevProps, prevState, prevContext) {
        const self = this;
        const { currentServiceProvider, constants } = this.props;

        if (!this.state.isChatLoaded && !this.state.isChatLoading) {
            if (h.notEmpty(constants) && currentServiceProvider && currentServiceProvider.service_provider_chat_code) {
                switch (currentServiceProvider.service_provider_chat_type) {
                    case constants.SERVICE_PROVIDER.CHAT_TYPE.INTERCOM:
                        self.setState({isChatLoading: true});

                        if (!self.is3rdPartyChatLoaded(constants, currentServiceProvider.service_provider_chat_type, currentServiceProvider.service_provider_chat_code)) {
                            h.spChat.initIntercom(currentServiceProvider.service_provider_chat_code, () => {
                                self.setState({isChatLoaded: true, isChatLoading: false});
                            });
                        }
                        break;
                }
            }
        }
    }

    componentDidMount () {
        const { setSubdomain } = this.props;
        var self = this;

        GeneralModel.getConstant({}, function (data) {
            var apiRes = helpers.api.handleApiResponse(data, false);
            if (apiRes.status === 'ok' && apiRes.data) {
                // reload system constants
                if (apiRes.data.constants) {
                    self.props.setConstants(apiRes.data.constants);
                }
            }
        });

        var subdomain = general.getSubdomain();

        // this is development login url
        if (helpers.general.cmpStr(subdomain, 'localhost')) {
            // do nothing
        }
        // this is other environments' login url
        else if (!helpers.general.isDev() && helpers.general.cmpStr(subdomain, 'app')) {
            // do nothing
        }
        // this is custom subdomain for individual companies
        else {
            // TODO: validate if subdomain exists
            setSubdomain(subdomain);
            // var accessToken = general.findGetParameter('token');
            // proceed to validate and refresh access token if user is redirect to company specific url after signing in
            // if (!general.isEmpty(accessToken) && !auth.isAuthenticated()) {
            //     // set access token to cookie
            //     auth.setAccessToken(accessToken);
            //     // check with server if access token is valid
            //     UserModel.checkToken(function (data) {
            //         var apiRes = api.handleApiResponse(data, false);
            //         console.log(apiRes.data);
            //         // redirects user back to login page if access token is not valid
            //         if (!general.compareString(apiRes.status, 'ok')) {
            //             console.log('access token validation failed');
            //             // window.location.href = config.webappUrl;
            //         }
            //     });
            // }
            // else user should be authenticated
            if (!auth.isAuthenticated()) {
            //     // do nothing for now
            // }
            // // if not redirect user to login page
            // else {

                // window.location.href = config.webappUrl;

                // redirect user back to login page
                h.auth.redirectToLogin(config.webappUrl);
            }
        }
    }

    componentWillReceiveProps (nextProps, nextContext) {
        const self = this;
        h.company.detectEntitySwitching(nextProps, self.props, () => {
            // self.remove3rdPartyChat();
        });
    }

    updateLoading (loading, options = {}) {
        let { silent } = options;
        if (!silent && this.refs.spinner) {
            this.setState({loading: loading});
        }
    }

    noPermission (perm) {
        if (this.refs.redirect) {
            this.setState({hasPermission: perm});
        }
    }

    isAuthenticated (perm) {
        if (this.refs.isLoggedIn) {
            this.setState({isLoggedIn: perm});
        }
    }

    is3rdPartyChatLoaded (constants, chatType, chatCode) {
        switch (chatType) {
            case constants.SERVICE_PROVIDER.CHAT_TYPE.INTERCOM:
                let headScriptTags = document.head.getElementsByTagName('script');
                for (let i = 0; i < headScriptTags.length; i++) {
                    const headScriptTag = headScriptTags[i];
                    if (
                        headScriptTag.src &&
                        headScriptTag.src.indexOf('https://widget.intercom.io/widget') > -1 &&
                        headScriptTag.src.indexOf(chatCode) < 0
                    ) {
                        return true;
                    }
                }
                break;
        }
        return false;
    }

    remove3rdPartyChat (constants, chatType, chatCode) {
        let headScriptTags = document.head.getElementsByTagName('script');
        for (let i = 0; i < headScriptTags.length; i++) {
            const headScriptTag = headScriptTags[i];
            if (h.notEmpty(constants) && chatType) {
                switch (chatType) {
                    default:
                        if (hasIntercomScript(headScriptTag)) {
                            document.head.removeChild(headScriptTag);
                        }
                    case constants.SERVICE_PROVIDER.CHAT_TYPE.INTERCOM:
                        if (hasIntercomScript(headScriptTag) && headScriptTag.src.indexOf(chatCode) < 0) {
                            document.head.removeChild(headScriptTag);
                        }
                        break;
                }
            } else {
                if (hasIntercomScript(headScriptTag)) {
                    document.head.removeChild(headScriptTag);
                }
            }
        }
        function hasIntercomScript (headScriptTag) {
            if (
                headScriptTag.src &&
                headScriptTag.src.indexOf('https://widget.intercom.io/widget') > -1
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    render () {
        const self = this;
        const layoutBoxed = false;
        const navCollapsed = false;
        const navBehind = false;
        const fixedHeader = true;
        const sidebarWidth = 'small';
        // colorOption: '14',                              // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
        const theme = 'accountsNinjaTheme';
        const { setTheme, loaderStatus, constants, userPermissions, currentEntity, currentServiceProvider } = this.props;

        let materialUITheme;
        switch (theme) {
            case 'gray':
                materialUITheme = grayTheme;
                break;
            case 'dark':
                materialUITheme = darkTheme;
                break;
            case 'accountsNinjaTheme':
                materialUITheme = accountsNinjaTheme;
                break;
            default:
                materialUITheme = lightTheme;
        }
        setTheme(materialUITheme);

        // loading indicator container
        var SpinnerContainer = '';

        if (!general.isEmpty(loaderStatus)) {
            //Precedence to show network request loader over redux state loader
            if(this.state.loading) {
                SpinnerContainer =
                    <div className="spinnerWrapper">
                        <Spinner name='folding-cube' color="#0091F4" overrideSpinnerClassName="spinner"/>
                    </div>
            } else {
                SpinnerContainer = general.compareBoolean(loaderStatus, true) ?
                    <div className="spinnerWrapper">
                        <Spinner name='folding-cube' color="#0091F4" overrideSpinnerClassName="spinner"/>
                    </div>
                    : '';
            }

        } else {
            SpinnerContainer = general.compareBoolean(this.state.loading, true) ?
                <div className="spinnerWrapper">
                    <Spinner name='folding-cube' color="#0091F4" overrideSpinnerClassName="spinner"/>
                </div>
                : '';
        }

        // user does not have permission to access feature
        var PermissionRedirect = !this.state.hasPermission && Boolean(this.state.hasPermission) === false ?
            (helpers.user.hasViewPermission(constants, userPermissions, '/compliance') ?
            <Redirect to="/compliance" push={true} /> : <Redirect to="/tasks" push={true} />)
            : '';

        // flag to determine if entity has been incorporated yet or not
        const isIncorporated =
                h.notEmpty(currentEntity) &&
                h.notEmpty(currentEntity.entity_status) &&
                h.notEmpty(constants) &&
                h.notEmpty(constants.ENTITY_STATUSES) &&
                h.cmpStr(currentEntity.entity_status, (constants.ENTITY_STATUSES.PENDING || 'pending')) ? false : true;

        // let spChat = null;
//         if (constants && currentServiceProvider && currentServiceProvider.service_provider_chat_code) {
//             switch (currentServiceProvider.service_provider_chat_type) {
//                 case constants.SERVICE_PROVIDER.CHAT_TYPE.INTERCOM:
//                     if (!window.Intercom) {
//                         self.remove3rdPartyChat(constants, currentServiceProvider.service_provider_chat_type, currentServiceProvider.service_provider_chat_code);
// // console.log('we are here and ready!!!');
//                         // (function (id) {
//                         //     var w = window;
//                         //     var ic = w.Intercom;
//                         //     if (typeof ic === "function") {
//                         //         ic('reattach_activator');
//                         //         ic('update', window.intercomSettings);
//                         //     } else {
//                         //         var d = document;
//                         //         var i = function () {i.c(arguments)};
//                         //         i.q = [];
//                         //         i.c = function (args) {i.q.push(args)};
//                         //         w.Intercom = i;
//                         //
//                         //         function l () {
//                         //             var s = d.createElement('script');
//                         //             s.type = 'text/javascript';
//                         //             s.async = true;
//                         //             s.src = 'https://widget.intercom.io/widget/' + id;
//                         //             s.onload = function () {
//                         //                 window.Intercom("shutdown");
//                         //                 window.Intercom("boot", {app_id: id});
//                         //             };
//                         //             var x = d.getElementsByTagName('script')[0];
//                         //             x.parentNode.insertBefore(s, x);
//                         //         }
//                         //
//                         //         if (w.attachEvent) {
//                         //             w.attachEvent('onload', l);
//                         //         } else {
//                         //             w.addEventListener('load', l, false);
//                         //         }
//                         //     }
//                         // })(window, document, currentServiceProvider.service_provider_chat_code);
//

//
//                         // setTimeout(() => {
//                         //     const {constants, currentUser, currentServiceProvider} = self.props;
//                         //     window.Intercom("shutdown");
//                         //     delete window.intercomSettings;
//                         //     window.intercomSettings = {
//                         //         app_id: currentServiceProvider.service_provider_chat_code,
//                         //         user_id: currentUser.user_id,
//                         //         name: h.user.formatFirstMiddleLastName(currentUser),
//                         //         email: <h className="user email"></h>
//                         //     };
//                         //     window.Intercom("boot", window.intercomSettings);
//                         // }, 3000);
//                     }
//         //                 console.log('loading chat: ', currentServiceProvider.service_provider_chat_code);
//         //                 self.remove3rdPartyChat(constants, currentServiceProvider.service_provider_chat_type, currentServiceProvider.service_provider_chat_code);
//         //                 (function (w, d, id, s, x) {
//         //                     function i () {
//         //                         i.c(arguments);
//         //                     }
//         //                     i.q = [];
//         //                     i.c = function (args) {
//         //                         i.q.push(args);
//         //                     };
//         //                     w.Intercom = i;
//         //                     s = d.createElement('script');
//         //                     s.async = 1;
//         //                     s.src = 'https://widget.intercom.io/widget/' + id;
//         //                     d.head.appendChild(s);
//         //                 })(window, document, currentServiceProvider.service_provider_chat_code);
//                     break;
//             }
//         // } else {
//         //
//         }

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(materialUITheme)}>
                <div id="app-inner">
                    <div className="preloaderbar hide"><span className="bar" /></div>
                    <div
                        className={classnames('app-main full-height', {
                            'fixed-header': fixedHeader,
                            'nav-collapsed': navCollapsed,
                            'nav-behind': navBehind,
                            'layout-boxed': layoutBoxed,
                            'theme-gray': theme === 'gray',
                            'theme-dark': theme === 'dark',
                            'sidebar-sm': sidebarWidth === 'small',
                            'sidebar-lg': sidebarWidth === 'large'})
                        }>
                        {/* loading indicator */}
                        <div ref="spinner">{SpinnerContainer}</div>

                        <Router history={history}>
                            <div>
                                {/*<div ref="isLoggedIn">{AuthenticatedRedirect}</div>*/}
                                {/*{UserPermissionsRedirect}*/}
                                <div ref="redirect">{PermissionRedirect}</div>
                                <helpers.api.HttpInterceptors noPermission={this.noPermission.bind(this)}
                                                              updateLoading={this.updateLoading.bind(this)}
                                                              isAuthenticated={this.isAuthenticated.bind(this)}
                                                              {...this.state}/>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Switch>
                                        <Route exact path='/' component={UserLogin}/>
                                        <Route exact path='/login' component={UserLogin}/>
                                        <Route exact path='/signup' component={UserSignup}/>
                                        <Route exact path='/invite/:token' component={UserInviteSignup}/>
                                        <Route exact path='/verify-email' component={UserEmailVerification}/>
                                        <Route exact path='/verify-email/:token' component={UserEmailVerification}/>
                                        <Route exact path='/forgot-password' component={UserForgotPassword}/>
                                        <Route exact path='/reset-password/:token' component={UserResetPassword}/>
                                        <Route exact path="/trial/signup" component={TrialSignup}/>
                                        <Route exact path="/trial/signup/:email/:isGoogleMail" component={TrialSignup}/>
                                        <Route exact path="/trial/signup/:email/:isGoogleMail/:servicesSignedUpFor" component={TrialSignup}/>
                                        <Route exact path="/trial/signup/:email/:isGoogleMail/:servicesSignedUpFor/:isExistingZaveUser" component={TrialSignup}/>
                                        <Route exact path="/trial/verify-email/:token" component={TrialEmailVerification}/>
                                        {/*<Route exact path="/register" component={UserCreateAccount}/>*/}
                                        <Route exact path="/signup/success" component={UserTryForFree}/>
                                        <Route exact path="/incorporation" component={IncorporationIndex}/>
                                        <Route exact path="/incorporation/:email" component={IncorporationIndex}/>
                                        <Route exact path="/:countryCode/incorporation" component={IncorporationIndex}/>
                                        <Route exact path="/:countryCode/incorporation/:email" component={IncorporationIndex}/>
                                        <Route exact path="/incorporation/director/:token" component={IncorporationDirectorKyc}/>
                                        <Route exact path="/incorporation/shareholder/individual/:token" component={IncorporationShareholderIndividualKyc}/>
                                        <Route exact path="/incorporation/shareholder/corporate/:token" component={IncorporationShareholderCorporateKyc}/>
                                        <PrivateRoute exact path="/incorporation/member/individual/:memberIdentityId" component={IncorporationMemberIdentityIndividualKyc}/>
                                        <PrivateRoute exact path="/incorporation/member/corporate/:memberIdentityId" component={IncorporationMemberIdentityCorporateKyc}/>
                                        <Route exact path="/add-entity" component={UserCreateAccount}/>
                                        <PrivateRoute exact path="/compliance" component={DashboardIndex}/>
                                        <PrivateRoute exact path="/user-settings" component={UserSettings}/>
                                        <PrivateRoute exact path="/user-settings/:module" component={UserSettings}/>
                                        <PrivateRoute exact path="/admin-settings" component={CompanySettings}/>
                                        <PrivateRoute exact path="/admin-settings/:module" component={CompanySettings}/>
                                        <PrivateRoute exact path="/vitals" component={FinancialIndex}/>
                                        <PrivateRoute exact path="/expenses" component={ExpenseIndex}/>
                                        <PrivateRoute exact path="/expenses/:module" component={ExpenseIndex}/>
                                        <PrivateRoute exact path="/bills" component={BillIndex}/>
                                        <PrivateRoute exact path="/bills/:module" component={BillIndex}/>
                                        <PrivateRoute exact path="/submissions" component={SubmissionsIndex}/>
                                        <PrivateRoute exact path="/submissions/:module" component={SubmissionsIndex}/>
                                        {/*<PrivateRoute exact path="/verifications" component={VerificationIndex}/>*/}
                                        <PrivateRoute exact path="/tasks" component={TaskIndex}/>
                                        <PrivateRoute exact path="/tasks/:taskId" component={TaskIndex}/>
                                        <PrivateRoute exact path="/subscribe/:module" component={SubscriptionIndex}/>
                                        <PrivateRoute exact path="/timeline" component={TimelineIndex}/>
                                        <PrivateRoute exact path="/marketplace" component={MarketplaceIndex}/>
                                        <PrivateRoute exact path="/entity-overview" component={EntityOverview}/>
                                        <PrivateRoute exact path="/cap-table" component={CapTable}/>
                                        <PrivateRoute exact path="/boardroom" component={Director}/>
                                        <PrivateRoute exact path="/entity-mapping" component={EntityMapping}/>
                                        <Route component={Common404}/>
                                    </Switch>
                                </Suspense>
                            </div>
                        </Router>

                        {/* notification container */}
                        <ToastContainer
                            position="top-center"
                            type="default"
                            autoClose={10000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            pauseOnHover
                        />
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }

}

Index.propTypes = {
    currentUser: PropTypes.object.isRequired,
    currentEntity: PropTypes.object.isRequired,
    theme: PropTypes.object,
    constants: PropTypes.object.isRequired,
    userPermissions: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.user,
    currentEntity: state.company.currentEntity,
    currentServiceProvider: state.company.currentServiceProvider,
    theme: state.config.theme,
    loaderStatus: state.config.loaderStatus,
    constants: state.constants,
    userPermissions: state.permission
});

const mapDispatchToProps = {
    setSubdomain,
    setTheme,
    startLoader,
    stopLoader,
    setConstants,
    setPermission
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

/**
 * define private route that will be used for routes are only available for authenticated users
 */
// class PrivateRoute extends React.Component {
//
//     constructor () {
//         super();
//     }
//
//     componentDidMount () {
//         // const { getConfig } = this.props;
//         // if (getConfig) {
//         //     getConfig();
//         // }
//         var self = this;
//
//         // if (helpers.auth.isAuthenticated()) {
//         //     GeneralModel.getConfig({}, function (data) {
//         //         var apiRes = helpers.api.handleApiResponse(data, false);
//         //         if (apiRes.status === 'ok') {
//         //             if (apiRes.data) {
//         //                 if (apiRes.data.constants) {
//         //                     self.props.setConstants(apiRes.data.constants)
//         //                     // self.setState({constants: apiRes.data.constants});
//         //                 }
//         //                 if (apiRes.data.permissions) {
//         //                     self.props.setPermission(apiRes.data.permissions);
//         //                     // self.setState({userPermissions: apiRes.data.permissions});
//         //                 }
//         //             }
//         //         }
//         //         self.setState({retrievedPermissions: true});
//         //         self.forceUpdate();
//         //         console.log(self.props);
//         //     });
//         // }
//     }
//
//     render () {
//         const { component: Component, constants, userPermissions, ...rest } = this.props;
//
//         if (!helpers.auth.isAuthenticated()) {
//             helpers.general.alert('Please login first.', 'error');
//         }
//
//         var renderContent = <div/>;
//         // user authenticated
//         // if (helpers.auth.isAuthenticated()) {
//
//         // }
//         // user not authenticated yet
//         // else {
//         // renderContent = <Redirect to={{pathname: '/login', state: {from: this.props.location}}}/>;
//         // }
//
//         if (typeof constants === typeof undefined && typeof userPermissions === typeof undefined) {
//             renderContent = <div/>;
//         } else if (Object.keys(constants).length === 0 || Object.keys(userPermissions).length === 0) {
//             renderContent = <div/>;
//         } else {
//             renderContent = <Component {...this.props}/>;
//         }
//
//         return helpers.general.initConfig(this.props) || (
//             <Route {...rest} render={props => (renderContent)}/>
//         );
//     }
//
// }

// PrivateRoute.propTypes = {
//     currentUser: PropTypes.object.isRequired,
//     constants: PropTypes.object.isRequired,
//     userPermissions: PropTypes.object.isRequired
// };
//
// const mapStateToProps = state => ({
//     currentUser: state.user,
//     constants: state.constants,
//     userPermissions: state.permission
// });
//
// const mapDispatchToProps = {
//     setConstants,
//     setPermission
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

// function render() {
//     ReactDOM.render(
//         <Index/>,
//         document.getElementById('app')
//     );
// }

// function render() {
//     ReactDOM.render(
//         <Provider store={store}>
//             <Index/>
//         </Provider>,
//         document.getElementById('app')
//     );
// }

// render();
