export default function taskconcierge (state = {}, action) {
    switch (action.type) {
        case 'REFRESH_AFTER_TASK_CONCIERGE_NAVIGATION':
            let deep_clone = state;
            console.log(state);
            return {
                ...state
            }
        default:
            return state;
    }
}