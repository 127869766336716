import 'core-js/es6/date';
import 'core-js/es6/function';
import 'core-js/es6/map';
import 'core-js/es6/math';
import 'core-js/es6/object';
import 'core-js/es6/parse-float';
import 'core-js/es6/parse-int';
import 'core-js/es6/regexp';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/es6/number';
import 'core-js/es6/array';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly.js';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import Index from './components/Index';
import './components/i18n';

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk)
));

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <Index/>
        </Provider>,
        document.getElementById('app')
    );
}

render();
