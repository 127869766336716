import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';

import config from './config';
import constants from './constants';
import permission from './permission';
import user from './user';
import company from './company';
import expense from './expense';
import bankTransaction from './bankTransaction';
import bill from './bill';
import form from './form';
import trial from './trial';
import task from './task';
import privateTask from './privateTask';
import demo from './demo';
import notification from './notification';
import incorporation from './incorporation';
import pricing from './pricing';
import analytics from './analytics';
import taskconcierge from './taskconcierge';

export default combineReducers({
    config,
    constants,
    permission,
    user,
    company,
    expense,
    bankTransaction,
    bill,
    form,
    trial,
    task,
    taskconcierge,
    privateTask,
    demo,
    notification,
    incorporation,
    pricing,
    analytics
    // form: formReducer
});
