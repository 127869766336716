import * as generalHelper from './general';
const general = generalHelper;

var userHelper = {

    /**
     * Checks whether user has access to a certain feature by module name and action
     * @param {object} constant
     * @param {object} userPermissions
     * @param {string} moduleName
     * @param {string} action
     */
    hasPermission: (constant, userPermissions, moduleName, action) => {
        console.log(userPermissions[moduleName][action]);
        if (
            constant && userPermissions && moduleName && action &&
            userPermissions[moduleName] &&
            userPermissions[moduleName][action] &&
            parseInt(userPermissions[moduleName][action]) === 1
        ) {
            return true;
        }
        return false;
    },

    /**
     * checks that user has view permission
     * @param constant
     * @param permissions
     * @param pathname
     * @returns {boolean}
     */
    hasViewPermission: function (constant, permissions, pathname) {
        var currentPathname = '';
        var moduleName = '';
        if (constant &&
            constant.MODULES &&
            constant.PERMISSIONS &&
            permissions) {
            currentPathname = pathname || window.location.pathname;
            if (generalHelper.isEmpty(currentPathname)) {
                return false;
            }
            // route to module mapping
            switch (currentPathname) {
                default:
                case '/compliance':
                    moduleName = constant.MODULES.COMPLIANCE;
                    break;
                case '/admin-settings':
                    moduleName = constant.MODULES.ADMIN_SETTINGS;
                    break;
                case '/vitals':
                    moduleName = constant.MODULES.FINANCIAL_INDEX;
                    break;
                case '/bills':
                    moduleName = constant.MODULES.BILL_INDEX;
                    break;
                case '/timeline':
                    moduleName = constant.MODULES.TIMELINE_INDEX;
                    break;
                // TODO: need to refactor individual Expense and Tasks modules into single group.
                case '/tasks':
                    moduleName = constant.MODULES.TASK_INDEX;
                    break;
                case '/expenses':
                    moduleName = constant.MODULES.EXPENSE_INDEX;
                    break;
                case '/expenses/awaiting-authorisation':
                    moduleName = constant.MODULES.EXPENSE_AWAITING_AUTHORISATION;
                    break;
                case '/expenses/awaiting-payment':
                    moduleName = constant.MODULES.EXPENSE_AWAITING_PAYMENT;
                    break;
                case '/expenses/previous-claims':
                    moduleName = constant.MODULES.EXPENSE_PREVIOUS_CLAIMS;
                    break;
                case '/marketplace':
                    moduleName = constant.MODULES.MARKETPLACE_INDEX;
                    break;
                case '/cap-table':
                    moduleName = constant.MODULES.CAP_TABLE;
                    break;
                case '/boardroom':
                    moduleName = constant.MODULES.DIRECTOR;
                    break;
            }
            if (permissions[moduleName] &&
                generalHelper.cmpBool(permissions[moduleName][constant.PERMISSIONS.VIEW], true)) {

                return true;
            } else {
                return false;
            }
        } else {

            return false;
        }
    },


    /**
     * formats user's first name and last name
     * @param data
     */
    formatFirstLastName: function (data) {
        var formattedName = '';
        if (general.notEmpty(data.first_name)) {
            formattedName += data.first_name;
        }
        if (general.notEmpty(data.last_name)) {
            if (general.notEmpty(formattedName)) {
                formattedName += ' ' + data.last_name;
            } else {
                formattedName += data.first_name;
            }
        }
        formattedName = general.ucFirstAllWords(formattedName);
        return formattedName;
    },
    /**
     * formats user's first name, middle name and last name
     * @param data
     * @returns {string}
     */
    formatFirstMiddleLastName: function (data) {
        var formattedName = '';

        // no data found
        if (generalHelper.isEmpty(data)) {
            return formattedName;
        }

        // first name
        if (generalHelper.notEmpty(data.first_name)) {
            formattedName = data.first_name;
        }
        // middle name
        if (generalHelper.notEmpty(data.middle_name)) {
            formattedName = generalHelper.isEmpty(formattedName) ? data.middle_name : formattedName + ' ' + data.middle_name;
        }
        // last name
        if (generalHelper.notEmpty(data.last_name)) {
            formattedName = generalHelper.isEmpty(formattedName) ? data.last_name : formattedName + ' ' + data.last_name;
        }
        formattedName = generalHelper.ucFirstAllWords(formattedName);
        return formattedName;
    }

};

export default userHelper;
