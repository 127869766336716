let DOCUMENT_TYPES = require('./documentTypes');
let DOCUMENT_STATUS = require('./documentStatus');
let EVENTS = require('./events');
let MEMBER_TYPES = require('./memberTypes');
let SHAREHOLDER = require('./shareholder');
let IDENTITY_STATUS = require('./identityStatus');
module.exports = {
    DOCUMENT_TYPES,
    DOCUMENT_STATUS,
    DOCUMENT_CLASSIFICATION: {
        INDIVIDUAL_IDENTIFICATION: 'individual_identification'
    },
    EVENTS,
    MEMBER_TYPES,
    SHAREHOLDER,
    IDENTITY_STATUS,
    MEMBER_IDENTITY_KYC_STATUS: {
        APPROVED: 'approved',
        DISAPPROVED: 'disapproved',
        PENDING: 'pending'
    }
};