import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { general } from '../../../helpers/index';
import { black } from "material-ui/styles/colors";
import Dialog from 'material-ui/Dialog';
import FontAwesome from 'react-fontawesome';
import CommonFloatingActionButton from '../../Common/CommonFloatingActionButton';
import PartialTaskChatInput from '../Task/PartialTaskChatInput';
import { helpers, h } from "../../../helpers";
import FileDrop from "react-file-drop";
import CommonOverlay from '../../Common/CommonOverlay';

/*======================================================================================================================
 * define react component
 *====================================================================================================================*/
export class PartialTaskAddDialog extends React.Component {

    constructor () {
        super ();
        var additionalFields = {
            // material-ui dialog
            open: false,
            // drag-and-drop file upload overlay
            showUploadOverlay: false,
            // drag-and-drop files
            droppedFiles: [],
            // fields
            fields: {
                type: {},
                message: {}
            }
        };
        this.state = general.initState(additionalFields);
        helpers.logHelper.log({subPath: 'add-task'});
    }


    componentDidMount () {
        const { constants } = this.props;
        var { fields } = this.state;

        // default selection of task type to miscellaneous
        fields.type.value = constants.TASK_TYPES_CLIENT.MISCELLANEOUS.VALUE;
        this.setState({fields: fields}, () => {
            if(this.props.forceOpen) {
                this.onDialogOpen();
            }
        });
    }

    onTaskTypeChange (event, index, value) {
        var key = 'type',
            fields = this.state.fields;
        fields[key].value = value;
        fields[key].dirty = true;
        this.setState({fields: fields});
        this.validate(key);
    }

    onChange (e) {
        var { fields } = this.state;
        if (fields && e && e.target) {
            var key = e.target.name;
            var value = e.target.value;

            fields[key].value = value;
            fields[key].error = '';
        }

        this.validate(key);

        this.setState({fields: fields});
    }

    validate (key) {
        var fields = this.state.fields,
            value = fields[key].value || '';

        fields[key].dirty = true;
        fields[key].error = '';

        switch (key) {
            case 'type': {
                if (general.isEmpty(value)) {
                    fields.type.error = 'Please select task type';
                }
                break;
            }
            case 'message':
                if (general.isEmpty(value)) {
                    fields.message.error = 'Please enter message before sending';
                }
                break;
            default:
        }

        this.setState({fields: fields});
    }

    /**
     * handles dialog open event
     */
    onDialogOpen () {
        this.setState({open: true});
    };

    /**
     * handles dialog close event
     */
    onDialogClose (event) {
        console.log('First layer dialog ====', event);
        this.setState({open: false}, () => {
            let { triggerSource = { source: '', payload: ''} } = this.props
            if(helpers.general.cmpStr(triggerSource.source, 'entity-mapping')) {
                if(!h.isEmpty(this.props.forceClose)) {
                    this.props.forceClose(event);
                }
            }

            if(!h.isEmpty(this.props.exitOthersTaskScreen)) {
                this.props.exitOthersTaskScreen();
            }
        });
    };

    // onCreateTask (e) {
    //     e.preventDefault();
    //     var self = this;
    //     var { currentEntity, currentUser } = this.props;
    //     var { fields } = this.state;
    //     var isValid = true;
    //
    //     // validate that entire form has no more errors
    //     for (var key in this.state.fields) {
    //         if (!this.state.fields[key].dirty) {
    //             this.validate(key);
    //             isValid = false;
    //         } else if (this.state.fields[key].error && this.state.fields[key].error !== '') {
    //             this.validate(key);
    //             isValid = false;
    //         }
    //     }
    //
    //     // check that form is valid
    //     if (isValid) {
    //         var query = {
    //             entity_id: currentEntity.entity_id,
    //             task_type: fields.type.value || '',
    //             task_from_user_id: currentUser.user_id,
    //             task_message: fields.message.value || ''
    //         };
    //         TaskModel.createTask(query, function (data) {
    //             var apiRes = api.handleApiResponse(data, true);
    //             if (apiRes.status === 'ok') {
    //                 self.props.reloadTasks();
    //                 self.setState({open: false});
    //             }
    //         });
    //     }
    // }


    render () {
        const { constants, currentEntity, triggerSource = { source: '', payload: ''}, renderFloatingButton } = this.props;
        const showFloatingButton = h.notEmpty(this.props.renderFloatingButton) && h.cmpBool(this.props.renderFloatingButton, false) ? false : true;
        var taskTypes = [];
        for (var key in constants.TASK_TYPES_CLIENT) {
            taskTypes.push({
                text: constants.TASK_TYPES_CLIENT[key].TEXT,
                value: constants.TASK_TYPES_CLIENT[key].VALUE
            });
        }

        if(!h.isEmpty(currentEntity.entity_permissions.task_concierge) && !helpers.general.cmpStr(triggerSource.source, 'entity-mapping')) {
            return (
                <div>
                        <FileDrop onDrop={this.handleDrop.bind(this)}
                                  onFrameDragEnter={this.handleDragOver.bind(this)}
                                  onFrameDragLeave={this.handleDragLeave.bind(this)}
                                  onFrameDrop={this.handleDragLeave.bind(this)}>
                            <PartialTaskChatInput
                                reloadTaskMessages={() => this.props.reloadTasks()}
                                droppedFiles={this.state.droppedFiles}
                                resetDroppedFiles={this.resetDroppedFiles.bind(this)}
                                closeDialog={(event) => this.onDialogClose(event)}
                                //onBackButtonPressed={() => this.props.onBackButton()}
                                existingMessage={this.props.messagePayload}
                                existingSubject={this.props.subjectPayload}
                                getSuggestions={(input) => this.props.getSuggestions(input)}
                                suggestionsArray={this.props.suggestionsArray}
                                openConciergeWorkflow={this.props.openConciergeWorkflow}
                                onCreateWorkflowAnalytics={this.props.onCreateWorkflowAnalytics}
                            />
                            {h.cmpBool(this.state.showUploadOverlay, true) &&
                            <CommonOverlay content={
                                <div>
                                    <FontAwesome
                                        name='upload'
                                        size='2x'
                                    /><br/><br/>
                                    Upload attachment to task
                                </div>}
                            />}
                        </FileDrop>

                </div>
            );
        }

        return (
            <div>
                {showFloatingButton ? <CommonFloatingActionButton onAddButtonClick={this.onDialogOpen.bind(this)} /> : null}
                <Dialog
                    title={<div>Create a task
                        <FontAwesome
                            className="float-right"
                            onClick={() => this.onDialogClose('close-event')}
                            name='times-circle-o'
                            style={{color: black, marginRight: -20, marginTop: -20, cursor: 'pointer'}}
                            size='2x'/>&nbsp;
                    </div>}
                    contentClassName="dialog-container-chat"
                    modal={true}
                    open={this.state.open}
                    onRequestClose={this.onDialogClose.bind(this)}
                    autoScrollBodyContent={true}
                    repositionOnUpdate={true}
                >

                    <FileDrop onDrop={this.handleDrop.bind(this)}
                              onFrameDragEnter={this.handleDragOver.bind(this)}
                              onFrameDragLeave={this.handleDragLeave.bind(this)}
                              onFrameDrop={this.handleDragLeave.bind(this)}>
                        <PartialTaskChatInput
                            reloadTaskMessages={() => this.props.reloadTasks()}
                            droppedFiles={this.state.droppedFiles}
                            resetDroppedFiles={this.resetDroppedFiles.bind(this)}
                            closeDialog={(event) => this.onDialogClose(event)}
                            triggerSource={this.props.triggerSource}
                            isBroadcastSuccess={this.props.isBroadcastSuccess}
                            handleRequestDelete={this.props.handleRequestDelete}
                        />
                        {h.cmpBool(this.state.showUploadOverlay, true) &&
                        <CommonOverlay content={
                            <div>
                                <FontAwesome
                                    name='upload'
                                    size='2x'
                                /><br/><br/>
                                Upload attachment to task
                            </div>}
                        />}
                    </FileDrop>
                </Dialog>
            </div>
        )
    }

    handleDrop (files, event) {
        this.setState({droppedFiles: files});
    }

    handleDragOver (event) {
        this.setState({showUploadOverlay: true});
    }

    handleDragLeave (event) {
        this.setState({showUploadOverlay: false});
    }

    resetDroppedFiles () {
        this.setState({droppedFiles: []});
    }
}

PartialTaskAddDialog.propTypes = {
    currentUser: PropTypes.object.isRequired,
    currentCompany: PropTypes.object,
    currentEntity: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    verifications: PropTypes.array
};

const mapStateToProps = state => ({
    currentUser: state.user,
    currentCompany: state.company.company,
    currentEntity: state.company.currentEntity,
    theme: state.config.theme,
    constants: state.constants,
    verifications: state.bankTransaction.verifications
});

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartialTaskAddDialog));
