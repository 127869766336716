export default function bill (state = {}, action) {
    switch (action.type) {
        case 'SET_NEW_BILLS':
            return {
                ...state,
                newBills: action.bills
            };
        case 'SET_AWAITING_BILLS':
            return {
                ...state,
                awaitingBills: action.bills
            };
        case 'SET_ARCHIVED_BILLS':
            return {
                ...state,
                archivedBills: action.bills
            };
        case 'SET_BILLS_SUMMARY':
            return {
                ...state,
                billsSummary: action.bills
            };
        default:
            return state;
    }
}