module.exports = {
    SECURITY_TYPES: {
        ORDINARY: 'ordinary',
        PREFERRED: 'preferred'
    },
    TRANSACTION_STATUS: {
        PENDING: 'pending',
        COMPLETE: 'complete',
        DRAFT: 'draft',
        PREVIOUS: 'previous'
    },
    HOLDING_STATUS: {
        PENDING: 'pending',
        CURRENT: 'current',
        PREVIOUS: 'previous'
    }
};