import * as generalHelper from './general';
import * as dateHelper from './date';
import * as apiHelper from './api';
import * as authHelper from './auth';
import * as validateHelper from './validate';
import companyHelper from './company';
import * as formHelper from './form';
import * as historyHelper from './history';
import * as currencyHelper from './currency';
import logHelper from './log';
import userHelper from './user';
import pricingHelper from './pricing';
import * as spChatHelper from './spChat';
import * as billingSubscriptionHelper from './billingSubscription';
import * as entityBillingHelper from './entityBilling';
import * as stripeHelper from './stripe';
import * as recaptchaHelper from './recaptcha';
import * as expenseHelper from './expense';
import * as fileHelper from './file';
import * as timelineHelper from './timeline';

export const helpers = {
    general: generalHelper,
    date: dateHelper,
    api: apiHelper,
    auth: authHelper,
    validate: validateHelper,
    company: companyHelper,
    form: formHelper,
    history: historyHelper,
    currency: currencyHelper,
    logHelper: logHelper,
    user: userHelper,
    pricing: pricingHelper,
    spChat: spChatHelper,
    billingSubscription: billingSubscriptionHelper,
    entityBilling: entityBillingHelper,
    file: fileHelper,
    timeline: timelineHelper,
    // general helper functions to expose on top level
    log: generalHelper.log,
    isEmpty: generalHelper.isEmpty,
    notEmpty: generalHelper.notEmpty,
    cmpStr: generalHelper.compareString,
    cmpInt: generalHelper.compareInt,
    cmpBool: generalHelper.compareBoolean,
    stripe: stripeHelper,
    recaptcha: recaptchaHelper,
    expense: expenseHelper
};

export const h = {
    general: generalHelper,
    date: dateHelper,
    api: apiHelper,
    auth: authHelper,
    validate: validateHelper,
    company: companyHelper,
    form: formHelper,
    history: historyHelper,
    currency: currencyHelper,
    logHelper: logHelper,
    user: userHelper,
    pricing: pricingHelper,
    spChat: spChatHelper,
    billingSubscription: billingSubscriptionHelper,
    entityBilling: entityBillingHelper,
    file: fileHelper,
    timeline: timelineHelper,
    // general helper functions to expose on top level
    log: generalHelper.log,
    isEmpty: generalHelper.isEmpty,
    notEmpty: generalHelper.notEmpty,
    cmpStr: generalHelper.compareString,
    cmpInt: generalHelper.compareInt,
    cmpBool: generalHelper.compareBoolean,
    stripe: stripeHelper,
    recaptcha: recaptchaHelper,
    expense: expenseHelper
};

export const general = generalHelper;
export const date = dateHelper;
export const api = apiHelper;
export const auth = authHelper;
export const validate = validateHelper;
export const company = companyHelper;
export const form = formHelper;
export const history = historyHelper;
export const currency = currencyHelper;
export const log = logHelper;
export const user = userHelper;
export const pricing = pricingHelper;
export const spChat = spChatHelper;
export const billingSubscription = billingSubscriptionHelper;
export const entityBilling = entityBillingHelper;
export const stripe = stripeHelper;
export const recaptcha = recaptchaHelper;
export const expense = expenseHelper;
export const file = fileHelper;
