import { helpers, h } from '../helpers/index';
import { config } from "../configs/config";
import * as Constants from '../constants';

export default function incorporation (state = {}, action) {
    switch (action.type) {
        case 'SET_INCORPORATION_FORM_SG':
            action.incorporationFormSG = action.incorporationFormSG || {};
            var incorporationForm = {};
            // map list of incorporation form fields by country
            switch (action.countryCode) {
                default:
                case 'SG':
                    incorporationForm = {
                        incorporation_form_id: action.incorporationFormSG.incorporation_form_incorporation_form_id || action.incorporationFormSG.incorporation_form_id,
                        entity_name: action.incorporationFormSG.incorporation_form_sg_entity_name || action.incorporationFormSG.entity_name,
                        incorporation_pricing_plan_id: action.incorporationFormSG.incorporation_form_sg_incorporation_pricing_plan_fk || action.incorporationFormSG.incorporation_pricing_plan_id,
                        // use_corporate_secretary: action.incorporationFormSG.incorporation_form_sg_is_use_corporate_secretary || action.incorporationFormSG.use_corporate_secretary || 1,
                        corporate_secretary_pricing_plan_id: action.incorporationFormSG.incorporation_form_sg_corporate_secretary_pricing_plan_fk || action.incorporationFormSG.corporate_secretary_pricing_plan_id,
                        description: action.incorporationFormSG.incorporation_form_sg_description_of_business || action.incorporationFormSG.description,
                        industry: action.incorporationFormSG.incorporation_form_sg_industry || action.incorporationFormSG.industry,
                        financial_year_end: action.incorporationFormSG.incorporation_form_sg_financial_year_end || action.incorporationFormSG.financial_year_end || helpers.date.calculateDefaultFYE_SG(),
                        use_zave_address: action.incorporationFormSG.incorporation_form_sg_is_use_zave_address || action.incorporationFormSG.use_zave_address || 0,
                        use_zave_address_pricing_plan_id: action.incorporationFormSG.incorporation_form_sg_use_zave_address_pricing_plan_fk || action.incorporationFormSG.use_zave_address_pricing_plan_id,
                        address_suite: action.incorporationFormSG.incorporation_form_sg_suite || action.incorporationFormSG.address_suite,
                        address_street: action.incorporationFormSG.incorporation_form_sg_address || action.incorporationFormSG.address_street,
                        address_postal: action.incorporationFormSG.incorporation_form_sg_postal_code || action.incorporationFormSG.address_postal,
                        use_zave_director: action.incorporationFormSG.incorporation_form_sg_is_appoint_nominee_director || action.incorporationFormSG.use_zave_director || 0,
                        nominee_director_pricing_plan_id: action.incorporationFormSG.incorporation_form_sg_nominee_director_pricing_plan_fk || action.incorporationFormSG.nominee_director_pricing_plan_id,
                        use_zave_shareholder: action.incorporationFormSG.incorporation_form_sg_is_appoint_nominee_shareholder || action.incorporationFormSG.use_zave_shareholder || 0,
                        nominee_shareholder_pricing_plan_id: action.incorporationFormSG.incorporation_form_sg_nominee_shareholder_pricing_plan_fk || action.incorporationFormSG.nominee_shareholder_pricing_plan_id,
                        share_par_value: action.incorporationFormSG.incorporation_form_sg_share_par_value || action.incorporationFormSG.share_par_value,
                        share_left: action.incorporationFormSG.incorporation_form_sg_share_left || action.incorporationFormSG.share_left,
                        share_count: action.incorporationFormSG.incorporation_form_sg_share_count || action.incorporationFormSG.share_count,
                        share_allocated: action.incorporationFormSG.share_allocated || 0,
                        payment_method: action.incorporationFormSG.incorporation_form_payment_method || action.incorporationFormSG.payment_method || Constants.PaymentMethods.DEFAULT_PAYMENT_METHOD.payment_method_id
                    };
                    if (h.notEmpty(action.incorporationFormSG.incorporation_form_sg_is_use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.incorporation_form_sg_is_use_corporate_secretary;
                    } else if (h.notEmpty(action.incorporationFormSG.use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.use_corporate_secretary;
                    } else {
                        incorporationForm.use_corporate_secretary = 1;
                    }
                    break;
                case 'AU':
                    incorporationForm = {
                        incorporation_form_id: action.incorporationFormSG.incorporation_form_incorporation_form_id || action.incorporationFormSG.incorporation_form_id,
                        entity_name: action.incorporationFormSG.incorporation_form_au_entity_name || action.incorporationFormSG.entity_name,
                        incorporation_pricing_plan_id: action.incorporationFormSG.incorporation_form_au_incorporation_pricing_plan_fk || action.incorporationFormSG.incorporation_pricing_plan_id,
                        // use_corporate_secretary: action.incorporationFormSG.incorporation_form_au_is_use_corporate_secretary || action.incorporationFormSG.use_corporate_secretary || 1,
                        corporate_secretary_pricing_plan_id: action.incorporationFormSG.incorporation_form_au_corporate_secretary_pricing_plan_fk || action.incorporationFormSG.corporate_secretary_pricing_plan_id,
                        description: action.incorporationFormSG.incorporation_form_au_description_of_business || action.incorporationFormSG.description,
                        industry: action.incorporationFormSG.incorporation_form_au_industry || action.incorporationFormSG.industry,
                        financial_year_end: action.incorporationFormSG.incorporation_form_au_financial_year_end || action.incorporationFormSG.financial_year_end || helpers.date.calculateDefaultFYE_SG(),
                        use_zave_address: action.incorporationFormSG.incorporation_form_au_is_use_zave_address || action.incorporationFormSG.use_zave_address || 0,
                        use_zave_address_pricing_plan_id: action.incorporationFormSG.incorporation_form_au_use_zave_address_pricing_plan_fk || action.incorporationFormSG.use_zave_address_pricing_plan_id,
                        address_suite: action.incorporationFormSG.incorporation_form_au_suite || action.incorporationFormSG.address_suite,
                        address_street: action.incorporationFormSG.incorporation_form_au_address || action.incorporationFormSG.address_street,
                        address_postal: action.incorporationFormSG.incorporation_form_au_postal_code || action.incorporationFormSG.address_postal,
                        use_zave_director: action.incorporationFormSG.incorporation_form_au_is_appoint_nominee_director || action.incorporationFormSG.use_zave_director || 0,
                        nominee_director_pricing_plan_id: action.incorporationFormSG.incorporation_form_au_nominee_director_pricing_plan_fk || action.incorporationFormSG.nominee_director_pricing_plan_id,
                        use_zave_shareholder: action.incorporationFormSG.incorporation_form_au_is_appoint_nominee_shareholder || action.incorporationFormSG.use_zave_shareholder || 0,
                        nominee_shareholder_pricing_plan_id: action.incorporationFormSG.incorporation_form_au_nominee_shareholder_pricing_plan_fk || action.incorporationFormSG.nominee_shareholder_pricing_plan_id,
                        share_par_value: action.incorporationFormSG.incorporation_form_au_share_par_value || action.incorporationFormSG.share_par_value,
                        share_left: action.incorporationFormSG.incorporation_form_au_share_left || action.incorporationFormSG.share_left,
                        share_count: action.incorporationFormSG.incorporation_form_au_share_count || action.incorporationFormSG.share_count,
                        share_allocated: action.incorporationFormSG.share_allocated || 0,
                        payment_method: action.incorporationFormSG.incorporation_form_payment_method || action.incorporationFormSG.payment_method || Constants.PaymentMethods.DEFAULT_PAYMENT_METHOD.payment_method_id
                    };
                    if (h.notEmpty(action.incorporationFormSG.incorporation_form_au_is_use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.incorporation_form_au_is_use_corporate_secretary;
                    } else if (h.notEmpty(action.incorporationFormSG.use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.use_corporate_secretary;
                    } else {
                        incorporationForm.use_corporate_secretary = 1;
                    }
                    break;
                case 'HK':
                    incorporationForm = {
                        incorporation_form_id: action.incorporationFormSG.incorporation_form_incorporation_form_id || action.incorporationFormSG.incorporation_form_id,
                        entity_name: action.incorporationFormSG.incorporation_form_hk_entity_name || action.incorporationFormSG.entity_name,
                        incorporation_pricing_plan_id: action.incorporationFormSG.incorporation_form_hk_incorporation_pricing_plan_fk || action.incorporationFormSG.incorporation_pricing_plan_id,
                        // use_corporate_secretary: action.incorporationFormSG.incorporation_form_hk_is_use_corporate_secretary || action.incorporationFormSG.use_corporate_secretary || 1,
                        corporate_secretary_pricing_plan_id: action.incorporationFormSG.incorporation_form_hk_corporate_secretary_pricing_plan_fk || action.incorporationFormSG.corporate_secretary_pricing_plan_id,
                        description: action.incorporationFormSG.incorporation_form_hk_description_of_business || action.incorporationFormSG.description,
                        industry: action.incorporationFormSG.incorporation_form_hk_industry || action.incorporationFormSG.industry,
                        financial_year_end: action.incorporationFormSG.incorporation_form_hk_financial_year_end || action.incorporationFormSG.financial_year_end || helpers.date.calculateDefaultFYE_SG(),
                        use_zave_address: action.incorporationFormSG.incorporation_form_hk_is_use_zave_address || action.incorporationFormSG.use_zave_address || 0,
                        use_zave_address_pricing_plan_id: action.incorporationFormSG.incorporation_form_hk_use_zave_address_pricing_plan_fk || action.incorporationFormSG.use_zave_address_pricing_plan_id,
                        address_suite: action.incorporationFormSG.incorporation_form_hk_suite || action.incorporationFormSG.address_suite,
                        address_street: action.incorporationFormSG.incorporation_form_hk_address || action.incorporationFormSG.address_street,
                        address_postal: action.incorporationFormSG.incorporation_form_hk_postal_code || action.incorporationFormSG.address_postal,
                        use_zave_director: action.incorporationFormSG.incorporation_form_hk_is_appoint_nominee_director || action.incorporationFormSG.use_zave_director || 0,
                        nominee_director_pricing_plan_id: action.incorporationFormSG.incorporation_form_hk_nominee_director_pricing_plan_fk || action.incorporationFormSG.nominee_director_pricing_plan_id,
                        use_zave_shareholder: action.incorporationFormSG.incorporation_form_hk_is_appoint_nominee_shareholder || action.incorporationFormSG.use_zave_shareholder || 0,
                        nominee_shareholder_pricing_plan_id: action.incorporationFormSG.incorporation_form_hk_nominee_shareholder_pricing_plan_fk || action.incorporationFormSG.nominee_shareholder_pricing_plan_id,
                        share_par_value: action.incorporationFormSG.incorporation_form_hk_share_par_value || action.incorporationFormSG.share_par_value,
                        share_left: action.incorporationFormSG.incorporation_form_hk_share_left || action.incorporationFormSG.share_left,
                        share_count: action.incorporationFormSG.incorporation_form_hk_share_count || action.incorporationFormSG.share_count,
                        share_allocated: action.incorporationFormSG.share_allocated || 0,
                        payment_method: action.incorporationFormSG.incorporation_form_payment_method || action.incorporationFormSG.payment_method || Constants.PaymentMethods.DEFAULT_PAYMENT_METHOD.payment_method_id
                    };
                    if (h.notEmpty(action.incorporationFormSG.incorporation_form_hk_is_use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.incorporation_form_hk_is_use_corporate_secretary;
                    } else if (h.notEmpty(action.incorporationFormSG.use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.use_corporate_secretary;
                    } else {
                        incorporationForm.use_corporate_secretary = 1;
                    }
                    break;
                case 'VG':
                    incorporationForm = {
                        incorporation_form_id: action.incorporationFormSG.incorporation_form_incorporation_form_id || action.incorporationFormSG.incorporation_form_id,
                        entity_name: action.incorporationFormSG.incorporation_form_vg_entity_name || action.incorporationFormSG.entity_name,
                        incorporation_pricing_plan_id: action.incorporationFormSG.incorporation_form_vg_incorporation_pricing_plan_fk || action.incorporationFormSG.incorporation_pricing_plan_id,
                        // use_corporate_secretary: action.incorporationFormSG.incorporation_form_vg_is_use_corporate_secretary || action.incorporationFormSG.use_corporate_secretary || 1,
                        corporate_secretary_pricing_plan_id: action.incorporationFormSG.incorporation_form_vg_corporate_secretary_pricing_plan_fk || action.incorporationFormSG.corporate_secretary_pricing_plan_id,
                        description: action.incorporationFormSG.incorporation_form_vg_description_of_business || action.incorporationFormSG.description,
                        industry: action.incorporationFormSG.incorporation_form_vg_industry || action.incorporationFormSG.industry,
                        financial_year_end: action.incorporationFormSG.incorporation_form_vg_financial_year_end || action.incorporationFormSG.financial_year_end || helpers.date.calculateDefaultFYE_SG(),
                        use_zave_address: action.incorporationFormSG.incorporation_form_vg_is_use_zave_address || action.incorporationFormSG.use_zave_address || 0,
                        use_zave_address_pricing_plan_id: action.incorporationFormSG.incorporation_form_vg_use_zave_address_pricing_plan_fk || action.incorporationFormSG.use_zave_address_pricing_plan_id,
                        address_suite: action.incorporationFormSG.incorporation_form_vg_suite || action.incorporationFormSG.address_suite,
                        address_street: action.incorporationFormSG.incorporation_form_vg_address || action.incorporationFormSG.address_street,
                        address_postal: action.incorporationFormSG.incorporation_form_vg_postal_code || action.incorporationFormSG.address_postal,
                        use_zave_director: action.incorporationFormSG.incorporation_form_vg_is_appoint_nominee_director || action.incorporationFormSG.use_zave_director || 0,
                        nominee_director_pricing_plan_id: action.incorporationFormSG.incorporation_form_vg_nominee_director_pricing_plan_fk || action.incorporationFormSG.nominee_director_pricing_plan_id,
                        use_zave_shareholder: action.incorporationFormSG.incorporation_form_vg_is_appoint_nominee_shareholder || action.incorporationFormSG.use_zave_shareholder || 0,
                        nominee_shareholder_pricing_plan_id: action.incorporationFormSG.incorporation_form_vg_nominee_shareholder_pricing_plan_fk || action.incorporationFormSG.nominee_shareholder_pricing_plan_id,
                        share_par_value: action.incorporationFormSG.incorporation_form_vg_share_par_value || action.incorporationFormSG.share_par_value,
                        share_left: action.incorporationFormSG.incorporation_form_vg_share_left || action.incorporationFormSG.share_left,
                        share_count: action.incorporationFormSG.incorporation_form_vg_share_count || action.incorporationFormSG.share_count,
                        share_allocated: action.incorporationFormSG.share_allocated || 0,
                        payment_method: action.incorporationFormSG.incorporation_form_payment_method || action.incorporationFormSG.payment_method || Constants.PaymentMethods.DEFAULT_PAYMENT_METHOD.payment_method_id
                    };
                    if (h.notEmpty(action.incorporationFormSG.incorporation_form_vg_is_use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.incorporation_form_vg_is_use_corporate_secretary;
                    } else if (h.notEmpty(action.incorporationFormSG.use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.use_corporate_secretary;
                    } else {
                        incorporationForm.use_corporate_secretary = 1;
                    }
                    break;
                case '00':
                    incorporationForm = {
                        incorporation_form_id: action.incorporationFormSG.incorporation_form_incorporation_form_id || action.incorporationFormSG.incorporation_form_id,
                        entity_name: action.incorporationFormSG.incorporation_form_00_entity_name || action.incorporationFormSG.entity_name,
                        incorporation_pricing_plan_id: action.incorporationFormSG.incorporation_form_00_incorporation_pricing_plan_fk || action.incorporationFormSG.incorporation_pricing_plan_id,
                        // use_corporate_secretary: action.incorporationFormSG.incorporation_form_00_is_use_corporate_secretary || action.incorporationFormSG.use_corporate_secretary || 1,
                        corporate_secretary_pricing_plan_id: action.incorporationFormSG.incorporation_form_00_corporate_secretary_pricing_plan_fk || action.incorporationFormSG.corporate_secretary_pricing_plan_id,
                        description: action.incorporationFormSG.incorporation_form_00_description_of_business || action.incorporationFormSG.description,
                        industry: action.incorporationFormSG.incorporation_form_00_industry || action.incorporationFormSG.industry,
                        financial_year_end: action.incorporationFormSG.incorporation_form_00_financial_year_end || action.incorporationFormSG.financial_year_end || helpers.date.calculateDefaultFYE_SG(),
                        use_zave_address: action.incorporationFormSG.incorporation_form_00_is_use_zave_address || action.incorporationFormSG.use_zave_address || 0,
                        use_zave_address_pricing_plan_id: action.incorporationFormSG.incorporation_form_00_use_zave_address_pricing_plan_fk || action.incorporationFormSG.use_zave_address_pricing_plan_id,
                        address_suite: action.incorporationFormSG.incorporation_form_00_suite || action.incorporationFormSG.address_suite,
                        address_street: action.incorporationFormSG.incorporation_form_00_address || action.incorporationFormSG.address_street,
                        address_postal: action.incorporationFormSG.incorporation_form_00_postal_code || action.incorporationFormSG.address_postal,
                        use_zave_director: action.incorporationFormSG.incorporation_form_00_is_appoint_nominee_director || action.incorporationFormSG.use_zave_director || 0,
                        nominee_director_pricing_plan_id: action.incorporationFormSG.incorporation_form_00_nominee_director_pricing_plan_fk || action.incorporationFormSG.nominee_director_pricing_plan_id,
                        use_zave_shareholder: action.incorporationFormSG.incorporation_form_00_is_appoint_nominee_shareholder || action.incorporationFormSG.use_zave_shareholder || 0,
                        nominee_shareholder_pricing_plan_id: action.incorporationFormSG.incorporation_form_00_nominee_shareholder_pricing_plan_fk || action.incorporationFormSG.nominee_shareholder_pricing_plan_id,
                        share_par_value: action.incorporationFormSG.incorporation_form_00_share_par_value || action.incorporationFormSG.share_par_value,
                        share_left: action.incorporationFormSG.incorporation_form_00_share_left || action.incorporationFormSG.share_left,
                        share_count: action.incorporationFormSG.incorporation_form_00_share_count || action.incorporationFormSG.share_count,
                        share_allocated: action.incorporationFormSG.share_allocated || 0,
                        payment_method: action.incorporationFormSG.incorporation_form_payment_method || action.incorporationFormSG.payment_method || Constants.PaymentMethods.DEFAULT_PAYMENT_METHOD.payment_method_id
                    };
                    if (h.notEmpty(action.incorporationFormSG.incorporation_form_00_is_use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.incorporation_form_00_is_use_corporate_secretary;
                    } else if (h.notEmpty(action.incorporationFormSG.use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.use_corporate_secretary;
                    } else {
                        incorporationForm.use_corporate_secretary = 1;
                    }
                    break;
                case '01':
                    incorporationForm = {
                        incorporation_form_id: action.incorporationFormSG.incorporation_form_incorporation_form_id || action.incorporationFormSG.incorporation_form_id,
                        entity_name: action.incorporationFormSG.incorporation_form_01_entity_name || action.incorporationFormSG.entity_name,
                        incorporation_pricing_plan_id: action.incorporationFormSG.incorporation_form_01_incorporation_pricing_plan_fk || action.incorporationFormSG.incorporation_pricing_plan_id,
                        // use_corporate_secretary: action.incorporationFormSG.incorporation_form_01_is_use_corporate_secretary || action.incorporationFormSG.use_corporate_secretary || 1,
                        corporate_secretary_pricing_plan_id: action.incorporationFormSG.incorporation_form_01_corporate_secretary_pricing_plan_fk || action.incorporationFormSG.corporate_secretary_pricing_plan_id,
                        description: action.incorporationFormSG.incorporation_form_01_description_of_business || action.incorporationFormSG.description,
                        industry: action.incorporationFormSG.incorporation_form_01_industry || action.incorporationFormSG.industry,
                        financial_year_end: action.incorporationFormSG.incorporation_form_01_financial_year_end || action.incorporationFormSG.financial_year_end || helpers.date.calculateDefaultFYE_SG(),
                        use_zave_address: action.incorporationFormSG.incorporation_form_01_is_use_zave_address || action.incorporationFormSG.use_zave_address || 0,
                        use_zave_address_pricing_plan_id: action.incorporationFormSG.incorporation_form_01_use_zave_address_pricing_plan_fk || action.incorporationFormSG.use_zave_address_pricing_plan_id,
                        address_suite: action.incorporationFormSG.incorporation_form_01_suite || action.incorporationFormSG.address_suite,
                        address_street: action.incorporationFormSG.incorporation_form_01_address || action.incorporationFormSG.address_street,
                        address_postal: action.incorporationFormSG.incorporation_form_01_postal_code || action.incorporationFormSG.address_postal,
                        use_zave_director: action.incorporationFormSG.incorporation_form_01_is_appoint_nominee_director || action.incorporationFormSG.use_zave_director || 0,
                        nominee_director_pricing_plan_id: action.incorporationFormSG.incorporation_form_01_nominee_director_pricing_plan_fk || action.incorporationFormSG.nominee_director_pricing_plan_id,
                        use_zave_shareholder: action.incorporationFormSG.incorporation_form_01_is_appoint_nominee_shareholder || action.incorporationFormSG.use_zave_shareholder || 0,
                        nominee_shareholder_pricing_plan_id: action.incorporationFormSG.incorporation_form_01_nominee_shareholder_pricing_plan_fk || action.incorporationFormSG.nominee_shareholder_pricing_plan_id,
                        share_par_value: action.incorporationFormSG.incorporation_form_01_share_par_value || action.incorporationFormSG.share_par_value,
                        share_left: action.incorporationFormSG.incorporation_form_01_share_left || action.incorporationFormSG.share_left,
                        share_count: action.incorporationFormSG.incorporation_form_01_share_count || action.incorporationFormSG.share_count,
                        share_allocated: action.incorporationFormSG.share_allocated || 0,
                        payment_method: action.incorporationFormSG.incorporation_form_payment_method || action.incorporationFormSG.payment_method || Constants.PaymentMethods.DEFAULT_PAYMENT_METHOD.payment_method_id
                    };
                    if (h.notEmpty(action.incorporationFormSG.incorporation_form_01_is_use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.incorporation_form_01_is_use_corporate_secretary;
                    } else if (h.notEmpty(action.incorporationFormSG.use_corporate_secretary)) {
                        incorporationForm.use_corporate_secretary = action.incorporationFormSG.use_corporate_secretary;
                    } else {
                        incorporationForm.use_corporate_secretary = 1;
                    }
                    break;
            }

            return {
                ...state,
                incorporationFormSG: incorporationForm
            };
        case 'SET_INCORPORATION_FORM_DIRECTORS_SG':
            action.incorporationFormDirectorsSG = action.incorporationFormDirectorsSG || {};
            var nomineeDirector = {};
            var newDirectorsList = [];
            if (helpers.general.notEmpty(action.incorporationFormDirectorsSG)) {
                for (let i = 0; i < action.incorporationFormDirectorsSG.length; i++) {
                    const director = action.incorporationFormDirectorsSG[i];
                    /**=================================================================================================
                     * process normal director details
                     *================================================================================================*/
                    var newDirector = {};
                    switch (action.countryCode) {
                        default:
                        case 'SG':
                            newDirector = {
                                director_id: director.incorporation_form_director_sg_director_id || director.director_id,
                                first_name: director.incorporation_form_director_sg_first_name || director.first_name,
                                middle_name: director.incorporation_form_director_sg_middle_name || director.middle_name,
                                last_name: director.incorporation_form_director_sg_last_name || director.last_name,
                                nationality: director.incorporation_form_director_sg_nationality || director.nationality,
                                country_pretty: director.country_nice_name || director.country_pretty,
                                email: director.incorporation_form_director_sg_email || director.email,
                                contact_number: director.incorporation_form_director_sg_contact_number || director.contact_number,
                                status: director.incorporation_form_director_sg_status || director.status,
                                is_nominee: director.incorporation_form_director_sg_is_nominee || director.is_nominee
                            };
                            break;
                        case 'AU':
                            newDirector = {
                                director_id: director.incorporation_form_director_au_director_id || director.director_id,
                                first_name: director.incorporation_form_director_au_first_name || director.first_name,
                                middle_name: director.incorporation_form_director_au_middle_name || director.middle_name,
                                last_name: director.incorporation_form_director_au_last_name || director.last_name,
                                nationality: director.incorporation_form_director_au_nationality || director.nationality,
                                country_pretty: director.country_nice_name || director.country_pretty,
                                email: director.incorporation_form_director_au_email || director.email,
                                contact_number: director.incorporation_form_director_au_contact_number || director.contact_number,
                                status: director.incorporation_form_director_au_status || director.status,
                                is_nominee: director.incorporation_form_director_au_is_nominee || director.is_nominee
                            };
                            break;
                        case 'HK':
                            newDirector = {
                                director_id: director.incorporation_form_director_hk_director_id || director.director_id,
                                first_name: director.incorporation_form_director_hk_first_name || director.first_name,
                                middle_name: director.incorporation_form_director_hk_middle_name || director.middle_name,
                                last_name: director.incorporation_form_director_hk_last_name || director.last_name,
                                nationality: director.incorporation_form_director_hk_nationality || director.nationality,
                                country_pretty: director.country_nice_name || director.country_pretty,
                                email: director.incorporation_form_director_hk_email || director.email,
                                contact_number: director.incorporation_form_director_hk_contact_number || director.contact_number,
                                status: director.incorporation_form_director_hk_status || director.status,
                                is_nominee: director.incorporation_form_director_hk_is_nominee || director.is_nominee
                            };
                            break;
                        case 'VG':
                            newDirector = {
                                director_id: director.incorporation_form_director_vg_director_id || director.director_id,
                                first_name: director.incorporation_form_director_vg_first_name || director.first_name,
                                middle_name: director.incorporation_form_director_vg_middle_name || director.middle_name,
                                last_name: director.incorporation_form_director_vg_last_name || director.last_name,
                                nationality: director.incorporation_form_director_vg_nationality || director.nationality,
                                country_pretty: director.country_nice_name || director.country_pretty,
                                email: director.incorporation_form_director_vg_email || director.email,
                                contact_number: director.incorporation_form_director_vg_contact_number || director.contact_number,
                                status: director.incorporation_form_director_vg_status || director.status,
                                is_nominee: director.incorporation_form_director_vg_is_nominee || director.is_nominee
                            };
                            break;
                        case '00':
                            newDirector = {
                                director_id: director.incorporation_form_director_00_director_id || director.director_id,
                                first_name: director.incorporation_form_director_00_first_name || director.first_name,
                                middle_name: director.incorporation_form_director_00_middle_name || director.middle_name,
                                last_name: director.incorporation_form_director_00_last_name || director.last_name,
                                nationality: director.incorporation_form_director_00_nationality || director.nationality,
                                country_pretty: director.country_nice_name || director.country_pretty,
                                email: director.incorporation_form_director_00_email || director.email,
                                contact_number: director.incorporation_form_director_00_contact_number || director.contact_number,
                                status: director.incorporation_form_director_00_status || director.status,
                                is_nominee: director.incorporation_form_director_00_is_nominee || director.is_nominee
                            };
                            break;
                        case '01':
                            newDirector = {
                                director_id: director.incorporation_form_director_01_director_id || director.director_id,
                                first_name: director.incorporation_form_director_01_first_name || director.first_name,
                                middle_name: director.incorporation_form_director_01_middle_name || director.middle_name,
                                last_name: director.incorporation_form_director_01_last_name || director.last_name,
                                nationality: director.incorporation_form_director_01_nationality || director.nationality,
                                country_pretty: director.country_nice_name || director.country_pretty,
                                email: director.incorporation_form_director_01_email || director.email,
                                contact_number: director.incorporation_form_director_01_contact_number || director.contact_number,
                                status: director.incorporation_form_director_01_status || director.status,
                                is_nominee: director.incorporation_form_director_01_is_nominee || director.is_nominee
                            };
                            break;
                    }
                    newDirectorsList.push(newDirector);
                    /**=================================================================================================
                     * process nominee director details
                     *================================================================================================*/
                    if (helpers.general.cmpInt(newDirector.is_nominee, 1)) {
                        nomineeDirector = Object.assign({}, newDirector);
                    }
                }
            }
            return {
                ...state,
                incorporationFormDirectorsSG: newDirectorsList,
                nomineeDirectorSG: nomineeDirector
            };
        case 'SET_INCORPORATION_FORM_SHAREHOLDERS_SG':
            action.incorporationFormShareholdersSG = action.incorporationFormShareholdersSG || {};
            var incorporationFormSG = state.incorporationFormSG;
            var nomineeShareholder = {};
            var newShareholdersList = [];
            var individualShareholders = [];
            var corporateShareholders = [];
            var totalSharesAllocated = 0;
            if (helpers.general.notEmpty(action.incorporationFormShareholdersSG)) {
                for (let i = 0; i < action.incorporationFormShareholdersSG.length; i++) {
                    const shareholder = action.incorporationFormShareholdersSG[i];
                    /**=================================================================================================
                     * process normal shareholder details
                     *================================================================================================*/
                    // generic shareholder details
                    var newShareholder = {};
                    switch (action.countryCode) {
                        default:
                        case 'SG':
                            newShareholder = {
                                shareholder_id: shareholder.incorporation_form_shareholder_sg_shareholder_id || shareholder.shareholder_id,
                                share_count: shareholder.incorporation_form_shareholder_sg_share_count || shareholder.share_count,
                                paid_up_capital: shareholder.incorporation_form_shareholder_sg_paid_up_capital || shareholder.paid_up_capital,
                                type: shareholder.incorporation_form_shareholder_sg_type || shareholder.type,
                                status: shareholder.incorporation_form_shareholder_sg_status || shareholder.status,
                                country_pretty: shareholder.country_nice_name || shareholder.country_pretty,
                                is_nominee: shareholder.incorporation_form_shareholder_sg_is_nominee || shareholder.is_nominee
                            };
                            // individual shareholder
                            if (helpers.general.cmpStr(newShareholder.type, 'individual')) {
                                newShareholder.security_type = shareholder.incorporation_form_shareholder_individual_sg_security_type || shareholder.security_type;
                                newShareholder.first_name = shareholder.incorporation_form_shareholder_individual_sg_first_name || shareholder.first_name;
                                newShareholder.middle_name = shareholder.incorporation_form_shareholder_individual_sg_middle_name || shareholder.middle_name;
                                newShareholder.last_name = shareholder.incorporation_form_shareholder_individual_sg_last_name || shareholder.last_name;
                                newShareholder.nationality = shareholder.incorporation_form_shareholder_individual_sg_nationality || shareholder.nationality;
                                newShareholder.email = shareholder.incorporation_form_shareholder_individual_sg_email || shareholder.email;
                                newShareholder.contact_number = shareholder.incorporation_form_shareholder_individual_sg_contact_number || shareholder.contact_number;
                                individualShareholders.push(newShareholder);
                            }
                            // corporate shareholder
                            else if (helpers.general.cmpStr(newShareholder.type, 'corporate')) {
                                newShareholder.seniority = shareholder.incorporation_form_shareholder_corporate_sg_seniority || shareholder.seniority;
                                newShareholder.company_name = shareholder.incorporation_form_shareholder_corporate_sg_company_name || shareholder.company_name;
                                newShareholder.country_code = shareholder.incorporation_form_shareholder_corporate_sg_country_code || shareholder.country_code;
                                newShareholder.auth_rep_first_name = shareholder.incorporation_form_shareholder_corporate_sg_auth_rep_first_name || shareholder.auth_rep_first_name;
                                newShareholder.auth_rep_last_name = shareholder.incorporation_form_shareholder_corporate_sg_auth_rep_last_name || shareholder.auth_rep_last_name;
                                newShareholder.auth_rep_email = shareholder.incorporation_form_shareholder_corporate_sg_auth_rep_email || shareholder.auth_rep_email;
                                individualShareholders.push(corporateShareholders);
                            }
                            break;
                        case 'AU':
                            newShareholder = {
                                shareholder_id: shareholder.incorporation_form_shareholder_au_shareholder_id || shareholder.shareholder_id,
                                share_count: shareholder.incorporation_form_shareholder_au_share_count || shareholder.share_count,
                                paid_up_capital: shareholder.incorporation_form_shareholder_au_paid_up_capital || shareholder.paid_up_capital,
                                type: shareholder.incorporation_form_shareholder_au_type || shareholder.type,
                                status: shareholder.incorporation_form_shareholder_au_status || shareholder.status,
                                country_pretty: shareholder.country_nice_name || shareholder.country_pretty,
                                is_nominee: shareholder.incorporation_form_shareholder_au_is_nominee || shareholder.is_nominee
                            };
                            // individual shareholder
                            if (helpers.general.cmpStr(newShareholder.type, 'individual')) {
                                newShareholder.security_type = shareholder.incorporation_form_shareholder_individual_au_security_type || shareholder.security_type;
                                newShareholder.first_name = shareholder.incorporation_form_shareholder_individual_au_first_name || shareholder.first_name;
                                newShareholder.middle_name = shareholder.incorporation_form_shareholder_individual_au_middle_name || shareholder.middle_name;
                                newShareholder.last_name = shareholder.incorporation_form_shareholder_individual_au_last_name || shareholder.last_name;
                                newShareholder.nationality = shareholder.incorporation_form_shareholder_individual_au_nationality || shareholder.nationality;
                                newShareholder.email = shareholder.incorporation_form_shareholder_individual_au_email || shareholder.email;
                                newShareholder.contact_number = shareholder.incorporation_form_shareholder_individual_au_contact_number || shareholder.contact_number;
                                individualShareholders.push(newShareholder);
                            }
                            // corporate shareholder
                            else if (helpers.general.cmpStr(newShareholder.type, 'corporate')) {
                                newShareholder.seniority = shareholder.incorporation_form_shareholder_corporate_au_seniority || shareholder.seniority;
                                newShareholder.company_name = shareholder.incorporation_form_shareholder_corporate_au_company_name || shareholder.company_name;
                                newShareholder.country_code = shareholder.incorporation_form_shareholder_corporate_au_country_code || shareholder.country_code;
                                newShareholder.auth_rep_first_name = shareholder.incorporation_form_shareholder_corporate_au_auth_rep_first_name || shareholder.auth_rep_first_name;
                                newShareholder.auth_rep_last_name = shareholder.incorporation_form_shareholder_corporate_au_auth_rep_last_name || shareholder.auth_rep_last_name;
                                newShareholder.auth_rep_email = shareholder.incorporation_form_shareholder_corporate_au_auth_rep_email || shareholder.auth_rep_email;
                                individualShareholders.push(corporateShareholders);
                            }
                            break;
                        case 'HK':
                            newShareholder = {
                                shareholder_id: shareholder.incorporation_form_shareholder_hk_shareholder_id || shareholder.shareholder_id,
                                share_count: shareholder.incorporation_form_shareholder_hk_share_count || shareholder.share_count,
                                paid_up_capital: shareholder.incorporation_form_shareholder_hk_paid_up_capital || shareholder.paid_up_capital,
                                type: shareholder.incorporation_form_shareholder_hk_type || shareholder.type,
                                status: shareholder.incorporation_form_shareholder_hk_status || shareholder.status,
                                country_pretty: shareholder.country_nice_name || shareholder.country_pretty,
                                is_nominee: shareholder.incorporation_form_shareholder_hk_is_nominee || shareholder.is_nominee
                            };
                            // individual shareholder
                            if (helpers.general.cmpStr(newShareholder.type, 'individual')) {
                                newShareholder.security_type = shareholder.incorporation_form_shareholder_individual_hk_security_type || shareholder.security_type;
                                newShareholder.first_name = shareholder.incorporation_form_shareholder_individual_hk_first_name || shareholder.first_name;
                                newShareholder.middle_name = shareholder.incorporation_form_shareholder_individual_hk_middle_name || shareholder.middle_name;
                                newShareholder.last_name = shareholder.incorporation_form_shareholder_individual_hk_last_name || shareholder.last_name;
                                newShareholder.nationality = shareholder.incorporation_form_shareholder_individual_hk_nationality || shareholder.nationality;
                                newShareholder.email = shareholder.incorporation_form_shareholder_individual_hk_email || shareholder.email;
                                newShareholder.contact_number = shareholder.incorporation_form_shareholder_individual_hk_contact_number || shareholder.contact_number;
                                individualShareholders.push(newShareholder);
                            }
                            // corporate shareholder
                            else if (helpers.general.cmpStr(newShareholder.type, 'corporate')) {
                                newShareholder.seniority = shareholder.incorporation_form_shareholder_corporate_hk_seniority || shareholder.seniority;
                                newShareholder.company_name = shareholder.incorporation_form_shareholder_corporate_hk_company_name || shareholder.company_name;
                                newShareholder.country_code = shareholder.incorporation_form_shareholder_corporate_hk_country_code || shareholder.country_code;
                                newShareholder.auth_rep_first_name = shareholder.incorporation_form_shareholder_corporate_hk_auth_rep_first_name || shareholder.auth_rep_first_name;
                                newShareholder.auth_rep_last_name = shareholder.incorporation_form_shareholder_corporate_hk_auth_rep_last_name || shareholder.auth_rep_last_name;
                                newShareholder.auth_rep_email = shareholder.incorporation_form_shareholder_corporate_hk_auth_rep_email || shareholder.auth_rep_email;
                                individualShareholders.push(corporateShareholders);
                            }
                            break;
                        case 'VG':
                            newShareholder = {
                                shareholder_id: shareholder.incorporation_form_shareholder_vg_shareholder_id || shareholder.shareholder_id,
                                share_count: shareholder.incorporation_form_shareholder_vg_share_count || shareholder.share_count,
                                paid_up_capital: shareholder.incorporation_form_shareholder_vg_paid_up_capital || shareholder.paid_up_capital,
                                type: shareholder.incorporation_form_shareholder_vg_type || shareholder.type,
                                status: shareholder.incorporation_form_shareholder_vg_status || shareholder.status,
                                country_pretty: shareholder.country_nice_name || shareholder.country_pretty,
                                is_nominee: shareholder.incorporation_form_shareholder_vg_is_nominee || shareholder.is_nominee
                            };
                            // individual shareholder
                            if (helpers.general.cmpStr(newShareholder.type, 'individual')) {
                                newShareholder.security_type = shareholder.incorporation_form_shareholder_individual_vg_security_type || shareholder.security_type;
                                newShareholder.first_name = shareholder.incorporation_form_shareholder_individual_vg_first_name || shareholder.first_name;
                                newShareholder.middle_name = shareholder.incorporation_form_shareholder_individual_vg_middle_name || shareholder.middle_name;
                                newShareholder.last_name = shareholder.incorporation_form_shareholder_individual_vg_last_name || shareholder.last_name;
                                newShareholder.nationality = shareholder.incorporation_form_shareholder_individual_vg_nationality || shareholder.nationality;
                                newShareholder.email = shareholder.incorporation_form_shareholder_individual_vg_email || shareholder.email;
                                newShareholder.contact_number = shareholder.incorporation_form_shareholder_individual_vg_contact_number || shareholder.contact_number;
                                individualShareholders.push(newShareholder);
                            }
                            // corporate shareholder
                            else if (helpers.general.cmpStr(newShareholder.type, 'corporate')) {
                                newShareholder.seniority = shareholder.incorporation_form_shareholder_corporate_vg_seniority || shareholder.seniority;
                                newShareholder.company_name = shareholder.incorporation_form_shareholder_corporate_vg_company_name || shareholder.company_name;
                                newShareholder.country_code = shareholder.incorporation_form_shareholder_corporate_vg_country_code || shareholder.country_code;
                                newShareholder.auth_rep_first_name = shareholder.incorporation_form_shareholder_corporate_vg_auth_rep_first_name || shareholder.auth_rep_first_name;
                                newShareholder.auth_rep_last_name = shareholder.incorporation_form_shareholder_corporate_vg_auth_rep_last_name || shareholder.auth_rep_last_name;
                                newShareholder.auth_rep_email = shareholder.incorporation_form_shareholder_corporate_vg_auth_rep_email || shareholder.auth_rep_email;
                                individualShareholders.push(corporateShareholders);
                            }
                            break;
                        case '00':
                            newShareholder = {
                                shareholder_id: shareholder.incorporation_form_shareholder_00_shareholder_id || shareholder.shareholder_id,
                                share_count: shareholder.incorporation_form_shareholder_00_share_count || shareholder.share_count,
                                paid_up_capital: shareholder.incorporation_form_shareholder_00_paid_up_capital || shareholder.paid_up_capital,
                                type: shareholder.incorporation_form_shareholder_00_type || shareholder.type,
                                status: shareholder.incorporation_form_shareholder_00_status || shareholder.status,
                                country_pretty: shareholder.country_nice_name || shareholder.country_pretty,
                                is_nominee: shareholder.incorporation_form_shareholder_00_is_nominee || shareholder.is_nominee
                            };
                            // individual shareholder
                            if (helpers.general.cmpStr(newShareholder.type, 'individual')) {
                                newShareholder.security_type = shareholder.incorporation_form_shareholder_individual_00_security_type || shareholder.security_type;
                                newShareholder.first_name = shareholder.incorporation_form_shareholder_individual_00_first_name || shareholder.first_name;
                                newShareholder.middle_name = shareholder.incorporation_form_shareholder_individual_00_middle_name || shareholder.middle_name;
                                newShareholder.last_name = shareholder.incorporation_form_shareholder_individual_00_last_name || shareholder.last_name;
                                newShareholder.nationality = shareholder.incorporation_form_shareholder_individual_00_nationality || shareholder.nationality;
                                newShareholder.email = shareholder.incorporation_form_shareholder_individual_00_email || shareholder.email;
                                newShareholder.contact_number = shareholder.incorporation_form_shareholder_individual_00_contact_number || shareholder.contact_number;
                                individualShareholders.push(newShareholder);
                            }
                            // corporate shareholder
                            else if (helpers.general.cmpStr(newShareholder.type, 'corporate')) {
                                newShareholder.seniority = shareholder.incorporation_form_shareholder_corporate_00_seniority || shareholder.seniority;
                                newShareholder.company_name = shareholder.incorporation_form_shareholder_corporate_00_company_name || shareholder.company_name;
                                newShareholder.country_code = shareholder.incorporation_form_shareholder_corporate_00_country_code || shareholder.country_code;
                                newShareholder.auth_rep_first_name = shareholder.incorporation_form_shareholder_corporate_00_auth_rep_first_name || shareholder.auth_rep_first_name;
                                newShareholder.auth_rep_last_name = shareholder.incorporation_form_shareholder_corporate_00_auth_rep_last_name || shareholder.auth_rep_last_name;
                                newShareholder.auth_rep_email = shareholder.incorporation_form_shareholder_corporate_00_auth_rep_email || shareholder.auth_rep_email;
                                individualShareholders.push(corporateShareholders);
                            }
                            break;
                        case '01':
                            newShareholder = {
                                shareholder_id: shareholder.incorporation_form_shareholder_01_shareholder_id || shareholder.shareholder_id,
                                share_count: shareholder.incorporation_form_shareholder_01_share_count || shareholder.share_count,
                                paid_up_capital: shareholder.incorporation_form_shareholder_01_paid_up_capital || shareholder.paid_up_capital,
                                type: shareholder.incorporation_form_shareholder_01_type || shareholder.type,
                                status: shareholder.incorporation_form_shareholder_01_status || shareholder.status,
                                country_pretty: shareholder.country_nice_name || shareholder.country_pretty,
                                is_nominee: shareholder.incorporation_form_shareholder_01_is_nominee || shareholder.is_nominee
                            };
                            // individual shareholder
                            if (helpers.general.cmpStr(newShareholder.type, 'individual')) {
                                newShareholder.security_type = shareholder.incorporation_form_shareholder_individual_01_security_type || shareholder.security_type;
                                newShareholder.first_name = shareholder.incorporation_form_shareholder_individual_01_first_name || shareholder.first_name;
                                newShareholder.middle_name = shareholder.incorporation_form_shareholder_individual_01_middle_name || shareholder.middle_name;
                                newShareholder.last_name = shareholder.incorporation_form_shareholder_individual_01_last_name || shareholder.last_name;
                                newShareholder.nationality = shareholder.incorporation_form_shareholder_individual_01_nationality || shareholder.nationality;
                                newShareholder.email = shareholder.incorporation_form_shareholder_individual_01_email || shareholder.email;
                                newShareholder.contact_number = shareholder.incorporation_form_shareholder_individual_01_contact_number || shareholder.contact_number;
                                individualShareholders.push(newShareholder);
                            }
                            // corporate shareholder
                            else if (helpers.general.cmpStr(newShareholder.type, 'corporate')) {
                                newShareholder.seniority = shareholder.incorporation_form_shareholder_corporate_01_seniority || shareholder.seniority;
                                newShareholder.company_name = shareholder.incorporation_form_shareholder_corporate_01_company_name || shareholder.company_name;
                                newShareholder.country_code = shareholder.incorporation_form_shareholder_corporate_01_country_code || shareholder.country_code;
                                newShareholder.auth_rep_first_name = shareholder.incorporation_form_shareholder_corporate_01_auth_rep_first_name || shareholder.auth_rep_first_name;
                                newShareholder.auth_rep_last_name = shareholder.incorporation_form_shareholder_corporate_01_auth_rep_last_name || shareholder.auth_rep_last_name;
                                newShareholder.auth_rep_email = shareholder.incorporation_form_shareholder_corporate_01_auth_rep_email || shareholder.auth_rep_email;
                                individualShareholders.push(corporateShareholders);
                            }
                            break;
                    }
                    // sum total share count
                    totalSharesAllocated += newShareholder.share_count;
                    // add shareholder to array of shareholders
                    newShareholdersList.push(newShareholder);
                    /**=================================================================================================
                     * process nominee shareholder details
                     *================================================================================================*/
                    if (helpers.general.cmpInt(newShareholder.is_nominee, 1)) {
                        nomineeShareholder = Object.assign({}, newShareholder);
                    }
                }
                /**=================================================================================================
                 * update main incorporation form details
                 *================================================================================================*/
                if (helpers.general.notEmpty(incorporationFormSG)) {
                    var totalShares = helpers.general.notEmpty(incorporationFormSG.share_count) ? incorporationFormSG.share_count : config.incorporation.default_share_count;
                    incorporationFormSG.share_allocated = totalSharesAllocated;
                    incorporationFormSG.share_left = totalShares - totalSharesAllocated;
                }
            }
            return {
                ...state,
                incorporationFormSG: incorporationFormSG,
                incorporationFormShareholdersSG: newShareholdersList,
                nomineeShareholderSG: nomineeShareholder,
                individualShareholdersSG: individualShareholders,
                corporateShareholdersSG: corporateShareholders
            };
        case 'SET_INCORPORATION_FORM_USER':
            var newUser = {}
            if (helpers.general.notEmpty(action.incorporationFormUser)) {
                newUser = {
                    incorporation_form_user_id: action.incorporationFormUser.incorporation_form_user_incorporation_form_user_id || action.incorporationFormUser.incorporation_form_user_id,
                    auth_type: action.incorporationFormUser.incorporation_form_user_auth_type || action.incorporationFormUser.auth_type,
                    first_name: action.incorporationFormUser.incorporation_form_user_first_name || action.incorporationFormUser.first_name,
                    last_name: action.incorporationFormUser.incorporation_form_user_last_name || action.incorporationFormUser.last_name,
                    mobile_number: action.incorporationFormUser.incorporation_form_user_mobile_number || action.incorporationFormUser.mobile_number,
                    email: action.incorporationFormUser.incorporation_form_user_email || action.incorporationFormUser.email,
                    auth_data: action.incorporationFormUser.incorporation_form_user_google_auth_data || action.incorporationFormUser.auth_data,
                    is_google: action.incorporationFormUser.incorporation_form_user_email_is_google || action.incorporationFormUser.is_google || 0,
                    is_zave_user: action.incorporationFormUser.incorporation_form_user_is_zave_user || action.incorporationFormUser.is_zave_user || 0,
                    is_password_set: action.incorporationFormUser.incorporation_form_user_is_password_set || action.incorporationFormUser.is_password_set || 0
                };
            }
            return {
                ...state,
                incorporationFormUser: newUser
            };
        default:
            return state;
    }
}