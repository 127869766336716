import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { h, helpers } from '../../helpers';
import { black } from "material-ui/styles/colors";
import Dialog from 'material-ui/Dialog';
import FontAwesome from 'react-fontawesome';
import attachment from 'material-ui/svg-icons/file/attachment';
import { config } from '../../configs/config';

const RENDER_MODE_SHOW_THUMBNAILS = 'show_thumbnails';
const RENDER_MODE_IS_WRAPPER = 'is_wrapper';
const RENDER_MODE_DEFAULT = 'default';

const LOADING_MODE_IMMEDIATE = 'immediate';
const LOADING_MODE_LAZY = 'lazy';
export class CommonAttachmentDialog extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            // material-ui dialog
            open: false,
            attachments: []
        };
    }
    componentDidMount(){
        let self = this;
        let {attachmentUrl} = self.props;
        const loading_mode = self.getLoadingMode();
        if(loading_mode === LOADING_MODE_IMMEDIATE) {
            self.loadAttachmentUrls(); //Load on component mount if lazy load not specified
        }
        let id = self.props.id;
        if(!id && !attachmentUrl) {
            console.error(`CommonAttachmentDialog: Warning! Component attachemnt dialog should have a prop id or attachmentUrl.`);
        }
    }
    componentWillReceiveProps(newprops) {
        let self = this;
        let {attachmentUrl} = this.props;
        if(attachmentUrl) {
            let newAttachmentUrl = newprops.attachmentUrl;
            if(attachmentUrl !== newAttachmentUrl) {
                self.loadAttachmentUrls();
            }
        }
        else {
            let new_id = newprops.id;
            let old_id = this.props.id;
            if(new_id !== old_id) { //Trigger reload of attachment urls if id has changed
                const loading_mode = self.getLoadingMode();
                if(loading_mode === LOADING_MODE_IMMEDIATE) {
                    self.loadAttachmentUrls(); //Load on component mount if lazy load not specified
                }
            }
        }
    }
    render () {
        return this.getAttachmentDialog();
    }

    getAttachmentDialog(data){
        const { open, attachments } = this.state;
        //Once here, icons will surely show and dialogs will surely be presented
        return (
            <div>
                {/* Start of attachment menu */}
                {this.getAttachmentMenu()}
                {/* End of attachment menu */}
                {/* Start of show attachment dialog */}
                <Dialog
                    title={<div>
                        <FontAwesome
                            className="float-right"
                            onClick={() => {
                                this.setState({open: false});
                            }}
                            name='times-circle-o'
                            style={{color: black, marginRight: -10, marginTop: -10, cursor: 'pointer'}}
                            size='2x'/>&nbsp;
                    </div>}
                    modal={true}
                    open={open}
                    onRequestClose={() => {
                        this.setState({open: false});
                    }}
                    contentStyle={{width: '100%', height: window.innerHeight - 200, paddingTop: 0}}
                    //TODO: FIX
                    autoScrollBodyContent={true}
                    repositionOnUpdate={true}
                >
                    {this.getAttachmentPreviewListContainer(attachments)}
                </Dialog>
                {/* End of show attachment dialog */}
            </div>
        )
    }
    /**
     * Get the loading mode after render mode compatiblity checks
     */
    getLoadingMode(){
        let self = this;
        let { loading_mode } = self.props;
        const render_mode = this.getRenderMode();

        if (render_mode === RENDER_MODE_SHOW_THUMBNAILS && loading_mode === LOADING_MODE_LAZY) {
            console.log(`Warning: Thumbnail render mode is not supported with lazy loading. Defaulting to immediate loading mode.`);
            return LOADING_MODE_IMMEDIATE;
        }

        switch (loading_mode) {
            case LOADING_MODE_IMMEDIATE:
                return LOADING_MODE_IMMEDIATE;
            case LOADING_MODE_LAZY:
                return LOADING_MODE_LAZY;
            default:
                return LOADING_MODE_IMMEDIATE;
        }
    }

    getRenderMode(){
        let self = this;
        let { render_mode, showThumbnails, isWrapper } = self.props;
        // var isWrapper = h.notEmpty(this.props.isWrapper) && h.cmpBool(this.props.isWrapper, true) ? true : false;
        if (isWrapper) {
            return RENDER_MODE_IS_WRAPPER;
        }

        if (showThumbnails) {
            return RENDER_MODE_SHOW_THUMBNAILS;
        }
        switch (render_mode) {
            case RENDER_MODE_IS_WRAPPER:
                return RENDER_MODE_IS_WRAPPER;
            case RENDER_MODE_SHOW_THUMBNAILS:
                return RENDER_MODE_SHOW_THUMBNAILS;
            default:
                return RENDER_MODE_DEFAULT;
        }

    }

    presentDialog(){
        let AUTO_DOWNLOAD_TYPES = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv'];
        let self = this;
        let {onDialogOpen} = this.props;
        (async() => {
            await self.loadAttachmentUrls();
            let { attachments } = self.state;
            let downloads = attachments.map(attachment => {
                let { url } = attachment;
                return {
                    ext: h.general.getFileExtFromUrl(url),
                    filename: h.general.getFileNameFromUrl(url),
                    url
                };
            })
            .filter(attachment => {
                return AUTO_DOWNLOAD_TYPES.includes(attachment.ext);
            });
            for(let download of downloads) {
                h.general.downloadFile(download.url);
            }
        })();

        if(typeof onDialogOpen === 'function'){
            onDialogOpen(this);
        }
        this.setState({
            open: true
        });
    }

    getAttachmentMenu(){
        let self = this;
        let render_mode = this.getRenderMode();
        let { attachments } = self.state;

        //Preserve old spec for wrapper flag
        if(render_mode === RENDER_MODE_IS_WRAPPER){
            return (
                <div onClick={() => {
                    self.presentDialog();
                }}>
                    {this.props.children}
                </div>
            )
        }
        //Enable new spec for showing thunbnails
        else if(render_mode === RENDER_MODE_SHOW_THUMBNAILS){
            //Prepare variables
            let thumbnails = [];
            attachments = attachments ? attachments : [];
            let scale = 0.25;
            let dimensions = {
                width: scale * 210 * 0.92,
                height: scale * 297 * 0.76
            };

            //Generate thumbnails
            attachments.forEach((item, idx) => {
                let attachment_url = item.url;
                var fileType = helpers.general.getFileExtFromUrl(attachment_url);
                let thumbnail = null;
                let thumbnail_style = {height: dimensions.height, width: dimensions.width};
                switch (fileType) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'bmp':
                        thumbnail = (
                            <embed src={item.url} style={{...thumbnail_style, borderRadius: 3, display: 'inline-block'}}/>
                        );
                        break;
                    case 'pdf':
                        thumbnail = (
                            <FontAwesome
                                // className="float-right"
                                name='file-pdf-o'
                                size='2x'/>
                        )
                        break;
                    default:
                        thumbnail = (
                            <FontAwesome
                                name='file-o'
                                size='2x'
                            />
                        );
                        break;
                }
                let wrapper = (
                    <div
                        key={idx}
                        // style={thumbnail_style}
                        onClick={() => {
                            self.presentDialog();
                        }}>
                        {thumbnail}
                    </div>
                )

                //Push into generated elements array
                thumbnails.push(wrapper);
            })

            //Format the thumbnails layout
            let className = "";
            if(thumbnails.length > 1){
                className = "row";
            }

            //Return the rendered output
            return (
                <div>
                    {thumbnails}
                </div>
            );
        }
        //Default rendering scheme
        else {
            var loading_mode = self.getLoadingMode();
            if(loading_mode == LOADING_MODE_IMMEDIATE
                && Array.isArray(attachments)
                && attachments.length === 0) { //If we are able to determine that there is no file, hide the icon
                return(<div></div>)
            }
            else {
                //If we are not showing previews and it is not a wrapper (no child divs), then we render the default font awesome icon
                return (
                    /* Start attachment open dialog button */
                    <FontAwesome
                        name='file-o'
                        size='2x'
                        onClick={() => {
                            self.presentDialog();
                        }}
                    />
                    /* End attachment open dialog button */
                )
            }
        }
    }
    getAttachmentLoader(){
        let self = this;
        const { attachmentUrl, attachmentUrlLoader } = self.props;
        if(attachmentUrl) {
            let attachment = {
                url: attachmentUrl,
                name: ''
            };
            return (cb) => { cb([attachment]); };
        }
        else if (typeof attachmentUrlLoader === 'function') {
            return attachmentUrlLoader;
        }
        else {
            console.error(`Warning: No attachment loader was generated.`,'AttachmentURL:', attachmentUrl);
            return (cb) => {cb([]);};
        }
    }
    loadAttachmentUrls(){
        let self = this;
        const { loading } = self.state;
        return new Promise((resolve, reject) => {
            if(!loading){
                self.setState({
                    loading: true, //Loading has started
                    attachments: [] //Reset the attachment list during the load, in case the component is recycled
                }, function() {
                    //Start loading after the state has been set
                    let attachmentLoader = self.getAttachmentLoader();
                    //Actuall fetch the attachments and update the state
                    attachmentLoader(attachments => {
                        self.setState({
                            attachments: attachments,
                            loading: false //Loading has completed
                        });
                        resolve();
                    });
                });
            }
        });
    }
    /**
     * @param {Array} attachmentUrls
     */
    getAttachmentPreviewListContainer(attachmentUrls){
        if(!attachmentUrls){
            return (
                <div></div>
            )
        }
        var inner_list = [];
        attachmentUrls.forEach((attachment, index) => {
            var url = attachment.url ? attachment.url : "";
            var name = attachment.name ? attachment.name : "";
            var fileType = helpers.general.getFileExtFromUrl(url);
            var showPreview = false;
            switch (fileType) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'bmp':
                case 'pdf':
                    showPreview = true;
                    break;
                default:
                    showPreview = false;
            }
            var item = this.getAttachmentPreviewContainer(index, name, url, showPreview, fileType);
            inner_list.push(item);
        })
        return (
            <div>
                {inner_list}
            </div>
        )
    }
    getAttachmentPreviewContainer(key, name, attachmentUrl, showPreview, fileType){
        return (
            <div className="container"
                 key={key}>
                <div className="row pt-3">
                    <b className="col-9">{name}</b>
                    <a rel="noopener noreferrer"
                       target="_blank"
                       className="text-right col-3"
                       onClick={() => {
                           h.general.downloadFile(attachmentUrl)
                       }}
                       style={{color: '#2196F3', fontWeight: 500, cursor: 'pointer'}}
                    >
                        Download File </a>
                </div>

                {h.cmpBool(showPreview, true) &&
                <embed src={attachmentUrl} style={{width: '100%', height: fileType == 'pdf' ? (window.innerHeight - 200) : 'auto'}} />}
            </div>
        )
    }
}

CommonAttachmentDialog.propTypes = {
    attachmentUrl: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.string.isRequired
    ]),
    isWrapper: PropTypes.bool
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CommonAttachmentDialog);
