import { h } from '../helpers/index';

export default function task (state = {}, action) {
    switch (action.type) {
        case 'SET_TASKS':
            let stateData = {...state};
            if (action.taskStatus) {
                switch (action.taskStatus) {
                    case 'pending_client':
                        stateData.tasksPendingClient = action.tasks;
                        break;
                    case 'pending_staff':
                        stateData.tasksPendingStaff = action.tasks;
                        break;
                    case 'completed':
                        stateData.tasksDone = action.tasks;
                        break;
                }
            } else {
                stateData.tasks = action.tasks;
            }
            return stateData;
        case 'SET_TASK_MESSAGES':
            var taskMessages = h.notEmpty(action) && h.notEmpty(action.taskMessages) ? action.taskMessages : [];
            for (var i = 0; i < taskMessages.length; i++) {
                var taskMessage = taskMessages[i];
                if (h.notEmpty(taskMessage) && h.notEmpty(taskMessage.attachments)) {
                    for (var j = 0; j < taskMessage.attachments.length; j++) {
                        var taskMessageAttachment = taskMessage.attachments[j];
                        taskMessageAttachment = setTaskMessageAttachment(taskMessageAttachment);
                    }
                }
            }
            return {
                ...state,
                taskMessages: taskMessages
            };
        case 'SET_LAST_CREATED_TASK_ID':
            return {
                ...state,
                lastCreatedTaskId: action.taskId
            };
        default:
            return state;
    }
}

/**
 * set task message attachment information
 * @param returnData
 * @returns {*}
 */
function setTaskMessageAttachment (returnData) {
    returnData = returnData || {};
    returnData.attachment_id = returnData.task_message_attachment_attachment_id || returnData.attachment_id || '';
    delete returnData.task_message_attachment_attachment_id;
    returnData.message_fk = returnData.task_message_attachment_message_fk || returnData.message_fk || '';
    delete returnData.task_message_attachment_message_fk;
    returnData.file_name = returnData.task_message_attachment_file_name || returnData.file_name || '';
    delete returnData.task_message_attachment_file_name;
    returnData.file_url = returnData.task_message_attachment_file_url || returnData.file_url || '';
    delete returnData.task_message_attachment_file_url;
    returnData.created_by = returnData.task_message_attachment_created_by || returnData.created_by || '';
    delete returnData.task_message_attachment_created_by;
    returnData.created_date = returnData.task_message_attachment_created_date || returnData.created_date || '';
    delete returnData.task_message_attachment_created_date;
    returnData.updated_by = returnData.task_message_attachment_updated_by || returnData.updated_by || '';
    delete returnData.task_message_attachment_updated_by;
    returnData.updated_date = returnData.task_message_attachment_updated_date || returnData.updated_date || '';
    delete returnData.task_message_attachment_updated_date;
    return returnData;
}