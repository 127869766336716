import $ from 'jquery';
import { config } from '../configs/config';
import Axios from 'axios';

/**
 * gets timeline
 * @param data
 * @param callback
 */

export function getTimeline(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl +  '/v1.0.0/timeline/entity/get-timeline',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getTimelineAttachments(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/timeline/entity/get-event-attachments',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function postTimelineAction(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/timeline/entity/log-event-action',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getEventAttachments(data, callback) {
    Axios.get(config.apiUrl + `/v1.0.0/timeline/entity/get-event-attachments?event_id=${data.event_id}&event_ref=${data.event_ref}`).then(
        (response) => {
            let data = response.data;
            let success = response.status < 400 ? 'success' : 'failed';
            callback(data, success);
        }
    ).catch((err) => {
        callback(err, 'failed');
    });
}
