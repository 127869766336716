import { getEnv } from '../helpers/general.js';

export const config = {

    "development": {
        "env": "development",
        "appName": "Zave",
        "webUrl": "http://accounts-ninja-web:1337",
        "apiUrl": "https://localhost:3001",
        "apiAnalytics": {"url": "https://localhost:3003", "logData": false},
        "webappUrl": "https://localhost:4001",
        "webappSpUrl": "https://localhost:6001",
        "cdnUrl": ["https://cdn.nerdyhawk.com"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LepfDAUAAAAACLoKdB4lClIVJrdnlvClPDmHG4m"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-ftcn543q73spsmkg5hobi1bkkdcrj4pg.apps.googleusercontent.com",
            "clientSecret": "dfMTAa5ny6CoIjwEch7eC0Gk",
            "callbackUrl": "https://app.getzave.com/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_test_SBN1NdyyrMdso3uHFXI9liAd00OwsTh5in"
        },
        "incorporation": {
            "default_share_count": 100,
            "default_share_par_value": 1
        },
        "termsOfServiceUrl": "https://www.getzave.com/terms-of-service/",
        "privacyPolicyUrl": "https://www.getzave.com/privacy-policy/"
    },

    "staging": {
        "env": "staging",
        "appName": "Zave",
        "webUrl": "https://nerdyhawk.com",
        "apiUrl": "https://api.nerdyhawk.com",
        "apiAnalytics": {"url": "https://api-analytics.nerdyhawk.com", "logData": true},
        "webappUrl": "https://app.nerdyhawk.com",
        "webappSpUrl": "https://partner.nerdyhawk.com",
        "cdnUrl": ["https://cdn.nerdyhawk.com"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LepfDAUAAAAACLoKdB4lClIVJrdnlvClPDmHG4m"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-t0r6lgt5c1ddrk1uoffb188i52307s5n.apps.googleusercontent.com",
            "clientSecret": "4HSYbJbWZS8aHwiLSovkXLVz",
            "callbackUrl": "https://app.nerdyhawk.com/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_test_SBN1NdyyrMdso3uHFXI9liAd00OwsTh5in"
        },
        "incorporation": {
            "default_share_count": 100,
            "default_share_par_value": 1
        },
        "termsOfServiceUrl": "https://www.getzave.com/terms-of-service/",
        "privacyPolicyUrl": "https://www.getzave.com/privacy-policy/"
    },

    "qa": {
        "env": "qa",
        "appName": "Zave",
        "webUrl": "https://primaryone.net",
        "apiUrl": "https://api.primaryone.net",
        "apiAnalytics": {"url": "https://api-analytics.primaryone.net", "logData": true},
        "webappUrl": "https://app.primaryone.net",
        "webappSpUrl": "https://partner.primaryone.net",
        "cdnUrl": ["https://cdn.primaryone.net"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LepfDAUAAAAACLoKdB4lClIVJrdnlvClPDmHG4m"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-rlstlqiahjfqqp6eduovsva9rjo9o7br.apps.googleusercontent.com",
            "clientSecret": "9KPmFYfgllSM4PQwxWRbUO8z",
            "callbackUrl": "https://app.primaryone.net/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_test_SBN1NdyyrMdso3uHFXI9liAd00OwsTh5in"
        },
        "incorporation": {
            "default_share_count": 100,
            "default_share_par_value": 1
        },
        "termsOfServiceUrl": "https://www.getzave.com/terms-of-service/",
        "privacyPolicyUrl": "https://www.getzave.com/privacy-policy/"
    },

    "production": {
        "env": "production",
        "appName": "Zave",
        "webUrl": "https://www.getzave.com",
        "apiUrl": "https://api.getzave.com",
        "apiAnalytics": {"url": "https://api-analytics.getzave.com", "logData": true},
        "webappUrl": "https://app.getzave.com",
        "webappAdminUrl": "https://staff.getzave.com",
        "webappSpUrl": "https://partner.getzave.com",
        "cdnUrl": ["https://cdn.getzave.com"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LcdFTIUAAAAACGh4A6MyoDwJYRnQBLSBIxbTa-4"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-f655u9dh95jamvtpt4ce4v91pa5pap89.apps.googleusercontent.com",
            "clientSecret": "uYjfwCitf1LuGgB9meTiwomy",
            "callbackUrl": "https://app.getzave.com/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_live_VA7NY6tOpbOVt7wSIJhvkaM100nXRDi1vs"
        },
        "incorporation": {
            "default_share_count": 100,
            "default_share_par_value": 1
        },
        "termsOfServiceUrl": "https://www.getzave.com/terms-of-service/",
        "privacyPolicyUrl": "https://www.getzave.com/privacy-policy/"
    }

}[getEnv()];
