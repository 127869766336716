module.exports = {
    ENTITY: {
        DIRECTOR_APPOINTED: 'director_appointed',
        DIRECTOR_RESIGNED: 'director_resigned',
        DIRECTOR_UPDATED: 'director_updated',

        //For interop with old CRUD director system
        LEGACY_CREATE_DIRECTOR: 'create',
        LEGACY_UPDATE_DIRECTOR: 'update',
        LEGACY_DELETE_DIRECTOR: 'delete',

        SHARE: {
            //Transactions  
            ALLOCATED: 'allocate',
            UNALLOCATED: 'unallocate',
            TRANSFER: 'transfer',
            INITIAL_ALLOTMENT: 'initital_allotment',
            //Snapshots - events used to clear / edit the state directly
            IMPORT_SNAPSHOT: 'import_snapshot',
            IMPORT_INITIAL_ALLOTMENT: 'import_initial_allotment',
            //Deprecated
            TRANSFERRED_IN: 'transfer_in',
            TRANSFERRED_OUT: 'transfer_out',
        },

        //For interop with old CRUD director system
        LEGACY_CREATE_SHAREHOLDER: 'create',
        LEGACY_UPDATE_SHAREHOLDER: 'update',
        LEGACY_DELETE_SHAREHOLDER: 'delete',
    }
};