
export default function task (state = {}, action) {
    switch (action.type) {
        case 'GET_ENTITY_USERS_AND_ENTITY_USER_GROUPS':
            return {
                ...state,
                entityUsersAndEntityUserGroups: action.entityUsersAndEntityUserGroups
            };
        case 'GET_SERVICE_PROVIDER_USERS_AND_SERVICE_PROVIDER_USER_GROUPS':
            return {
                ...state,
                serviceProviderUsersAndServiceProviderUserGroups: action.serviceProviderUsersAndServiceProviderUserGroups
            };
        default:
            return state;
    }
}
