import $ from 'jquery';
import { config } from '../configs/config';


/**
 * get task-concierge session id
 * @param data
 * @param callback
 */
export function getTaskConciergeSessionId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/taskconcierge/start-task-concierge-session',
        dataType: "json",
        data: {...data, is_silent_request: 1},
        error: callback
    }).done(callback);
}


/**
 * stop task-concierge session id
 * @param data
 * @param callback
 */
export function stopTaskConciergeSessionId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/taskconcierge/stop-task-concierge-session',
        dataType: "json",
        data: {...data, is_silent_request: 1},
        error: callback
    }).done(callback);
}

/**
 * send task-concierge data to analytics
 * @param data
 * @param callback
 */
export function sendTaskConciergeDataForAnalytics (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/taskconcierge/send-task-concierge-data-for-analytics',
        dataType: "json",
        data: {...data, is_silent_request: 1},
        error: callback
    }).done(callback);
}
        

/**
 * get suggestions
 * @param data
 * @param callback
 */
export function getSuggestions (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/taskconcierge/get-suggestions',
        dataType: "json",
        data: {...data, is_silent_request: 1},
        error: callback
    }).done(callback);
}


/**
 * get task concierge statuses
 * @param data
 * @param callback
 */
export function getTaskConciergeStatuses (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/taskconcierge/get-task-concierge-statuses',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create task and taskconceirge by corporate action
 * @param data
 * @param callback
 */
export function createTaskTaskConciergeByCorporateAction (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/taskconcierge/create-task-taskconcierge-by-corporate-action',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
