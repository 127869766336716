/*======================================================================================================================
 * import main libraries
 *====================================================================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { general, api, validate, form } from '../../../helpers/index';
import CardText from 'material-ui/Card/CardText';
import Card from 'material-ui/Card/Card';
import * as BankTransactionActions from '../../../actions/bankTransaction';
import * as TaskActions from '../../../actions/task';
import { black } from "material-ui/styles/colors";
import Dialog from 'material-ui/Dialog';
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';
import FontAwesome from 'react-fontawesome';
import PartialTaskChatList from '../Task/PartialTaskChatList';
import PartialTaskAddDialog from '../Task/PartialTaskAddDialog';
import * as TaskConciergeModel from '../../../models/taskconcierge';
import $ from 'jquery';
import { toArray } from 'react-emoji-render';
import ReactHtmlParser from 'react-html-parser';
import { helpers, h } from '../../../helpers';
import CircularProgress from 'material-ui/CircularProgress';
import CommonStepNavigator from '../../Common/CommonStepNavigator';
import ZaveCommon from 'zave_common';
import SanitizeHtml from 'sanitize-html';

const emptyStateStyle = {
    maxWidth: 150
};
/*======================================================================================================================
 * define react component
 *====================================================================================================================*/
export class PartialTaskList extends React.Component {

    constructor () {
        super();
        var additionalFields = {
            // material-ui dialog
            open: false,
            // dialog selected bank transaction
            selectedTask: [{}],
            selectedMessageType: '',
            taskConciergeStatuses: [],
            requestTaskConciergeStatus: true,
            currentTaskConciergeStatusId: ''
        };
        this.state = general.initState(additionalFields);
    }

    componentDidMount () {
        setInterval(function () {
            $('.status-light').fadeIn(1000).delay(1000).fadeOut(1500).delay(1000).fadeIn(1500);
        }, 1000);

        const taskId = h.general.getGetParam(this, 'taskId');
        if (h.notEmpty(this.props.tasksDone) || h.notEmpty(this.props.tasksPendingStaff) || h.notEmpty(this.props.tasksPendingClient)) {
            this.onDialogOpen(taskId);
        }
    }

    renderTaskConciergeStatuses() {
        if(this.state.taskConciergeStatuses.length>0) {
            return (
                <div style={{ marginTop: 20 }}>
                    <ul className='row' style={{ listStyleType: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/*this.state.taskConciergeStatuses.map((eachItem) => <TaskConciergeStatusComponent description={eachItem.description} isChecked={eachItem.isCurrentState} />)*/}
                        {
                            <CommonStepNavigator
                                stages={this.state.taskConciergeStatuses.map((eachItem) => {
                                    return {
                                        ...eachItem
                                    };
                                })}
                                current_stage_id={this.state.currentTaskConciergeStatusId}
                            />
                        }
                    </ul>
                </div>
            );
        } else {
            return (
                <div style={{ fontSize: 14 }}></div>
            );
        }

    }

    /**
     * handles dialog open event
     * @param taskId
     */
    onDialogOpen (taskId) {
        const self = this;
        const { taskStatus, constants } = this.props;
        let tasks = null;
        switch (taskStatus) {
            case constants.TASK_STATUS.PENDING_CLIENT:
                tasks = this.props.tasksPendingClient;
                break;
            case constants.TASK_STATUS.PENDING_STAFF:
                tasks = this.props.tasksPendingStaff;
                break;
            case constants.TASK_STATUS.DONE:
                tasks = this.props.tasksDone;
                break;
        }
        var tasksData = [];
        if (!general.isEmpty(tasks)) {
            for (var i = 0; i < tasks.length; i++) {
                var task = tasks[i];
                if (general.cmpStr(task.task_status, taskStatus)) {
                    tasksData.push(task);
                }
            }
        }
        var selectedTask = [];
        for (var i = 0; i < tasksData.length; i++) {
            var task = tasksData[i];
            if (general.cmpStr(taskId, task.task_task_id)) {
                self.props.history.replace('/tasks');
                selectedTask.push(task);
            }
        }

        this.setState({open: true, selectedTask: selectedTask, requestTaskConciergeStatus: true });

        //!Note {Investigate further on behavior }
        // //Fetch statuses from API for Task Concierge
        // console.log('Dialog open >>>>');
        //this.getStatuses(taskId);
    }

    getStatuses(taskId) {
        const { userPermissions } = this.props;
        let ZaveCommonConstant = ZaveCommon.config.constant;
        console.log(this.props.userPermissions);
        if(!h.general.isEmpty(taskId) && (
            userPermissions &&
            userPermissions[ZaveCommonConstant.MODULES.TASK_CONCIERGE] &&
            h.cmpBool(userPermissions[ZaveCommonConstant.MODULES.TASK_CONCIERGE][ZaveCommonConstant.PERMISSIONS.VIEW], true)
        )) {
            TaskConciergeModel.getTaskConciergeStatuses({ 'task_fk': taskId, is_silent_request: 1, 'entity_fk': this.props.currentEntity.entity_id },(data) => {
                var apiRes = helpers.api.handleApiResponse(data, false);
                let current_stage_id = '';
                for (var i = 0; i < apiRes.data.length; i++) {
                    if(apiRes.data[i].isCurrentState) {
                        current_stage_id = apiRes.data[i].id
                    }
                }
                this.setState({ requestTaskConciergeStatus: false, taskConciergeStatuses: apiRes.data, currentTaskConciergeStatusId: current_stage_id });
            });
        }
    }

    /**
     * handles dialog close event
     */
    onDialogClose () {
        var self = this;
        this.setState({open: false, taskConciergeStatuses: [], selectedTask: [{}]}, function () {
            self.props.reloadTasks();
        });
    };

    render () {
        const self = this;
        const { theme, constants, taskStatus } = this.props;
        const { selectedTask } = this.state;
        let tasks = null;
        let taskEmptyStateImg = null;
        let taskEmptyStateText = null;
        switch (taskStatus) {
            case constants.TASK_STATUS.PENDING_CLIENT:
                tasks = this.props.tasksPendingClient;
                taskEmptyStateImg = 'https://cdn.getzave.com/assets/task/well_done_v3.png';
                taskEmptyStateText = <span style={{fontFamily: theme.fontFamily}}>You're all caught up!</span>;
                break;
            case constants.TASK_STATUS.PENDING_STAFF:
                tasks = this.props.tasksPendingStaff;
                taskEmptyStateImg = 'https://cdn.getzave.com/assets/task/new_tasks_v3.png';
                taskEmptyStateText = <span style={{fontFamily: theme.fontFamily}}>Our hands are free!<br/>Hit the plus sign below if there are any Tasks we can help you with.</span>;
                break;
            case constants.TASK_STATUS.DONE:
                tasks = this.props.tasksDone;
                taskEmptyStateImg = 'https://cdn.getzave.com/assets/task/tasks_done_v3.png';
                taskEmptyStateText = <span style={{fontFamily: theme.fontFamily}}>You've yet to complete a Task, but I'm sure you will soon!</span>;
                break;
        }
        var tasksData = [];
        if (!general.isEmpty(tasks)) {
            for (var i = 0; i < tasks.length; i++) {
                var task = tasks[i];
                if (general.cmpStr(taskStatus, task.task_status)) {
                    tasksData.push(task);
                }
            }
        }

        return tasksData && tasksData.length > 0 ? (
            <div style={{padding: 8, borderRadius: 2}}>
                {tasksData && tasksData.length > 0 && tasksData.map(function (task, index) {
                    var taskId = task.task_task_id;
                    var message = task.message && task.message.task_message_message ? task.message.task_message_message : '';
                    var statusColor = '';
                    message = toArray(general.nl2br(h.general.fixEmoji(message)));
                    var formattedMessage = [];
                    for (var i = 0; i < message.length; i++) {
                        if (general.isEmpty(message[i].props)) {
                            // formattedMessage.push(ReactHtmlParser(message[i]));
                            formattedMessage.push(ReactHtmlParser(SanitizeHtml(message[i], {
                                allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br'],
                                allowedAttributes: { 'a': ['href'] }
                            })));
                        } else {
                            message[i].props.style.fontSize = 22;
                            formattedMessage.push(message[i]);
                        }
                    }
                    formattedMessage = general.truncateArray(formattedMessage, 100);
                    var messageTimeAgo = task.message && task.message.time_ago ? task.message.time_ago : '';
                    switch (task.task_status) {
                        case constants.TASK_STATUS.PENDING_STAFF:
                            statusColor = theme.palette.warningColor;
                            break;
                        case constants.TASK_STATUS.PENDING_CLIENT:
                            statusColor = theme.palette.successColor;
                            break;
                        case constants.TASK_STATUS.DONE:
                            statusColor = theme.palette.dangerColor;
                            break;
                    }
                    var entityName = task.message && task.message.task_message_user_from ? task.message.task_message_user_from : '';
                    var userProfilePic = '';
                    var userName = '';
                    if (task.message) {
                        if (task.message.first_name) {
                            userName = task.message.first_name;
                        }
                        if (task.message.last_name) {
                            userName += helpers.general.notEmpty(userName) ? ' ' + task.message.last_name : task.message.last_name;
                            // userName = 'hello world hello world hello world';
                        }
                        if (task.message.profile_picture) {
                            userProfilePic = task.message.profile_picture;
                        }
                    }
                    return <Card className={"verification-chat text-left " + (tasksData.length - 1 === index ? '' : ' mbs')}
                                 style={{cursor: 'pointer', borderRadius: 4}}
                                 key={taskId}
                                 containerStyle={{paddingBottom: '0px !important'}}
                                 onClick={self.onDialogOpen.bind(self, taskId)}>
                        <CardText className="verification-chat">
                            <div className="mbs"  style={{whiteSpace: 'nowrap', overflow: 'hidden', wordBreak: 'break-word'}}><b>{task.task_subject}</b></div>
                            <div className="verification-text" style={{whiteSpace: 'nowrap', overflow: 'hidden', wordBreak: 'break-word'}}>{formattedMessage}</div><br/>
                            <small className="float-right text-muted pb-3">{messageTimeAgo}</small><br/>
                        </CardText>
                    </Card>
                })}

                {h.notEmpty(this.state.selectedTask) &&
                <Dialog
                    title={
                        <div>Task: {this.state.selectedTask[0].task_subject}
                            <FontAwesome className="float-right"
                                onClick={this.onDialogClose.bind(this)}
                                name='times-circle-o'
                                style={{color: black, marginRight: -20, marginTop: -20, cursor: 'pointer'}}
                                size='2x'
                            />&nbsp;
                            {this.renderTaskConciergeStatuses()}
                        </div>
                    }
                    contentClassName="dialog-container-chat"
                    style={{wordBreak: 'break-word'}}
                    modal={true}
                    open={this.state.open}
                    onRequestClose={this.onDialogClose.bind(this)}
                    autoScrollBodyContent={true}
                    repositionOnUpdate={true}
                >

                    <div>
                        {selectedTask.map((task) => {
                            var status = task.task_status;
                            var key = 'task-' + task.task_task_id;
                            return (<div key={key}>
                                <PartialTaskChatList
                                    task={this.state.selectedTask && this.state.selectedTask[0] ? this.state.selectedTask[0] : {}}
                                    closeDialog={this.onDialogClose.bind(this)}
                                    status={status}
                                    taskId={task.task_task_id}
                                    refreshStatuses={() => this.getStatuses(task.task_task_id)}
                                />

                            </div>);
                        })}
                    </div>
                </Dialog>}
            </div>
        )
        :
        <div className="p-5">
            <img className="img-fluid" style={emptyStateStyle} src={taskEmptyStateImg}/><br/>
            <span>{taskEmptyStateText}</span>
        </div>;
    }
}

PartialTaskList.propTypes = {
    currentUser: PropTypes.object.isRequired,
    currentCompany: PropTypes.object.isRequired,
    currentEntity: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    userPermissions: PropTypes.object.isRequired,
    tasks: PropTypes.array,
    taskStatus: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.user,
    currentCompany: state.company.company,
    currentEntity: state.company.currentEntity,
    theme: state.config.theme,
    constants: state.constants,
    userPermissions: state.permission,
    tasks: state.task.tasks,
    lastCreatedTaskId: state.task.lastCreatedTaskId,
    // tasksPendingClient: state.task.tasksPendingClient,
    // tasksPendingStaff: state.task.tasksPendingStaff,
    // tasksDone: state.task.tasksDone
});

const mapDispatchToProps = {
    setVerifications: BankTransactionActions.setVerifications
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartialTaskList));
