import React from 'react';
// https://github.com/fkhadra/react-toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { default as swal } from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {general} from "./index";
import { config } from '../configs/config';
import { config as ZaveCommonConfig } from "zave_common";

String.prototype.replaceAll = function(searchStr, replaceStr) {
    var str = this;

    // escape regexp special characters in search string
    searchStr = searchStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    return str.replace(new RegExp(searchStr, 'gi'), replaceStr);
};

if (!Object.values) {
    /**
     * Polyfill for object.values to support IE11
     * @param {object} obj
     * @returns {[]}
     */
    Object.values = function (obj) {
        if (!obj) return [];
        if (typeof obj !== 'object') return [];
        return Object.keys(obj).map(key => {
            return obj[key];
        });
    };
}

if (!Object.entries) {
    /**
     * Polyfill for Object.entries to support IE11
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
     * @param {object} obj
     * @returns {[]}
     */
    Object.entries = function( obj ){
        if (!obj) return [];
        if (typeof obj !== 'object') return [];
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
    };
}

/**
 * get current environment based on hostname
 * @returns {*}
 */
export function getEnv () {
    var debug = false,
        hostName = window.location.hostname,
        processEnvironment = function (debug, environment) {
            if (debug) console.log('Current environment: ' + environment);
            return environment;
        };
    // development environment
    if (hostName.indexOf('localhost') > -1) {
        return processEnvironment(debug, 'development');
    }
    // staging environment
    else if (hostName.indexOf('nerdyhawk.com') > -1) {
        return processEnvironment(debug, 'staging');
    }
    // qa environment
    else if (hostName.indexOf('primaryone.net') > -1) {
        return processEnvironment(debug, 'qa');
    }
    // production environment
    else {
        return processEnvironment(debug, 'production');
    }
}

/**
 * checks if environment is development
 * @returns {boolean}
 */
export function isDev () {
    if (compareString(getEnv(), 'development')) {
        return true;
    } else {
        return false;
    }
}

/**
 * gets all url GET parameters
 * @param url
 * @returns {string}
 */
export function findGetParameter (parameterName) {
    var result = null,
        tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

/**
 * initialize state for standard structure across all react elements
 *
 * [SAMPLE additionalStates data]
 * additionalStates: {
 *      fields: [
 *          {
 *              value: 'default value of field',
 *              dirty: 'boolean (true/false) indicator to indicate by default if field is edited or not'
 *              // this is usually used for fields that will not have user interactions on it
 *              // form submission validation will checked if fields have been edited or not using this dirty flag
 *          }
 *          ...
 *      },
 *      ...
 * }
 * @template T
 * @param {T} additionalStates
 * @returns {{ loading: boolean, fields: Object<string, { value: any, error: string, dirty: boolean, changed: boolean}> } & T}
 */
export function initState (additionalStates) {
    // add any states that you want to have across all components here
    var initializedState = {
        loading: false
    };
    if (additionalStates) {
        for (var additionalState in additionalStates) {
            initializedState[additionalState] = additionalStates[additionalState];
            if (additionalState === 'fields') {
                for (var field in initializedState[additionalState]) {
                    initializedState[additionalState][field] = {
                        // store actual value of field
                        value: initializedState[additionalState][field].value || '',
                        // store error message of field
                        error: '',
                        // flag to indicate whether field has been edited before
                        dirty: initializedState[additionalState][field].dirty || false,
                        // flag to indicate whether value has changed compared to previous value
                        changed: false
                    };
                }
            }
        }
    }
    return initializedState;
}

/**
 * generic alert
 * @param message
 * @param type
 */
let user_current_alert = null;
export function alert (message, type) {
    if(!user_current_alert) {
        setTimeout(() => { //Reset after 5 seconds
            user_current_alert = null;
        }, 5000);
        type = type || 'success';
        toast.success(message, {
            type: type,
            className: 'toast-' + type
        });
        user_current_alert = {message, type};
    }
}

/**
 * generic prompt using Sweetalert2
 * @param data
 * @param callback
 */
export function prompt (data, callback) {
    if (data && data.message) {
        swal({
            title: data.title || 'Zave',
            text: data.message,
            type: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#0091F4",
            confirmButtonText: "Yes",
            cancelButtonColor: "#344fb2",
            cancelButtonText: "No"
        }).then(function () {
            callback(true);
        }, function () {
            callback(false);
        });
    }
}

/**
 * capitalize first letter of word
 * @param str
 * @returns {string}
 */
export function ucFirst (str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

/**
 * capitalize all first letters of sentence
 * @param str
 * @returns {string}
 */
export function ucFirstAllWords (str) {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
}

/**
 * initalize and load system constants and user permissions (if applicable)
 * @param props
 * @returns {XML}
 */
export function initConfig (props) {
    const { constants, userPermissions } = props;
    if (typeof constants === typeof undefined && typeof userPermissions === typeof undefined) {
        return <div/>
    } else if (Object.keys(constants).length === 0 || Object.keys(userPermissions).length === 0) {
        return <div/>
    }
}

/**
 * check if value exists
 * @param value
 * @returns {boolean}
 */
export function isset (value) {
    if (typeof value !== typeof undefined) {
        return true;
    }
    return false;
}

/**
 * check variable for empty value
 * @param value
 * @returns {boolean}
 */
export function isEmpty (value) {
    // test results
    //---------------
    // []        true, empty array
    // {}        true, empty object
    // null      true
    // undefined true
    // ""        true, empty string
    // ''        true, empty string
    // 0         false, number
    // true      false, boolean
    // false     false, boolean
    // Date      false
    // function  false

    if (value === undefined)
        return true;

    if (typeof (value) === 'function' ||
        typeof (value) === 'number' ||
        typeof (value) === 'boolean' ||
        Object.prototype.toString.call(value) === '[object Date]')
        return false;

    if (value === null || value.length === 0)        // null or 0 length array
        return true;

    if (typeof (value) === "object") {
        // empty object

        var r = true;

        if (Object.keys(value).length > 0) {
            r = false;
        }

        return r;
    }

    if (typeof (value) === "string" && value.trim() === "") {
        return true;
    }

    return false;
}

/**
 * inverse of isEmpty function
 * @param value
 * @returns {boolean}
 */
export function notEmpty (value) {
    return !isEmpty(value);
}

/**
 * delete key value from object
 * @param obj
 * @param key
 * @returns {*}
 */
export function deleteObjectKey (obj, key) {
    if (this.isset(obj) && this.isset(key)) {
        if (obj.hasOwnProperty(key)) {
            delete obj[key];
        }
    }
    return obj;
}

/**
 * truncate text with ellipsis
 * @param string
 * @param length
 * @param ellipsis
 * @returns {*}
 */
export function truncate (string, length, ellipsis) {
    length = typeof length !== typeof undefined ? length : 20;
    ellipsis = typeof ellipsis !== typeof undefined ? ellipsis : '...';
    if (string.length > length) {
        return string.substring(0, length) + ellipsis;
    } else {
        return string;
    }
};

/**
 * truncate text array with ellipsis (supports emoji rendering)
 * @param string
 * @param length
 * @param ellipsis
 * @returns {*}
 */
export function truncateArray (string, length, ellipsis) {
    length = typeof length !== typeof undefined ? length : 20;
    ellipsis = typeof ellipsis !== typeof undefined ? ellipsis : '...';
    var actualLength = 0;
    // get length of text
    for (var i = 0; i < string.length; i++) {
        var strPart = string[i];
        // supports emoji rendering
        if (this.isEmpty(strPart.props)) {
            if (!this.isEmpty(strPart[0])) {
                strPart = strPart[0];
                actualLength += strPart.length;
                if (actualLength > length) {
                    string[i][0] = this.truncate(strPart, length, ellipsis);
                    return string;
                }
            }
        } else {
            actualLength++;
            if (actualLength > length) {
                string.push(ellipsis);
                return string;
            }
        }
    }
    return string;
};

/**
 * compare two values and returns true if match else false
 * @param str1
 * @param str2
 */
export function compareString (str1, str2) {
    if (typeof str1 !== typeof undefined && typeof str2 !== typeof undefined) {
        if (String(str1).toLowerCase() === String(str2).toLowerCase()) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareString function
 * @param str1
 * @param str2
 */
export function cmpStr (str1, str2) {
    return this.compareString(str1, str2);
}

/**
 * compare two booleans and returns true if match else false
 * @param value1
 * @param value2
 * @returns {boolean}
 */
export function compareBoolean (value1, value2) {
    if (typeof value1 !== typeof undefined && typeof value1 !== typeof undefined) {
        if (Boolean(value1) === Boolean(value2)) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareBoolean function
 * @param value1
 * @param value2
 */
export function cmpBool (value1, value2) {
    return this.compareBoolean(value1, value2);
}

/**
 * compare two integer and returns true if match else false
 * @param value1
 * @param value2
 * @returns {boolean}
 */
export function compareInt (value1, value2) {
    if (typeof value1 !== typeof undefined && typeof value1 !== typeof undefined) {
        if (parseInt(value1, 10) === parseInt(value2, 10)) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareString function
 * @param value1
 * @param value2
 */
export function cmpInt (value1, value2) {
    return compareInt(value1, value2);
}

/**
 * compare two float and returns true if match else false
 * @param value1
 * @param value2
 * @returns {boolean}
 */
export function compareFLoat (value1, value2) {
    if (typeof value1 !== typeof undefined && typeof value1 !== typeof undefined) {
        if (parseFloat(value1, 10) === parseFloat(value2, 10)) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareFLoat function
 * @param value1
 * @param value2
 */
export function cmpFloat (value1, value2) {
    return this.compareFLoat(value1, value2);
}

/**
 * calculates the default number of rows to display for react table
 * @param collection
 * @param maxPerPage
 * @returns {*}
 */
export function calculateRowsToDisplay (collection, maxPerPage) {
    // collection = collection || [];
    maxPerPage = maxPerPage || 10;
    // var emptyCollectionSize = 1;
    // if (collection.length === 0) {
    //     return emptyCollectionSize;
    // }
    // if (collection.length / maxPerPage > 1) {
        return maxPerPage;
    // }
    // return collection.length;
}

/**
 * javascript's version of php nl2br
 * @param str
 * @param is_xhtml
 * @returns {string}
 */
export function nl2br (str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br/>' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

/**
 * extracts sub domain from hostname
 * @returns {string}
 */
export function getSubdomain () {
    var extractedSubdomain = '';
    var hostName = window.location.hostname;
    if (!general.isEmpty(hostName)) {
        var splitHostName = hostName.split(".");
        var subdomain = splitHostName[0];
        if (!general.compareString(subdomain, "zave") &&
            !general.compareString(subdomain, "getzaving")) {
            extractedSubdomain = subdomain;
            return extractedSubdomain;
        }
    }
    return extractedSubdomain;
}

/**
 * gets file extension based on file name
 * @param fileName
 * @returns {*}
 */
export function getFileExt (fileName) {
    fileName = typeof fileName !== 'undefined' ? fileName : '';
    var fileNameSplits = fileName.split('.');
    if (fileNameSplits.length < 2) {
        return '';
    } else {
        let fileExt = fileNameSplits[fileNameSplits.length - 1];
        return fileExt ? fileExt.toLowerCase() : '';
    }
};

/**
 * gets file extension based on url
 * @param url
 * @returns {*}
 */
export function getFileExtFromUrl (url) {
    if (isEmpty(url)) {
        return '';
    }
    var urlSplits = url.split('/');
    var fileName = urlSplits[urlSplits.length - 1];
    return this.getFileExt(fileName);
}

/**
 * gets file name based on url
 * @param url
 * @returns {*}
 */
export function getFileNameFromUrl (url) {
    if (isEmpty(url)) {
        return '';
    }
    var urlSplits = url.split('/');
    var fileName = urlSplits[urlSplits.length - 1];
    return fileName;
}

/**
 * gets GET paramter from url
 * @param self
 * @param key
 * @returns {string}
 */
export function getGetParam (self, key) {
    if (self &&
        self.props &&
        self.props.match &&
        self.props.match.params &&
        self.props.match.params[key]) {
        return self.props.match.params[key];
    } else {
        return '';
    }
}

/**
 * generic function to get current route
 * works hand in hand with react-router v4
 * @param self
 * @returns {*}
 */
export function getCurrentRoute (self) {
    if (self &&
        self.props &&
        self.props.location &&
        self.props.location.pathname) {
        return self.props.location.pathname;
    } else {
        return '/';
    }
}

/**
 * generic function to format route with country code
 * @param currentRoute
 * @param countryCode
 * @returns {string}
 */
export function formatRouteWithCountryCode (currentRoute, countryCode) {
    currentRoute = !this.isEmpty(currentRoute) ? currentRoute : '/';
    countryCode = !this.isEmpty(countryCode) ? countryCode : this.getCountry();
    var formattedRoute = '/' + countryCode + currentRoute;
    return formattedRoute;
}

/**
 * prettify constant value for display
 * @param value
 * @returns {string}
 */
export function prettifyConstant (value) {
    if (this.isEmpty(value)) {
        return  '';
    } else {
        return this.ucFirstAllWords(value.replaceAll('_', ' '));
    }
}

/**
 * compare two value, will return 1, -1, 0 if greater, leaser, and equal respectifely
 *
 * @param type integer, string, float, boolean
 * @param a
 * @param b
 * @returns {number}
 */
export function compareTwoValue (type, a, b) {
    switch (type) {
        case 'float':
            if (typeof a === 'string') {
                a = a.replace(',', '') || 0;
                b = b.replace(',', '') || 0;
            }
            a = parseFloat(a) || 0;
            b = parseFloat(b) || 0;
            break;

        case 'integer':
        default:
            a = parseInt(a, 10) || 0;
            b = parseInt(b, 10) || 0;
            break;

        case 'string':
            // force null and undefined to the bottom
            a = a === null || a === undefined ? -Infinity : a;
            b = b === null || b === undefined ? -Infinity : b;
            // force any string values to lowercase
            a = typeof a === "string" ? a.toLowerCase() : a;
            b = typeof b === "string" ? b.toLowerCase() : b;
            break;
    }
    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    } else {
        return 0;
    }
}

/**
 * generic log function
 * @param message
 */
export function log (message) {
    console.log('================================================================');
    console.log(message);
    console.log('================================================================');
}

/**
 * get screen width
 * @returns {number}
 */
export function getScreenWidth () {
    var screenWidth = window.innerWidth || 0;
    return screenWidth;
}

/**
 * // TODO: to extend this function when we are supporting other countries in future
 * generic get country that user is access site from
 * @returns {string}
 */
export function getCountry () {
    var countryCode = 'sg';
    return countryCode;
}

/**
 * get word count of a string
 * @param str
 * @returns {number}
 */
export function getWordCount (str) {
    if (isEmpty(str)) {
        return 0;
    }
    var matches = str.match(/\S+/g) ;
    return matches ? matches.length : 0;
}

/**
 * trim whitespace from start and end of string
 * @param value
 * @returns {*}
 */
export function trimStr (value) {
    if (value) {
        return value.replace(/^\s+|\s+$/g, "");
    }
    return "";
}

/**
 * format string with pural default 's' based on count value
 * @param str
 * @param count
 * @param pural
 * @returns {*}
 */
export function formatPural (str, count, pural) {
    if (this.isEmpty(str)) {
        return '';
    }
    count = this.notEmpty(count) ? count : 0;
    pural = pural || 's';
    if (count > 1) {
        str += 's';
    }
    return str;
}

/**
 * fix missing emoji in react-emoji-render
 * @param message
 * @returns {*}
 */
export function fixEmoji (message) {
    message = message.replace(/:man_dancing:/g, '🕺');
    message = message.replace(/:star-struck:/g, '🤩');
    message = message.replace(/:grinning_face_with_star_eyes:/g, '🤩');
    return message;
}

/**
 *
 * @param country_code
 * @param [style] If nothing is specified then it be set as default styling
 * @returns {*|string}
 */
export function getCountryEmoji (country_code, style) {
    style = style || {
        boxShadow: '6px 0 20px rgba(255, 255, 255, 0.20)'
    };
    let countryEmoji = '';
    switch(country_code) {
        case 'SG':
            countryEmoji = '🇸🇬';
            break;
        case 'MY':
            countryEmoji = '🇲🇾';
            break;
        case 'HK':
            countryEmoji = '🇭🇰';
            break;
        case 'AU':
            countryEmoji = '🇦🇺';
            break;
        case 'VG':
            countryEmoji = '🇬🇧';
            break;
        default:
            countryEmoji = '';
    }
    // countryEmoji = '<span style="box-shadow: \'6px 0 20px rgba(255, 255, 255, 0.20)\'">' + countryEmoji + '</span>';
    return countryEmoji;
}

/**
 * check if file already exists on page
 * @param file
 * @param tag
 * @returns {boolean}
 */
export function checkFileExistsOnPage (file, tag) {
    if (!file || !tag) {
        return false;
    }
    let scripts = Array.from(document.querySelectorAll(tag));
    switch (tag) {
        case 'script':
            scripts = scripts.map(scr => scr.src);
            break;
        case 'link':
            scripts = scripts.map(scr => scr.href);
            break;
    }
    if (scripts.includes(file)) {
        return true;
    } else {
        return false;
    }
}

/**
 * prettify country code for display
 * @param value
 * @returns {string}
 */
export function prettifyCountry (value) {
    if (this.isEmpty(value)) {
        return '';
    } else {
        switch (value) {
            default:
            case '00':
                return 'Unassigned';
            case '01':
                return 'Other';
            case 'SG':
                return 'Singapore';
            case 'MY':
                return 'Malaysia';
            case 'AU':
                return 'Australia';
            case 'HK':
                return 'Hong Kong';
            case 'VG':
                return 'BVI';
        }
    }
}

/**
 * requires a file
 * @param {string} elementId
 * @param {string} file
 * @returns {Promise<any>}
 */
export function require (elementId, file) {
    if (!elementId || !file) {
        console.log(`Main.require: missing parameters (elementId, file)`);
        return;
    }
    return general.requireToDOM(document.getElementById(elementId), file);
}

/**
 * requires a file into a HTMLElement
 * @param {HTMLElement} element
 * @param {string} file
 * @returns {Promise<undefined|Promise<any>>}
 */
export function requireToDOM(element, file) {
    let jsFileExt = /(.js)$/i;
    let cssFileExt = /(.css)$/i;
    let tag = null;
    if (jsFileExt.test(file)) {
        tag = 'script';
    } else if (cssFileExt.test(file)) {
        tag = 'link';
    }
    if (general.checkFileExistsOnPage(file, tag)) {
        console.log(`${file} already exists on page`);
        return;
    }
    return new Promise((resolve, reject) => {
        let fileNode = null;
        if (jsFileExt.test(file)) {
            fileNode = document.createElement('script');
            fileNode.src = file;
            // IE
            fileNode.onreadystatechange = function () {
                if (fileNode.readyState === 'loaded' || fileNode.readyState === 'complete') {
                    fileNode.onreadystatechange = null;
                    resolve();
                }
            };
            // others
            fileNode.onload = function () {
                resolve();
            };
            element.appendChild(fileNode);
            // self.appendToElement(elementId, fileNode);
            // } else if (cssFileExt.test(file)) {
        } else {
            fileNode = document.createElement('link');
            fileNode.rel = 'stylesheet';
            fileNode.type = 'text/css';
            fileNode.href = file;
            // self.appendToElement(elementId, fileNode);
            element.appendChild(fileNode);
            resolve();
        }
    });
}

/**
 * Downloads a file from a url
 * @param {string} fileUrl
 */
export function downloadFile(fileUrl) {
    const downloadApiUrl = `${config.apiUrl}/v1.0.0/general/download`;
    const fileName = general.getFileNameFromUrl(fileUrl);
    fetch(downloadApiUrl, {
        method: 'post',
        headers: { 'content-type': "application/json" },
        body: JSON.stringify({ url: fileUrl })
    })
    .then(response => response.blob())
    .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = decodeURIComponent(fileName);
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
        general.alert(`File downloaded successfully`);
    });
}

export function formatCustomStyle() {
    let style = {
        fontFamily: 'objektiv-mk2, sans-serif'
    };
    return style;
}

/**
 * Polyfill to support IE11 as a replacement for "new Event('resize')" syntax
 * @param {string} eventName
 * @returns {Event}
 */
export function createDomEvent (eventName) {
    let domEvent = null;
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        domEvent = document.createEvent('UIEvents');
        domEvent.initUIEvent(eventName, true, false, window, 0);
    } else {
        domEvent = new Event(eventName);
    }
    return domEvent;
}

/**
 * generic alert with no timeout limit set
 * @param message
 * @param type Takes in 'success' or 'error'
 */
export function alertNoLimit (message, type) {
    type = type || 'success';
    toast.success(message, {
        type: type,
        className: 'toast-' + type
    });
}

export function strTolowerCase(word) {
    return typeof word === "string" ? word.toLowerCase() : word;
}

/**
 * Count the number of decimal places in a value
 * Source: https://stackoverflow.com/questions/27082377/get-number-of-decimal-places-with-javascript
 * @param value
 * @returns {number|number}
 */
export function countDecimals (value) {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}

/**
 * Count the number of zeros in decimal of a value
 * Source: https://stackoverflow.com/questions/31001901/how-to-count-the-number-of-zero-decimals-in-javascript
 * @param value
 * @returns {number}
 */
export function noOfZerosInDecimal (value) {
    const noOfZeros = Math.floor( Math.log10(value) + 1);
    return noOfZeros && noOfZeros < 0 ? Math.abs(noOfZeros) : 0;
}
