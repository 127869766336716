/*======================================================================================================================
 * import main libraries
 *====================================================================================================================*/
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { ListItem } from 'material-ui/List';
import Avatar from 'material-ui/Avatar/Avatar';
import {h} from '../../../../helpers';
import FontAwesome from 'react-fontawesome';
import * as CompanyModel from '../../../../models/company';
import * as UploadModel from '../../../../models/upload';
import PartialUIButton from '../../UI/Button';

const selfChatStyle = {
    backgroundColor: '#DBFAFF'
};
const MESSAGE_1 = 'The following fields will be extracted:';
const MESSAGE_2 = '* Please note that file extraction time may take up to 1 minute.';

/*======================================================================================================================
 * define react component
 *====================================================================================================================*/

class PartialBizfileEntityUpdate extends React.Component {

    constructor() {
        super();
        let additionalFields = {
            fields: {
                entity_file_upload: {},
                extractor_id: '',
                file_name: ''
            },
            isShowUploadButton: true,
            entity_files: [],
            submitBtnDisabled: true,
        };
        this.state = h.general.initState(additionalFields);
    }

    componentDidUpdate(prevProps) {
        let { droppedFiles } = this.props;
        if (h.notEmpty(droppedFiles) &&
            !h.cmpStr(JSON.stringify(prevProps.droppedFiles), JSON.stringify(droppedFiles))) {
            const event = {
                target: {
                    files: droppedFiles
                }
            };
            this.onFileUploadChange(event);
        }
    }

    render() {
        const {constants, index, isSelf, profilePic, name, date, formattedMessage, userFrom, taskSwimLaneStatus} = this.props;
        let isSelfType = isSelf && Boolean(isSelf) === true;

        return(
            <ListItem
                disabled={true}
                className="chat-list-item"
                innerDivStyle={isSelfType ? selfChatStyle : {}}
                leftAvatar={<Avatar src={profilePic} />}
                children={
                    <div key={index}>
                        <label style={{fontWeight: 'bold'}}>{name}</label> <small>{index > 0 ? 'replied ' : 'asked '} {date}</small>
                        <div style={{display: 'table', fontSize: 12}}>
                            <small className="text-muted mb-2">{userFrom}</small>
                        </div>
                        <br/>
                        <span style={{fontSize: 14}}>{formattedMessage}</span><br/><br/>

                        <div className='row mb-5'>
                            <small className="col-10 mb-1">{MESSAGE_1}</small>&nbsp;
                            <ul className="col-10 ml-5">
                                <li><small>Registration Number</small></li>
                                <li><small>Company Name</small></li>
                                <li><small>Registered Office Address</small></li>
                                <li><small>Director Details (Name, ID, Address)</small></li>
                                <li><small>Shareholder Details (Name, ID, Address, Share Type, Share Count)</small></li>
                            </ul>
                            <small className="col-10 pt-1">{MESSAGE_2}</small>&nbsp;
                            <form id="entity-file-upload" className="form-horizontal col-12 mt-5" onSubmit={this.handleSubmit.bind(this)}>
                                <input
                                    type="file"
                                    className="form-control"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {this.onFileUploadChange(event);}}
                                    ref={(ref) => this.upload = ref}
                                    multiple
                                />
                                {this.state.isShowUploadButton && (taskSwimLaneStatus === constants.TASK_STATUS.PENDING_CLIENT) &&
                                    <div className="p-5 text-center mb-3" style={{border: '1px dashed #808080'}}>
                                        {this.showAttachedFile()}
                                    </div>}
                                {this.state.isShowUploadButton && (taskSwimLaneStatus === constants.TASK_STATUS.PENDING_CLIENT) &&
                                <PartialUIButton
                                    buttonType={2}
                                    primary={true}
                                    type='button'
                                    label='Select Bizfile'
                                    onClick={() => this.upload.click() }
                                />}&nbsp;&nbsp;
                                {this.state.isShowUploadButton && (taskSwimLaneStatus === constants.TASK_STATUS.PENDING_CLIENT) &&
                                <PartialUIButton
                                    primary={true}
                                    type='submit'
                                    label='Upload'
                                />}
                            </form>
                        </div>
                    </div>
                }
            />
        );
    }

    async handleSubmit(e) {
        e.preventDefault();
        const self = this;
        await self.onFileUpload();
    }

    /**
     * Handles input file upload event
     * @param {} event
     */
    onFileUploadChange(event) {
        const self = this;
        let {entity_files} = this.state;
        let fields = {...self.state.fields};
        let files = event.target.files;
        let hasUnsupportedFileType = false;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type && !h.cmpStr(file.type, 'application/pdf')) {
                hasUnsupportedFileType = true;
            } else {
                entity_files.push(file);
            }
        }
        if (hasUnsupportedFileType) {
            h.general.alertNoLimit('Removed non-PDF files from list of Bizfiles to upload.', 'warning');
        }
        self.setState({
            entity_files: entity_files,
            fields: fields,
            submitBtnDisabled: false
        });
    }

    /**
     * Handles file upload
     * @returns {Promise<>}
     */
    // Prepares form data for file upload
    async onFileUpload() {
        const self = this;
        let { entity_files } = self.state;
        let { constants, currentUser, currentEntity, task, reloadTaskMessages, closeDialog, resetDroppedFiles} = self.props;
        let formData = new FormData();
        let task_entity_id = task.task_entity_fk || '';
        let task_id = task.task_task_id;
        let entity_country_code = currentEntity.entity_country_code;

        if(h.isEmpty(entity_files)) return;
        if(Array.isArray(entity_files) && entity_files.length > 0) {
            formData.append('file', entity_files[0]);
        }
        formData.append('user_id', currentUser.user_id);
        formData.append('owner_id', task_entity_id);
        formData.append('owner_type', 'entity');
        formData.append('document_country_code', entity_country_code);
        formData.append('task_type', constants.NIRVEDA.TASK_TYPE.UPDATE.UPDATE_ENTITY);
        formData.append('task_module_task_id', task_id);
        formData.append('document_reading_submodule', 'task');
        formData.append('upload_type', '17');
        formData.append('data_type', '2');

        await new Promise((resolve, reject) => {
            UploadModel.upload(formData, function(data) {
                const apiRes = h.api.handleApiResponse(data, true);
                if (apiRes.status === 'ok') {
                    // clear dropped file list after upload is done and remove upload button from view
                    resetDroppedFiles();
                    self.clearAttachedFiles();
                    self.setState({isShowUploadButton: false}, () => {
                        reloadTaskMessages();
                        closeDialog();
                        resolve();
                    });
                } else {reject(h.general.alertNoLimit('Failed to upload Bizfile', 'warning'));}
            });
        });
    }

    showAttachedFile () {
        let {entity_files} = this.state;
        let {theme} = this.props;
        if (h.notEmpty(entity_files)) {
            let fileList = [];
            for (let i = 0; i < entity_files.length; i++) {
                const attachedFile = entity_files[i];
                fileList.push(attachedFile.name);
            }
            return <span>
                {fileList.map((fileItem) => {
                    return <div>{fileItem}</div>;
                })}
                <br/>
                <small
                    style={{cursor: 'pointer', color: theme.palette.primary1ColorV2}}
                    onClick={() => {
                        this.clearAttachedFiles();
                    }}
                >
                    <FontAwesome
                        name='times'
                        style={{cursor: 'pointer'}}
                        size= '1x'
                    />&nbsp;Clear file
                </small>
            </span>;
        } else {
            return <span className="text-muted">Drag Bizfile here to upload</span>;
        }
    }

    clearAttachedFiles () {
        this.setState({entity_files: [], uploadProgress: ''}, () => {
            this.props.resetDroppedFiles();
        });
    }
}

const mapStateToProps = state => ({
    currentUser: state.user,
    currentEntity: state.company.currentEntity,
    constants: state.constants,
    theme: state.config.theme
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartialBizfileEntityUpdate));