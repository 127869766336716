import $ from 'jquery';
import { config } from '../configs/config';

export function getEntityUsersAndEntityUserGroups (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/private-task/get-entity-users-and-entity-usergroups',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getServiceProviderUsersAndServiceProviderUserGroups (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/private-task/get-serviceprovider-users-and-serviceprovider-usergroups',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
