import React, { Component } from 'react';
import PropTypes from "prop-types";

export class CommonOverlay extends Component {
    render () {
        return (
            <div className="overlay-container">
                <span className="overlay-container-content">{this.props.content}</span>
            </div>
        );
    }
}

CommonOverlay.propTypes = {
    content: PropTypes.string.isRequired
};

export default CommonOverlay;