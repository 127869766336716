import $ from 'jquery';
import { config } from '../configs/config';
import { general, api, log } from '../helpers/index';

/**
 * create user api
 * @param data
 * @param callback
 */
export function createUser (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/signup',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create user by invitation api
 * @param data
 * @param callback
 */
export function createUserByInvite (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/invite-signup',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check email address availability
 * @param data
 * @param callback
 */
export function checkEmailAvailability (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/user/check-email-availability',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * verify recaptcha
 * @param data
 * @param callback
 */
export function verifyRecaptcha (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/verify-recaptcha',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * verify email
 * @param data
 * @param callback
 */
export function verifyEmail (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/verify-email',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * resend verification email
 * @param data
 * @param callback
 */
export function resendVerificationEmail (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/resend-verification-email',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * email login
 * @param data
 * @param callback
 */
export function login (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/login',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * email login
 * @param data
 * @param callback
 */
export function loginGoogle (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/login/google',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * user sign out
 * @param data
 * @param callback
 */
export function signout (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/signout',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check access token validity
 * @param callback
 */
export function checkToken (callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/check-token',
        dataType: "json",
        data: {},
        error: callback
    }).done(callback);
}

/**
 * user forgot password
 * @param data
 * @param callback
 */
export function forgotPassword (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/forgot-password',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * user reset password
 * @param data
 * @param callback
 */
export function resetPassword (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/user/reset-password',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get account creation invitation
 * @param data
 * @param callback
 */
export function getInvite (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/user/get-invite',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get user
 * @param data
 * @param callback
 */
export function getUser (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/user/get-user',
        dataType: "json",
        data: data,
        error: callback
    }).done(function (response) {
        var apiRes = api.handleApiResponse(response, false);
        var user = {};
        var entity = {};
        if (general.cmpStr(apiRes.status, 'ok') && !general.isEmpty(apiRes.data)) {
            user = apiRes.data.user || {};
            entity = apiRes.data.entities && apiRes.data.entities.length > 0 ? apiRes.data.entities[0] : {};
            log.setUser(user);
            log.setEntity(entity);
        }
        callback(response);
    });
}

/**
 * get user
 * @param data
 * @param callback
 */
export function updateUser (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/user/update-user-profile',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update user settings
 * @param data
 * @param callback
 */
export function updateUserSettings (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/user/update-user-settings',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get user role
 * @param data
 * @param callback
 */
export function updateUserRole (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/user/update-user-role',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check if token is valid
 * @param {*} data
 * @param {*} callback
 */
export function isTokenValid(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/is-token-valid',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check if the password reset token is valid
 * @param {{ token : string }} data
 * @param {*} callback
 */
export function checkResetPasswordToken(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/check-reset-password-token',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
