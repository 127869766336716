/*======================================================================================================================
 * import main libraries
 *====================================================================================================================*/
import React from 'react';
import { helpers, h } from '../../helpers/index';
import { config } from '../../configs/config';

export class Header extends React.Component {

    constructor () {
        super();
        var additionalFields = {
            isLogoVisible: true,
            fields: {}
        }
        this.state = helpers.general.initState(additionalFields);
    }

    componentDidMount() {
        const { showLogo } = this.props;
        if (!helpers.general.isEmpty(showLogo)) {
            this.setState({isLogoVisible: showLogo});
        }
        // add custom class attribute to body tag
        document.body.classList.toggle('login');
    }

    componentWillUnmount() {
        // remove custom class attribute to body tag
        document.body.classList.remove('login');
    }

    render() {
        const { isLogoVisible } = this.state;
        //TODO: to refactor this to get service provider web url dynamically
        const spWebUrl = 'https://www.getzave.com';
        let logo = 'https://cdn.getzave.com/assets/logo/zave-logo-email-v2.png';
        return (
            !helpers.general.isEmpty(isLogoVisible) && helpers.general.compareBoolean(isLogoVisible, true) ?
            <section className="logo text-center mb-4">
                <img style={{maxWidth:120}} src={logo} alt={config.appName}/>
            </section> : <div></div>
        );
    }

}

export class Body extends React.Component {
    render () {
        let style = this.props.style;
        let mainBodyStyle = this.props.mainBodyStyle;
        return (
            <div style={style} className="page-login pbm">
                <div style={mainBodyStyle} className="main-body">
                    <div className="">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export class Footer extends React.Component {
    render () {
        return (
            <div></div>
        );
    }
}
