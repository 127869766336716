import { helpers, h } from '../helpers/index';
import * as Constants from '../constants/index';
export default function pricing (state = {}, action) {
    switch (action.type) {
        case 'SET_PRICINGS':
            var newPricingData = {
                accounting_and_tax: [],
                formation_and_corporate_secretary: [],
                other_services: []
            };
            // get pricing details from server side fields
            var getPricingDetails = function (pricing) {
                return {
                    pricing_id: pricing.pricing_pricing_id || pricing.pricing_id,
                    country_code: pricing.pricing_country_code || pricing.country_code,
                    name: pricing.pricing_name || pricing.name,
                    description: pricing.pricing_description || pricing.description,
                    category: pricing.pricing_category || pricing.category,
                    currency_code: pricing.pricing_currency_code || pricing.currency_code,
                    transaction_sku: pricing.pricing_transaction_sku || pricing.transaction_sku,
                    transaction_count: pricing.pricing_transaction_count || pricing.transaction_count,
                    addon_type: pricing.pricing_addon_type || pricing.addon_type,
                    addon_fk: pricing.pricing_addon_fk || pricing.addon_fk,
                    pricing_plans: [],
                    payment_methods: Constants.PaymentMethods.ALL
                };
            };
            // get pricing plan details from server side fields
            var getPricingPlanDetails = function (pricingPlan) {
                return {
                    pricing_plan_id: pricingPlan.pricing_plan_pricing_plan_id || pricingPlan.pricing_plan_id,
                    name: pricingPlan.pricing_plan_name || pricingPlan.name,
                    amount: pricingPlan.pricing_plan_amount || pricingPlan.amount,
                    amount_prefix: pricingPlan.pricing_plan_amount_prefix || pricingPlan.amount_prefix,
                    type: pricingPlan.pricing_plan_type || pricingPlan.type
                };
            };
            // format each pricing category
            if (helpers.general.notEmpty(action.pricings)) {
                // load pricing data for accounting and tax
                if (helpers.general.notEmpty(action.pricings.accounting_and_tax)) {
                    for (var i = 0; i < action.pricings.accounting_and_tax.length; i++) {
                        const pricing = action.pricings.accounting_and_tax[i];
                        if (helpers.general.notEmpty(pricing)) {
                            var newPricing = getPricingDetails(pricing);
                            if (helpers.general.notEmpty(pricing.pricing_plans)) {
                                for (var j = 0; j < pricing.pricing_plans.length; j++) {
                                    const pricingPlan = pricing.pricing_plans[j];
                                    if (helpers.general.notEmpty(pricingPlan)) {
                                        newPricing.pricing_plans.push(getPricingPlanDetails(pricingPlan));
                                    }
                                }
                            }
                            newPricingData.accounting_and_tax.push(newPricing);
                        }
                    }
                }
                // load pricing data for formation and corporation secretary
                if (helpers.general.notEmpty(action.pricings.formation_and_corporate_secretary)) {
                    for (var i = 0; i < action.pricings.formation_and_corporate_secretary.length; i++) {
                        const pricing = action.pricings.formation_and_corporate_secretary[i];
                        if (helpers.general.notEmpty(pricing)) {
                            var newPricing = getPricingDetails(pricing);
                            if (helpers.general.notEmpty(pricing.pricing_plans)) {
                                for (var j = 0; j < pricing.pricing_plans.length; j++) {
                                    const pricingPlan = pricing.pricing_plans[j];
                                    if (helpers.general.notEmpty(pricingPlan)) {
                                        newPricing.pricing_plans.push(getPricingPlanDetails(pricingPlan));
                                    }
                                }
                            }
                            newPricingData.formation_and_corporate_secretary.push(newPricing);
                        }
                    }
                }
                // load pricing data for other services
                if (helpers.general.notEmpty(action.pricings.other_services)) {
                    for (var i = 0; i < action.pricings.other_services.length; i++) {
                        const pricing = action.pricings.other_services[i];
                        if (helpers.general.notEmpty(pricing)) {
                            var newPricing = getPricingDetails(pricing);
                            if (helpers.general.notEmpty(pricing.pricing_plans)) {
                                for (var j = 0; j < pricing.pricing_plans.length; j++) {
                                    const pricingPlan = pricing.pricing_plans[j];
                                    if (helpers.general.notEmpty(pricingPlan)) {
                                        newPricing.pricing_plans.push(getPricingPlanDetails(pricingPlan));
                                    }
                                }
                            }
                            newPricingData.other_services.push(newPricing);
                        }
                    }
                }
            }
            var currencyCode =
                helpers.general.notEmpty(newPricingData.accounting_and_tax) &&
                helpers.general.notEmpty(newPricingData.accounting_and_tax[0]) &&
                helpers.general.notEmpty(newPricingData.accounting_and_tax[0].currency_code) ?
                    newPricingData.accounting_and_tax[0].currency_code : '';
            return {
                ...state,
                pricings: newPricingData,
                accounting: newPricingData.accounting_and_tax,
                corporate: newPricingData.formation_and_corporate_secretary,
                other: newPricingData.other_services,
                currencyPrefix: helpers.pricing.getCurrencyShortPrefix(currencyCode)
            };
        case 'GET_PRICING':
            var pricingType = action.pricingType || '';
            var pricingId = action.pricingId || '';
            var pricings = state.pricings || {};
            var pricingData = {};
            if (helpers.general.notEmpty(pricings) &&
                helpers.general.notEmpty(pricingType) &&
                helpers.general.notEmpty(pricingId) &&
                helpers.general.notEmpty(pricings[pricingType])) {
                var pricingTypeData = pricings[pricingType];
                for (var i = 0; i < pricingTypeData.length; i++) {
                    const pricing = pricingTypeData[i];
                    if (helpers.general.notEmpty(pricing)) {
                        pricingData = Object.assign({}, pricing);
                    }
                }
            }
            return {
                ...state,
                pricingData: pricingData
            };
        default:
            return state;
    }
}