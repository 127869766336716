import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

export class CommonFloatingActionButton extends React.Component {

    constructor () {
        super();
        // var additionalFields = {
        //     bgCounter: 0,
        //     bgImage: '',
        //     enabled: true,
        //     timeout: 5000,
        //     fields: {
        //         email: { value: '' },
        //         password: { value: '' },
        //         remember: { value: 0, dirty: true }
        //     }
        // };
        // this.state = helpers.general.initState(additionalFields);
    }

    componentDidMount() {

    }

    render() {
        const { theme, onAddButtonClick } = this.props;

        return (
            <div>
                <FloatingActionButton
                    onClick={onAddButtonClick}
                    style={{
                        backgroundColor: theme.palette.primary1Color,
                        margin: 0,
                        top: 'auto',
                        right: 100,
                        bottom: 22,
                        left: 'auto',
                        position: 'fixed',
                        zIndex: 995
                    }}>
                    <ContentAdd />
                </FloatingActionButton>
            </div>
        );
    }

}

CommonFloatingActionButton.propTypes = {
    theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    theme: state.config.theme
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommonFloatingActionButton));