import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import { ListItem } from 'material-ui/List';
import Avatar from "material-ui/Avatar/Avatar";
import {h, helpers} from "../../../../helpers";
import RaisedButton from 'material-ui/RaisedButton';
import * as CompanyModel from "../../../../models/company";

const selfChatStyle = {
    backgroundColor: '#DBFAFF'
};

class PartialConnectClientSP extends React.Component {

    constructor () {
        super();
        let additionalFields = {
            disableSubmitButton: false,
            isShowSubmitButton: false,
            fields: {
                first_name: { value: '' },
                last_name: { value: '' },
                firm_name: { value: '' },
                email: { value: '' }
            }
        }
        this.state = h.general.initState(additionalFields);
    }

    componentDidMount () {
        this.performApiCheck();
    }

    render () {
        const {index, isSelf, profilePic, name, date, formattedMessage, userFrom} = this.props;
        let isSelfType = isSelf && Boolean(isSelf) === true;

        return (
            <ListItem
                disabled={true}
                className="chat-list-item"
                innerDivStyle={isSelfType ? selfChatStyle : {}}
                leftAvatar={<Avatar src={profilePic} />}
                children={
                    <div key={index}>
                        <label style={{fontWeight: 'bold'}}>{name}</label> <small>{index > 0 ? 'replied ' : 'asked '} {date}</small>
                        <div style={{display: 'table', fontSize: 12}}>
                            <small className="text-muted mb-2">{userFrom}</small>
                        </div>
                        <br/>
                        <span style={{fontSize: 14}}>{formattedMessage}</span><br/><br/>

                        <form className="register-form" onSubmit={this.handleSubmit.bind(this)}>
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <h.form.Input
                                        className="placeholder-no-fix"
                                        type="text"
                                        floatingLabelText="First Name *"
                                        name="first_name"
                                        fullWidth={false}
                                        onChange={this.onChange.bind(this)}
                                        data={this.state.fields.first_name}
                                        disabled={this.state.disableSubmitButton}
                                    />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <h.form.Input
                                        className="placeholder-no-fix"
                                        type="text"
                                        floatingLabelText="Last Name *"
                                        name="last_name"
                                        fullWidth={false}
                                        onChange={this.onChange.bind(this)}
                                        data={this.state.fields.last_name}
                                        disabled={this.state.disableSubmitButton}
                                    />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <h.form.Input
                                        className="placeholder-no-fix"
                                        type="text"
                                        floatingLabelText="Firm Name *"
                                        name="firm_name"
                                        fullWidth={false}
                                        onChange={this.onChange.bind(this)}
                                        data={this.state.fields.firm_name}
                                        disabled={this.state.disableSubmitButton}
                                    />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <h.form.Input
                                        className="placeholder-no-fix"
                                        type="text"
                                        floatingLabelText="Email Address *"
                                        name="email"
                                        fullWidth={false}
                                        onChange={this.onChange.bind(this)}
                                        data={this.state.fields.email}
                                        disabled={this.state.disableSubmitButton}
                                    />
                                </div>
                                <div className="col-12">
                                    {this.state.isShowSubmitButton &&
                                    <RaisedButton
                                        type="submit"
                                        className="mt-3"
                                        label="Connect"
                                        onClick={this.handleSubmit.bind(this)}
                                        disabled={this.state.disableSubmitButton}
                                        primary={true}
                                    />}
                                </div>
                            </div>
                        </form>

                </div>}
            />
        );
    }

    onChange (e) {
        this.setState({fields: helpers.form.onFormChange(e, this.state.fields, this.validate.bind(this))});
    }

    validate (key) {
        let fields = this.state.fields;
        let value = fields[key].value || '';

        fields[key].dirty = true;
        fields[key].error = '';

        switch (key) {
            case 'first_name':
                if (!value) {
                    fields.first_name.error = 'Please enter first name';
                }
                break;
            case 'last_name':
                if (!value) {
                    fields.last_name.error = 'Please enter last name';
                }
                break;
            case 'firm_name':
                if (!value) {
                    fields.firm_name.error = 'Please enter firm name';
                }
                break;
            case 'email':
                if (!value) {
                    fields.email.error = 'Please enter email address';
                } else if (!h.validate.validateEmail(value)) {
                    fields.email.error = 'Please enter a valid email address';
                }
                break;
        }

        this.setState({fields: fields});
    }

    handleSubmit (e) {
        e.preventDefault();
        const self = this;
        const {task, reloadTaskMessages} = this.props;
        let isValid = h.form.onFormSubmit(this.state.fields, this.validate.bind(this));
        if (isValid) {
            self.setState({disableSubmitButton: true, isShowSubmitButton: false});
            let taskId = task.task_task_id;
            let entityId = task.task_entity_fk;
            const query = {
                entity_id: entityId,
                service_provider_name: this.state.fields.firm_name.value,
                rep_first_name: this.state.fields.first_name.value,
                rep_last_name: this.state.fields.last_name.value,
                rep_email: this.state.fields.email.value,
                task_id: taskId
            };
            CompanyModel.createEntitySPInvite(query, (response) => {
                let apiRes = h.api.handleApiResponse(response);
                if (h.cmpStr(apiRes.status, 'ok')) {
                    reloadTaskMessages();
                }
            });
        }
    }

    performApiCheck () {
        const self = this;
        const {constants, task, toggleChatInputDisplay} = this.props;
        let {fields} = this.state;
        if (h.notEmpty(task)) {
            let entityId = task.task_entity_fk;
            let taskType = task.task_type;
            let taskSubType = task.task_sub_type;
            let taskFkId = task.task_fk_id;
            //System generated task type + client_connect_sp task sub type
            if (h.cmpStr(taskType, constants.TASK_TYPES.SYSTEM_GENERATED) && h.cmpStr(taskSubType, constants.TASK_SUB_TYPES.SYSTEM_GENERATED.CLIENT_CONNECT_SP)) {
                //Proceed with api check with task_fk_id "entity_service_provider_invite_id" exists
                if (h.notEmpty(taskFkId)) {
                    self.setState({disableSubmitButton: true, isShowSubmitButton: false});
                    const query = {entity_id: entityId};
                    CompanyModel.getEntitySPInvite(query, (response) => {
                        let apiRes = h.api.handleApiResponse(response, false);
                        if (h.cmpStr(apiRes.status, 'ok') && h.notEmpty(apiRes.data)) {
                            let invite = apiRes.data;
                            fields.first_name.value = invite.rep_first_name;
                            fields.last_name.value = invite.rep_last_name;
                            fields.firm_name.value = invite.service_provider_name;
                            fields.email.value = invite.rep_email;
                            self.setState({fields: fields});
                        }
                    });
                    toggleChatInputDisplay(true);
                } else { //SP invite form hasn't been submitted yet
                    self.setState({disableSubmitButton: false, isShowSubmitButton: true});
                    toggleChatInputDisplay(false);
                }
            }
        }
    }

}

const mapStateToProps = state => ({
    constants: state.constants
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartialConnectClientSP));