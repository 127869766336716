/*======================================================================================================================
 * import main libraries
 *====================================================================================================================*/
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { general, api, auth, form, validate } from '../../helpers/index';
import { setUser } from '../../actions/user';
import * as ConfigActions from '../../actions/config';
import { config } from '../../configs/config';
import * as UserModel from '../../models/user';
import { Header, Body, Footer } from '../Layout/LayoutPublic';
import RaisedButton from 'material-ui/RaisedButton';
import Divider from 'material-ui/Divider';
import {PartialTaskList} from "../Partial/Task/PartialTaskList";
import btnGoogleImage from '../../assets/img/btn_google_signin_dark_normal_web@2x.png';
import btnGooglePressedImage from '../../assets/img/btn_google_signin_dark_normal_web@2x.png';
import { GoogleLogin } from 'react-google-login';
import { helpers, h } from "../../helpers";
import * as GeneralModel from "../../models/general";
import { setCurrentEntityByEntityId } from '../../actions/company';
import { handleApiResponse } from '../../helpers/api';

const dividerContainerStyle = {
    width: 100,
    marginTop: 4
}
const dividerStyle = {
    backgroundColor: '#868e96'
}
const dividerTextStyle = {
    fontSize: 14
}

/*======================================================================================================================
 * define react component
 *====================================================================================================================*/
export class UserLogin extends React.Component {

    constructor () {
        super();
        // get remember me stored email address if there is any
        var rememberMe = auth.getCookie(auth.cookieRememberMe) || 0,
            rememberedEmail = auth.getCookie(auth.cookieEmail) || '',
            additionalFields = {
            fields: {
                email: { value: rememberedEmail, dirty: rememberedEmail !== '' ? true : false },
                password: { value: '' },
                remember: { value: rememberMe, dirty: true }
            }
        }
        this.state = general.initState(additionalFields);
        helpers.logHelper.log({subPath: 'login'});
    }

    componentDidMount () {
        const { constants, userPermissions, entities, setCurrentEntityByEntityId } = this.props;
        var self = this;
        var subdomain = general.getSubdomain();
        var accessToken = general.findGetParameter('token');
        var currentEntityId = general.findGetParameter('entity_id');
        if (!general.isEmpty(accessToken)) { //Login using token in parameter
            //Set up all cookies before loading system
            self.initUserAuthentication(accessToken); //Store the token in the cookie

            // set current entity id into cookie first in order for switching to this entity later
            if (h.notEmpty(currentEntityId)) {
                auth.setCookie(auth.cookieCurrentEntityId, currentEntityId);
            }

            //Check if the user is logged in since cookie has already been set
            self.isLoggedIn((isLoggedIn) => {
                if(isLoggedIn) {
                    self.afterLoginRedirectHandler();
                }
                else {
                    console.log('Invalid access token provided.');
                    // window.location.href = '/login'; //If the user is still not logged in, bring back to login page without access token in param
                    h.auth.redirectToLogin();
                }
            });
        } else { //No token was found in parameter, so try normal login
            self.isLoggedIn((isLoggedIn) => { //Check if the existing access token is valid
                if(!isLoggedIn) {
                    console.log('user is not logged in.');
                    auth.deleteAccessToken(); //Remove the stale access token
                }
                else { //If the user is already logged in, bring to landing page
                    console.log('user is already logged in.');
                    if (!general.isDev() && !general.compareString(subdomain, 'localhost') && !general.compareString(subdomain, 'app')) {
                        self.afterLoginRedirectHandler();
                    }
                }
            });
        }

        // add custom class attribute to body tag
        document.body.classList.toggle('login');
    }

    componentWillUnmount() {
        // remove custom class attribute to body tag
        document.body.classList.remove('login');
    }

    isLoggedIn(callback) {
        let token = auth.getAccessToken();
        if(!token) { //If there is no access token, user is not logged in
            return callback(false);
        }
        let data = {
            token
        };
        UserModel.isTokenValid(data, (response) => { //If token is not valid, user is not logged in
            let apiRes = handleApiResponse(response, false);
            let { isValid } = apiRes.data || {};
            callback(isValid || false);
        });
    }

    afterLoginRedirectHandler() {
        const self = this;
        const redirectRoute = h.general.findGetParameter('redirect_route') || '';
        const redirectSearch = h.general.findGetParameter('redirect_search') || '';
        let redirectUriRel = redirectRoute;
        if(redirectSearch) {
            redirectUriRel += redirectSearch;
        }
        GeneralModel.getConfig({}, function (data) {
            var apiRes = api.handleApiResponse(data, false);
            if (apiRes.status === 'ok' && apiRes.data) {
                var permission = apiRes.data.permissions;
                var constants = apiRes.data.constants;
                if (redirectUriRel && redirectRoute) {
                    self.props.history.replace(redirectUriRel);
                } else {
                    if (helpers.user.hasViewPermission(constants, permission, '/compliance')) {
                        self.props.history.replace('/compliance');
                    } else {
                        self.props.history.replace('/tasks');
                    }
                }
            // default redirect to compliance
            } else {
                if (redirectUriRel && redirectRoute) {
                    self.props.history.replace(redirectUriRel);
                } else {
                    self.props.history.replace('/compliance');
                }
            }
        });
    }

    initUserAuthentication (accessToken) {
        const { setUser } = this.props;
        // console.log('new accessToken: ' + accessToken)
        auth.setAccessToken(accessToken);
        setUser(auth.getUserInfo());
    }

    redirectToEntityUrl (data) {
        const redirectRoute = h.general.findGetParameter('redirect_route') || '';
        const redirectSearch = h.general.findGetParameter('redirect_search') || '';
        const redirectRouteEncoded = encodeURIComponent(redirectRoute);
        const redirectSearchEncoded = encodeURIComponent(redirectSearch);
        if (data &&
            data.entity &&
            data.access_token &&
            data.entity.company_url_name) {
            var entityUrlName = data.entity.company_url_name;
            var entityUrl = '';
            if (general.isDev()) {
                entityUrl = config.webappUrl.replace('localhost', entityUrlName + '.localhost');
            } else {
                entityUrl = config.webappUrl.replace('app', entityUrlName);
            }
            entityUrl += `?token=${data.access_token}&redirect_route=${redirectRouteEncoded}&redirect_search=${redirectSearchEncoded}`;
            window.location.href = entityUrl;
        }
        else {
            console.error('redirectToEntityUrl: Missing data for entity.');
            console.log(data);
        }
    }

    // componentWillMount () {
    //     var data = {
    //         email: auth.getCookie(auth.cookieEmail) || '',
    //         remember: auth.getCookie(auth.cookieRememberMe) || 0
    //     };
    //     // // console.log(data);
    //
    //     this.props.loadLoginForm(data);
    //
    //     console.log(this.props);
    // }

    onChange (e, isInputChecked) {
        var fields = this.state.fields,
            key = '',
            value = '';

        // handle Switch element types
        if (e.props) {
            key = e.props.name;
            value = e.props.value ? false : true;
        }
        // handle checkbox element types
        else if (e.target.name === 'remember') {
            key = e.target.name;
            value = parseInt(e.target.value, 10) === 1 ? 0 : 1;
        }
        // handle normal Input element types
        else {
            key = e.target.name;
            value = e.target.value;
        }

        fields[key].value = value;
        fields[key].error = '';

        this.setState({fields: fields});

        this.validate(key);
    }

    validate (key) {
        var fields = this.state.fields,
            value = fields[key].value || '';

        fields[key].dirty = true;

        switch (key) {
            case 'email':
                if (!value) {
                    fields.email.error = 'Please enter your email address';
                } else if (!validate.validateEmail(value)) {
                    fields.email.error = 'Please enter a valid email';
                }
                break;
            case 'password':
                if (!value) {
                    fields.password.error = 'Please enter your password';
                }
                break;
            default:
        }

        this.setState({fields: fields});
    }

    handleSubmit (e) {
        e.preventDefault();

        var isValid = true;

        // validate that entire form has no more errors
        for (var key in this.state.fields) {
            if (!this.state.fields[key].dirty) {
                this.validate(key);
                isValid = false;
            } else if (this.state.fields[key].error && this.state.fields[key].error !== '') {
                this.validate(key);
                isValid = false;
            }
        }

        // check that form is valid
        if (isValid) {
            var self = this,
                fields = this.state.fields || {},
                data = {
                    email: fields.email.value,
                    password: fields.password.value
                };

            // store user's email address depending on "remember me" option
            if (fields.remember.value && Boolean(fields.remember.value) === true) {
                auth.setCookie(auth.cookieRememberMe, fields.remember.value);
                auth.setCookie(auth.cookieEmail, fields.email.value);
            } else {
                auth.deleteCookie(auth.cookieRememberMe);
                auth.deleteCookie(auth.cookieEmail);
            }

            // api call to submit authenticate user
            UserModel.login(data, function (data) {
                var apiRes = api.handleApiResponse(data);
                if (apiRes.status === 'ok') {
                    // auth.setAccessToken(apiRes.data.access_token);
                    // self.props.setUser(auth.getUserInfo());
                    var currentUser = auth.getUserInfo(apiRes.data.access_token);
                    // window.Intercom("update", {
                    //     app_id: "sp89bz0g",
                    //     user_id: currentUser.user_id,
                    //     name: currentUser.first_name + (currentUser.last_name ? (' ' + currentUser.last_name) : ''),
                    //     email: currentUser.email || '',
                    //     phone: currentUser.mobile_number || '',
                    //     avatar: {
                    //         type: "avatar",
                    //         image_url: currentUser.profile_picture
                    //     }
                    // });
                    // window.Intercom("trackEvent", "Login via Email", {
                    //     "login_date": new Date(),
                    //     "user_id": currentUser.user_id || '',
                    //     "email": currentUser.email || ''
                    // });

                    // self.props.history.push('/tasks');
                    self.redirectToEntityUrl(apiRes.data);
                }
            });
        }
    }

    googleAuthSuccess (response) {
        var self = this;
        var token = response.accessToken || '';
        var profile = response.profileObj ? JSON.stringify(response.profileObj) : '';
        var data = {
            token: token,
            profile: profile,
            is_persistent_loading: true,
            google_auth_data: JSON.stringify(response)
        };
        UserModel.loginGoogle(data, function (data) {
            var apiRes = api.handleApiResponse(data);
            if (apiRes.status === 'ok') {
                // console.log(apiRes.data.access_token);
                // auth.setAccessToken(apiRes.data.access_token);

                // var currentUser = auth.getUserInfo(apiRes.data.access_token);
                // window.Intercom("update", {
                //     app_id: "sp89bz0g",
                //     user_id: currentUser.user_id,
                //     name: currentUser.first_name + (currentUser.last_name ? (' ' + currentUser.last_name) : ''),
                //     email: currentUser.email || '',
                //     phone: currentUser.mobile_number || '',
                //     avatar: {
                //         type: "avatar",
                //         image_url: currentUser.profile_picture
                //     }
                // });
                // window.Intercom("trackEvent", "Login via Google", {
                //     "login_date": new Date(),
                //     "user_id": currentUser.user_id || '',
                //     "email": currentUser.email || ''
                // });

                // self.props.setUser(auth.getUserInfo());

                // self.initUserAuthentication(apiRes.data.access_token);

                // self.props.history.push('/tasks');

                self.redirectToEntityUrl(apiRes.data);
            }
        });
    }

    googleAuthFailure (response) {
        console.log(response);
    }

    render() {
        const { theme } = this.props;
        // const { handleSubmit, pristine, reset, submitting, initialValues } = this.props;

        // var data = {
        //     email: auth.getCookie(auth.cookieEmail) || '',
        //     remember: auth.getCookie(auth.cookieRememberMe) || 0
        // };
        // loadLoginForm(data);
        //TODO: to refactor this to get service provider web url dynamically
        const spWebUrl = config.webappUrl;
        let logoZave = 'https://cdn.getzave.com/assets/logo/zave_favicon_v2.png';

        return general.isEmpty(general.findGetParameter('token')) ? (
            <div>
                <Body mainBodyStyle={{paddingTop: 40}} {...this.props}>

                    <div className="row justify-content-center">
                        <div className="col-6 col-sm-4 mb-3 text-center">
                            <img className="img-fluid" src={logoZave} alt={config.appName} />
                        </div>
                    </div>

                    <div className="card bg-white" style={{boxShadow: 'none'}}>
                        <div className="card-content">

                            {/*<form onSubmit={handleSubmit(this.submit.bind(this))}>*/}
                                {/*<Field*/}
                                    {/*name="email"*/}
                                    {/*type="text"*/}
                                    {/*component={form.ReduxInput}*/}
                                    {/*label="Email"*/}
                                    {/*fullWidth={true}*/}
                                    {/*validate={[required, email]}*/}
                                {/*/>*/}
                                {/*<Field*/}
                                    {/*name="password"*/}
                                    {/*type="password"*/}
                                    {/*component={form.ReduxInput}*/}
                                    {/*label="Password"*/}
                                    {/*fullWidth={true}*/}
                                    {/*validate={[required]}*/}
                                {/*/>*/}
                                {/*<Field*/}
                                    {/*name="remember"*/}
                                    {/*type="checkbox"*/}
                                    {/*component={form.ReduxCheckbox}*/}
                                    {/*label="Remember Me"*/}
                                {/*/>*/}
                                {/*<div>*/}
                                    {/*<RaisedButton type="submit" primary={true} disabled={submitting}*/}
                                                  {/*className="color-primary float-right"> Login </RaisedButton>*/}
                                {/*</div>*/}
                            {/*</form>*/}


                            {/* BEGIN LOGIN FORM */}
                            <form className="form-horizontal" onSubmit={this.handleSubmit.bind(this)}>
                                {/*<h4 className="text-center">Log in to your account</h4>*/}

                                <div className="row justify-content-center">
                                    <div className="col-11 col-sm-11">

                                        <p style={{fontSize: 35, lineHeight: '35px'}}
                                           className="font-weight-light text-center mb-4 mt-4"
                                        >
                                            Sign In
                                        </p>

                                        <div className="row justify-content-center pt-4">
                                            <div className="col-8 col-sm-6 col-md-6">
                                                <GoogleLogin
                                                    clientId={config.googleAuth.clientId}
                                                    onSuccess={this.googleAuthSuccess.bind(this)}
                                                    onFailure={this.googleAuthFailure.bind(this)}
                                                    prompt="select_account"
                                                    tag="span"
                                                    type=""
                                                    style={{padding: 0}}
                                                >
                                                    <img className="img-fluid mb-2" style={{cursor: 'pointer'}}
                                                         src={btnGoogleImage} alt="Google Sign Up Button"/>
                                                </GoogleLogin>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center mt-3 text-center">
                                            <div className="p-2 pt-3"
                                                 style={dividerContainerStyle}><Divider style={dividerStyle}/></div>
                                            <div className="p-2"><span className="text-muted" style={dividerTextStyle}>OR SIGN IN WITH</span></div>
                                            <div className="p-2 pt-3"
                                                 style={dividerContainerStyle}><Divider style={dividerStyle}/></div>
                                        </div>

                                        <form.Input className="placeholder-no-fix"
                                                            type="text"
                                                            floatingLabelText="Email"
                                                            name="email"
                                                            fullWidth={true}
                                                            onChange={this.onChange.bind(this)}
                                                            data={this.state.fields.email}/>

                                        <form.Input className="placeholder-no-fix"
                                                            type="password"
                                                            floatingLabelText="Password"
                                                            name="password"
                                                            fullWidth={true}
                                                            onChange={this.onChange.bind(this)}
                                                            data={this.state.fields.password}/>

                                        {/*<div className="form-actions">*/}
                                            <form.Checkbox
                                                className="rememberme mt-checkbox mt-checkbox-outline"
                                                name="remember"
                                                type="checkbox"
                                                label="Remember me"
                                                onChange={this.onChange.bind(this)}
                                                data={this.state.fields.remember}/>
                                        {/*</div>*/}

                                        {/*<div className="forget-password">*/}
                                            {/*<h4>Forgot your password ?</h4>*/}
                                            {/*<p>No worries, click*/}
                                                {/*<Link to="/forgot-password"> here </Link> to reset your password. </p>*/}
                                        {/*</div>*/}
                                        {/*<div className="create-account">*/}
                                            {/*<h4>Don't have an account yet ?</h4>*/}
                                            {/*<p>Click <Link to={'/signup'}>here </Link> to start a 30 day free trial.</p>*/}
                                            {/*<a href={config.webUrl} className="btn purple"> Back </a>*/}
                                        {/*</div>*/}
                                        <div className="row justify-content-center mt-3">
                                            <div className="col-10 col-sm-6 text-center">
                                                <RaisedButton
                                                    type="submit"
                                                    primary={true}
                                                    fullWidth={true}
                                                    // className="float-right"
                                                    label="Sign In"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12 text-center mt-3">
                                        <Link to="/forgot-password">Forgot password?</Link>
                                    </div>
                                </div>
                            </form>
                            {/* END LOGIN FORM */}

                        </div>
                        {/*<div className="card-action no-border text-right">*/}
                            {/*<RaisedButton primary={true}*/}
                                          {/*onClick={this.handleSubmit.bind(this)}*/}
                                          {/*className="color-primary"> Login </RaisedButton>*/}
                        {/*</div>*/}
                    </div>

                    <div className="text-center">
                        <span style={{fontSize: 13, color: '#666'}}>Don't have an account? <a href={`${config.webUrl}`}>Create one now</a></span>
                        <br/>
                        <span style={{fontSize: 13, color: '#666'}}>Are you a Zave Partner firm? <a href={`${config.webappSpUrl}`}>Sign in to the ZaveHub portal here</a></span>
                    </div>

                    {/*<div className="additional-info" style={{color: theme.palette.accent1Color}}>*/}
                    {/*    <a href={spWebUrl}>Home</a>*/}
                    {/*    /!*<span className="divider-h" />*!/*/}
                    {/*    /!*<Link to="/signup">Sign up</Link>*!/*/}
                    {/*    <span className="divider-h" />*/}
                    {/*    <Link to="/forgot-password">Forgot your password?</Link>*/}
                    {/*</div>*/}

                </Body>
                {/* END LOGIN */}
                <Footer {...this.props}/>
            </div>
        ) : <div></div>
    }

}

// UserLogin.propTypes = {
//     loadLoginForm: PropTypes.func.isRequired,
//     login: PropTypes.func.isRequired
// };
//
// const mapStateToProps = (state, ownProps) => ({
//     initialValues: {email: auth.getCookie(auth.cookieEmail) || '',
//         remember: auth.getCookie(auth.cookieRememberMe) || 0}
// });
//
// const mapDispatchToProps = dispatch => ({
//     loadLoginForm: actions.loadLoginForm,
//     login: (user, callback) => dispatch(actions.login(user, callback))
// });
//
// export default reduxForm({
//     form: 'userLoginForm',
//     enableReinitialize : true,
//     initialValues: {
//         // email: auth.getCookie(auth.cookieEmail) || '',
//         // remember: auth.getCookie(auth.cookieRememberMe) || 0
//     }
// })(connect(
//     mapStateToProps, mapDispatchToProps
// )(UserLogin));

// export default UserLogin;

UserLogin.propTypes = {
    constants: PropTypes.object,
    userPermissions: PropTypes.object,
    theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    constants: state.constants,
    userPermissions: state.permission,
    // loading: state.loading,
    // hasPermission: state.hasPermission,
    // isLoggedIn: state.isLoggedIn,
    // constants: state.constants,
    // userPermissions: state.userPermissions,
    // retrievedPermissions: state.permission.retrievedPermissions,
    // getConfig: state.getConfig
    theme: state.config.theme
});

const mapDispatchToProps = {
    setUser,
    setSubdomain: ConfigActions.setSubdomain,
    setCurrentEntityByEntityId
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserLogin));
