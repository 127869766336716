import {compareString} from './general';

/**
 * @typedef BillingSubscriptionService
 * @property {string} billing_service_description
 * @property {string} billing_service_id
 * @property {string} billing_service_identifier
 * @property {string} billing_service_name
 */
/**
 * Checks whether entity has service enabled
 * @param {{billing_subscription_services?: Array<BillingSubscriptionService>}} currentEntity
 * @param {string} billingServiceIdentifier
 * @returns {boolean}
 */
export function hasServiceEnabled (currentEntity, billingServiceIdentifier) {
	if (
		currentEntity &&
		currentEntity.billing_subscription_services &&
		currentEntity.billing_subscription_services.length > 0 &&
		billingServiceIdentifier
	) {
		for (let i = 0; i < currentEntity.billing_subscription_services.length; i++) {
			const billingSubscriptionService = currentEntity.billing_subscription_services[i];
			if (compareString(billingSubscriptionService.billing_service_identifier, billingServiceIdentifier)) {
				return true;
			}
		}
	}
	return false;
}
