export default function config (state = {}, action) {
    switch (action.type) {
        case 'SET_CONFIG':
            return {
                ...state,
                config: action.config
            };
        case 'SET_SUBDOMAIN':
            return {
                ...state,
                subdomain: action.subdomain
            }
        case 'SET_THEME':
            return {
                ...state,
                theme: action.theme
            };
        case 'SET_COUNTRY_CODE':
            return {
                ...state,
                countryCode: action.countryCode
            };
        case 'START_LOADER':
            return {
                ...state,
                loaderStatus: action.loaderStatus
            }
        case 'STOP_LOADER':
            return {
                ...state,
                loaderStatus: action.loaderStatus
            }
        default:
            return state;
    }
}