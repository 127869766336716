var TYPES = {
    CREDIT_CARD_STRIPE: {
        name: 'Credit Card',
        payment_method_id: 'CREDIT_CARD_STRIPE'
    },
    BANK_WIRE_TRANSFER_GENERIC: {
        name: 'Bank Wire Transfer',
        payment_method_id: 'BANK_WIRE_TRANSFER_GENERIC'
    }
}

//Prepare for export
module.exports = {
    CREDIT_CARD_STRIPE: TYPES.CREDIT_CARD_STRIPE,
    BANK_WIRE_TRANSFER_GENERIC: TYPES.BANK_WIRE_TRANSFER_GENERIC,
    DEFAULT_PAYMENT_METHOD: TYPES.CREDIT_CARD_STRIPE,
    ALL: TYPES,
    getAllPaymentMethodIds: function (){
        var output = {};
        for(var i in TYPES){
            output[i] = TYPES[i].payment_method_id;
        }
        return output;
    }
}