import { general } from '../helpers/index';

export default function form (state = {}, action) {
    const formId = action.formId || '';
    var returnState = { ...state };
    // get existing form from store by id or initialise it
    var currentForm = state[formId] || {};

    switch (action.type) {

        case 'INIT':
            // get new form fields
            var fields = action.fields || {};
            for (var key in fields) {
                var field = fields[key];
                currentForm[field] = currentForm[field] || {};
                currentForm[field].value = field.value || '';
                currentForm[field].error = field.error || '';
                currentForm[field].dirty = field.dirty || false;
            }
            returnState[formId] = currentForm;
            return returnState;

        case 'UPDATE_FIELD':
            const fieldName = action.fieldName || '';
            if (!general.isEmpty(formId) && !general.isEmpty(fieldName)) {
                currentForm[fieldName].value = action.value || '';
                currentForm[fieldName].error = action.error || '';
                currentForm[fieldName].dirty = action.dirty || false;
            }
            returnState[formId] = currentForm;
            return returnState;

        default:
            return state;
    }
}