import $ from 'jquery';
import { config } from '../configs/config';

/**
 * uploads image to server
 * @param data
 * @param callback
 */
export function upload (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/upload',
        dataType: "json",
        data: data,
        error: callback,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000
    }).done(callback);
}

/**
 * uploads bank transaction verification file to server
 * @param data
 * @param callback
 */
export function uploadVerification (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/upload',
        dataType: "json",
        data: data,
        error: callback,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000
    }).done(callback);
}