// import {
//   cyan500, cyan700,
//   green400,
//   grey100, grey300, grey400, grey500,
//   white, darkBlack, fullBlack,
// } from 'material-ui/styles/colors';
import {
    cyan500,
    grey300,
    green500,
    red500,
    blue500,
    yellow500,
    white, darkBlack, fullBlack,
} from 'material-ui/styles/colors';
import {fade} from 'material-ui/utils/colorManipulator';
import spacing from 'material-ui/styles/spacing';

/**
 *  Light Theme is the default theme used in material-ui. It is guaranteed to
 *  have all theme variables needed for every component. Variables not defined
 *  in a custom theme will default to these values.
 */
export default {
    spacing,
    fontFamily: 'objektiv-mk2, sans-serif',
    borderRadius: 2,
    palette: {
        primary1Color: '#0091F4',
        primary2Color: '#344fb2',
        primary3Color: '#f9f9f9',
        primary4Color: '#00cec8',
        accent1Color: '#22304b',
        accent2Color: '#344fb2',
        accent3Color: '#cbbfa2',
        textColor: darkBlack,
        secondaryTextColor: darkBlack,
        alternateTextColor: white,
        canvasColor: white,
        borderColor: grey300,
        disabledColor: fade(darkBlack, 0.3),
        pickerHeaderColor: '#0091F4',
        clockCircleColor: fade(darkBlack, 0.07),
        shadowColor: fullBlack,
        successColor: green500,
        infoColor: blue500,
        dangerColor: red500,
        warningColor: yellow500,
        sidebarBgColor: '#38445d',
        sidebarActiveColor: '#fff',
        sidebarInactiveColor: '#f9f9f9',
        topbarBgColor: '#22304b',
        tabBgColor: '#cbbfa2',
        tabActiveColor: '#22304b',
        tabInactiveColor: '#f9f9f9',
        inactiveColor: '#f9f9f9',
        topMenu: {
            textColor: '#fff',
            bgColor: '#263048'
        },
        sideMenu: {
            textColor: '#555555',
            bgColor: '#fff'
        },
        //** New color theme (9th Oct 2019)
        accent1ColorV2: '#22304B',
        accent2ColorV2: '#556075',
        accent3ColorV2: '#929CB0',
        tabBgColor1: '#22304B',
        tabBgColor2: '#556075',
        tabBgColor3: '#929CB0',
        tabActiveColorV2: '#EAEAEA',
        tabTextColor: '#FBFBFB',
        primary1ColorV2: '#0091f4',
        primary2ColorV2: '#3a445b',
        primary3ColorV2: '#00cec8',
        primary4ColorV2: '#344fb2',
        primary5ColorV2: '#22304b',
        primary6ColorV2: '#ffffff',
        primary7Colorv2: '#E1E1E1'
        // **
    }
};
