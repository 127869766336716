import { h } from '../helpers/index';

export default function company (state = {}, action) {
    switch (action.type) {
        case 'SET_COMPANY':
            return {
                ...state,
                company: action.company
            };
        case 'SET_ENTITIES':
            return {
                ...state,
                entities: action.entities
            }
        // case 'SET_ENTITY_USER':
        //     return {
        //         ...state,
        //         entityUser: action.entityUser
        //     }
        case 'SET_CURRENT_ENTITY_BY_ENTITY_ID': {
            let currentEntity =  action.entities.filter(entity =>
                String(entity.entity_id) === String(action.entityId)
            )[0] || action.entities[0];
            return {
                ...state,
                entities: Object.assign([], action.entities),
                currentEntity,
                currentServiceProvider: action.serviceProvider
            };
        }
        case 'SET_BOOKKEEPERS':
            return {
                ...state,
                bookkeepers: action.bookkeepers
            };
        case 'SET_BANK_ACCOUNTS':
            return {
                ...state,
                bankAccounts: action.accounts
            };
        case 'SET_CONTACTS':
            return {
                ...state,
                contacts: action.contacts
            };
        case 'SET_CURRENT_SERVICE_PROVIDER':
            return {
                ...state,
                currentServiceProvider: action.serviceProvider
            };
        case 'SET_DIRECTOR_KYC':
            action.director = h.notEmpty(action) && h.notEmpty(action.director) ? action.director : {};
            var kyc = h.notEmpty(state) && h.notEmpty(state.kyc) ? state.kyc : {};
            // set entity information
            kyc = setEntity(kyc, action.director);
            // set entity SG information
            kyc = setEntitySG(kyc, action.director);
            // set director information
            kyc.directorSG = kyc.directorSG || {};
            switch (action.countryCode) {
                default:
                case 'SG':
                    kyc.directorSG.director_id = action.director.company_entity_director_sg_director_id || kyc.directorSG.director_id || '';
                    kyc.directorSG.first_name = action.director.company_entity_director_sg_first_name || kyc.directorSG.first_name || '';
                    kyc.directorSG.middle_name = action.director.company_entity_director_sg_middle_name || kyc.directorSG.middle_name || '';
                    kyc.directorSG.last_name = action.director.company_entity_director_sg_last_name || kyc.directorSG.last_name || '';
                    kyc.directorSG.nationality = action.director.company_entity_director_sg_nationality || kyc.directorSG.nationality || '';
                    kyc.directorSG.contact_number = action.director.company_entity_director_sg_contact_number || kyc.directorSG.contact_number || '';
                    action.director.company_entity_director_sg_date_of_birth = h.cmpStr(action.director.company_entity_director_sg_date_of_birth, '0000-00-00') ? '' : action.director.company_entity_director_sg_date_of_birth;
                    kyc.directorSG.date_of_birth = action.director.company_entity_director_sg_date_of_birth || kyc.directorSG.date_of_birth || '';
                    kyc.directorSG.country_of_birth = action.director.company_entity_director_sg_country_of_birth || kyc.directorSG.country_of_birth || '';
                    kyc.directorSG.citizenship_status = action.director.company_entity_director_sg_citizenship_status || kyc.directorSG.citizenship_status || '';
                    kyc.directorSG.passport_photo_page = action.director.company_entity_director_sg_passport_photo_page || kyc.directorSG.passport_photo_page || '';
                    kyc.directorSG.proof_of_address = action.director.company_entity_director_sg_proof_of_address || kyc.directorSG.proof_of_address || '';
                    kyc.directorSG.nric_front = action.director.company_entity_director_sg_nric_front || kyc.directorSG.nric_front || '';
                    kyc.directorSG.nric_back = action.director.company_entity_director_sg_nric_back || kyc.directorSG.nric_back || '';
                    kyc.directorSG.status = action.director.company_entity_director_sg_status || kyc.directorSG.status || '';
                    kyc.directorSG.is_nominee = action.director.company_entity_director_sg_is_nominee || kyc.directorSG.is_nominee || 0;
                    break;
                case 'AU':
                    kyc.directorSG.director_id = action.director.company_entity_director_au_director_id || kyc.directorSG.director_id || '';
                    kyc.directorSG.first_name = action.director.company_entity_director_au_first_name || kyc.directorSG.first_name || '';
                    kyc.directorSG.middle_name = action.director.company_entity_director_au_middle_name || kyc.directorSG.middle_name || '';
                    kyc.directorSG.last_name = action.director.company_entity_director_au_last_name || kyc.directorSG.last_name || '';
                    kyc.directorSG.nationality = action.director.company_entity_director_au_nationality || kyc.directorSG.nationality || '';
                    kyc.directorSG.contact_number = action.director.company_entity_director_au_contact_number || kyc.directorSG.contact_number || '';
                    action.director.company_entity_director_au_date_of_birth = h.cmpStr(action.director.company_entity_director_au_date_of_birth, '0000-00-00') ? '' : action.director.company_entity_director_au_date_of_birth;
                    kyc.directorSG.date_of_birth = action.director.company_entity_director_au_date_of_birth || kyc.directorSG.date_of_birth || '';
                    kyc.directorSG.country_of_birth = action.director.company_entity_director_au_country_of_birth || kyc.directorSG.country_of_birth || '';
                    kyc.directorSG.citizenship_status = action.director.company_entity_director_au_citizenship_status || kyc.directorSG.citizenship_status || '';
                    kyc.directorSG.passport_photo_page = action.director.company_entity_director_au_passport_photo_page || kyc.directorSG.passport_photo_page || '';
                    kyc.directorSG.proof_of_address = action.director.company_entity_director_au_proof_of_address || kyc.directorSG.proof_of_address || '';
                    kyc.directorSG.nric_front = action.director.company_entity_director_au_nric_front || kyc.directorSG.nric_front || '';
                    kyc.directorSG.nric_back = action.director.company_entity_director_au_nric_back || kyc.directorSG.nric_back || '';
                    kyc.directorSG.status = action.director.company_entity_director_au_status || kyc.directorSG.status || '';
                    kyc.directorSG.is_nominee = action.director.company_entity_director_au_is_nominee || kyc.directorSG.is_nominee || 0;
                    break;
                case 'HK':
                    kyc.directorSG.director_id = action.director.company_entity_director_hk_director_id || kyc.directorSG.director_id || '';
                    kyc.directorSG.first_name = action.director.company_entity_director_hk_first_name || kyc.directorSG.first_name || '';
                    kyc.directorSG.middle_name = action.director.company_entity_director_hk_middle_name || kyc.directorSG.middle_name || '';
                    kyc.directorSG.last_name = action.director.company_entity_director_hk_last_name || kyc.directorSG.last_name || '';
                    kyc.directorSG.nationality = action.director.company_entity_director_hk_nationality || kyc.directorSG.nationality || '';
                    kyc.directorSG.contact_number = action.director.company_entity_director_hk_contact_number || kyc.directorSG.contact_number || '';
                    action.director.company_entity_director_hk_date_of_birth = h.cmpStr(action.director.company_entity_director_hk_date_of_birth, '0000-00-00') ? '' : action.director.company_entity_director_hk_date_of_birth;
                    kyc.directorSG.date_of_birth = action.director.company_entity_director_hk_date_of_birth || kyc.directorSG.date_of_birth || '';
                    kyc.directorSG.country_of_birth = action.director.company_entity_director_hk_country_of_birth || kyc.directorSG.country_of_birth || '';
                    kyc.directorSG.citizenship_status = action.director.company_entity_director_hk_citizenship_status || kyc.directorSG.citizenship_status || '';
                    kyc.directorSG.passport_photo_page = action.director.company_entity_director_hk_passport_photo_page || kyc.directorSG.passport_photo_page || '';
                    kyc.directorSG.proof_of_address = action.director.company_entity_director_hk_proof_of_address || kyc.directorSG.proof_of_address || '';
                    kyc.directorSG.nric_front = action.director.company_entity_director_hk_nric_front || kyc.directorSG.nric_front || '';
                    kyc.directorSG.nric_back = action.director.company_entity_director_hk_nric_back || kyc.directorSG.nric_back || '';
                    kyc.directorSG.status = action.director.company_entity_director_hk_status || kyc.directorSG.status || '';
                    kyc.directorSG.is_nominee = action.director.company_entity_director_hk_is_nominee || kyc.directorSG.is_nominee || 0;
                    break;
                case 'VG':
                    kyc.directorSG.director_id = action.director.company_entity_director_vg_director_id || kyc.directorSG.director_id || '';
                    kyc.directorSG.first_name = action.director.company_entity_director_vg_first_name || kyc.directorSG.first_name || '';
                    kyc.directorSG.middle_name = action.director.company_entity_director_vg_middle_name || kyc.directorSG.middle_name || '';
                    kyc.directorSG.last_name = action.director.company_entity_director_vg_last_name || kyc.directorSG.last_name || '';
                    kyc.directorSG.nationality = action.director.company_entity_director_vg_nationality || kyc.directorSG.nationality || '';
                    kyc.directorSG.contact_number = action.director.company_entity_director_vg_contact_number || kyc.directorSG.contact_number || '';
                    action.director.company_entity_director_vg_date_of_birth = h.cmpStr(action.director.company_entity_director_vg_date_of_birth, '0000-00-00') ? '' : action.director.company_entity_director_vg_date_of_birth;
                    kyc.directorSG.date_of_birth = action.director.company_entity_director_vg_date_of_birth || kyc.directorSG.date_of_birth || '';
                    kyc.directorSG.country_of_birth = action.director.company_entity_director_vg_country_of_birth || kyc.directorSG.country_of_birth || '';
                    kyc.directorSG.citizenship_status = action.director.company_entity_director_vg_citizenship_status || kyc.directorSG.citizenship_status || '';
                    kyc.directorSG.passport_photo_page = action.director.company_entity_director_vg_passport_photo_page || kyc.directorSG.passport_photo_page || '';
                    kyc.directorSG.proof_of_address = action.director.company_entity_director_vg_proof_of_address || kyc.directorSG.proof_of_address || '';
                    kyc.directorSG.nric_front = action.director.company_entity_director_vg_nric_front || kyc.directorSG.nric_front || '';
                    kyc.directorSG.nric_back = action.director.company_entity_director_vg_nric_back || kyc.directorSG.nric_back || '';
                    kyc.directorSG.status = action.director.company_entity_director_vg_status || kyc.directorSG.status || '';
                    kyc.directorSG.is_nominee = action.director.company_entity_director_vg_is_nominee || kyc.directorSG.is_nominee || 0;
                    break;
                case '00':
                    kyc.directorSG.director_id = action.director.company_entity_director_00_director_id || kyc.directorSG.director_id || '';
                    kyc.directorSG.first_name = action.director.company_entity_director_00_first_name || kyc.directorSG.first_name || '';
                    kyc.directorSG.middle_name = action.director.company_entity_director_00_middle_name || kyc.directorSG.middle_name || '';
                    kyc.directorSG.last_name = action.director.company_entity_director_00_last_name || kyc.directorSG.last_name || '';
                    kyc.directorSG.nationality = action.director.company_entity_director_00_nationality || kyc.directorSG.nationality || '';
                    kyc.directorSG.contact_number = action.director.company_entity_director_00_contact_number || kyc.directorSG.contact_number || '';
                    action.director.company_entity_director_00_date_of_birth = h.cmpStr(action.director.company_entity_director_00_date_of_birth, '0000-00-00') ? '' : action.director.company_entity_director_00_date_of_birth;
                    kyc.directorSG.date_of_birth = action.director.company_entity_director_00_date_of_birth || kyc.directorSG.date_of_birth || '';
                    kyc.directorSG.country_of_birth = action.director.company_entity_director_00_country_of_birth || kyc.directorSG.country_of_birth || '';
                    kyc.directorSG.citizenship_status = action.director.company_entity_director_00_citizenship_status || kyc.directorSG.citizenship_status || '';
                    kyc.directorSG.passport_photo_page = action.director.company_entity_director_00_passport_photo_page || kyc.directorSG.passport_photo_page || '';
                    kyc.directorSG.proof_of_address = action.director.company_entity_director_00_proof_of_address || kyc.directorSG.proof_of_address || '';
                    kyc.directorSG.nric_front = action.director.company_entity_director_00_nric_front || kyc.directorSG.nric_front || '';
                    kyc.directorSG.nric_back = action.director.company_entity_director_00_nric_back || kyc.directorSG.nric_back || '';
                    kyc.directorSG.status = action.director.company_entity_director_00_status || kyc.directorSG.status || '';
                    kyc.directorSG.is_nominee = action.director.company_entity_director_00_is_nominee || kyc.directorSG.is_nominee || 0;
                    break;
                case '01':
                    kyc.directorSG.director_id = action.director.company_entity_director_01_director_id || kyc.directorSG.director_id || '';
                    kyc.directorSG.first_name = action.director.company_entity_director_01_first_name || kyc.directorSG.first_name || '';
                    kyc.directorSG.middle_name = action.director.company_entity_director_01_middle_name || kyc.directorSG.middle_name || '';
                    kyc.directorSG.last_name = action.director.company_entity_director_01_last_name || kyc.directorSG.last_name || '';
                    kyc.directorSG.nationality = action.director.company_entity_director_01_nationality || kyc.directorSG.nationality || '';
                    kyc.directorSG.contact_number = action.director.company_entity_director_01_contact_number || kyc.directorSG.contact_number || '';
                    action.director.company_entity_director_01_date_of_birth = h.cmpStr(action.director.company_entity_director_01_date_of_birth, '0000-00-00') ? '' : action.director.company_entity_director_01_date_of_birth;
                    kyc.directorSG.date_of_birth = action.director.company_entity_director_01_date_of_birth || kyc.directorSG.date_of_birth || '';
                    kyc.directorSG.country_of_birth = action.director.company_entity_director_01_country_of_birth || kyc.directorSG.country_of_birth || '';
                    kyc.directorSG.citizenship_status = action.director.company_entity_director_01_citizenship_status || kyc.directorSG.citizenship_status || '';
                    kyc.directorSG.passport_photo_page = action.director.company_entity_director_01_passport_photo_page || kyc.directorSG.passport_photo_page || '';
                    kyc.directorSG.proof_of_address = action.director.company_entity_director_01_proof_of_address || kyc.directorSG.proof_of_address || '';
                    kyc.directorSG.nric_front = action.director.company_entity_director_01_nric_front || kyc.directorSG.nric_front || '';
                    kyc.directorSG.nric_back = action.director.company_entity_director_01_nric_back || kyc.directorSG.nric_back || '';
                    kyc.directorSG.status = action.director.company_entity_director_01_status || kyc.directorSG.status || '';
                    kyc.directorSG.is_nominee = action.director.company_entity_director_01_is_nominee || kyc.directorSG.is_nominee || 0;
                    break;
            }
            state.kyc = kyc;
            return { ...state };
        case 'SET_SHAREHOLDER_KYC':
            action.shareholder = h.notEmpty(action) && h.notEmpty(action.shareholder) ? action.shareholder : {};
            var kyc = h.notEmpty(state) && h.notEmpty(state.kyc) ? state.kyc : {};
            // set entity information
            kyc = setEntity(kyc, action.shareholder);
            // set entity SG information
            kyc = setEntitySG(kyc, action.shareholder);
            // set shareholder SG information
            kyc = setShareholder(action.countryCode, kyc, action.shareholder);
            state.kyc = kyc;
            return { ...state };
        case 'SET_DIRECTORS':
            var directors = [];
            action.directors = h.notEmpty(action.directors) ? action.directors : [];
            for (var i = 0; i < action.directors.length; i++) {
                var director = action.directors[i];
                var directorData = {};
                directorData = setEntityDirector(action.countryCode, directorData, director);
                directors.push(directorData);
            }
            return {
                ...state,
                entityDirectors: directors
            };
        case 'SET_SHAREHOLDERS':
            var shareholders = [];
            action.shareholders = h.notEmpty(action.shareholders) ? action.shareholders : [];
            for (var i = 0; i < action.shareholders.length; i++) {
                var shareholder = action.shareholders[i];;
                var shareholderData = {};
                shareholderData = setEntityShareholder(action.countryCode, shareholderData, shareholder);
                shareholders.push(shareholderData);
            }
            return {
                ...state,
                entityShareholders: shareholders
            };
        default:
            return state;
    }
}

/**
 * set entity information
 * @param kyc
 * @param newData
 * @returns {*}
 */
function setEntity (kyc, newData) {
    kyc.entity = kyc.entity || {};
    kyc.entity.company_id = newData.company_fk || kyc.entity.company_id || '';
    kyc.entity.entity_id = newData.entity_id || kyc.entity.entity_id || '';
    kyc.entity.entity_name = newData.entity_name || kyc.entity.entity_name || '';
    kyc.entity.entity_short_name = newData.entity_short_name || kyc.entity.entity_short_name || '';
    kyc.entity.office_number = newData.office_number || kyc.entity.office_number || '';
    kyc.entity.address_1 = newData.address_1 || kyc.entity.address_1 || '';
    kyc.entity.address_2 = newData.address_2 || kyc.entity.address_2 || '';
    kyc.entity.postal_code = newData.postal_code || kyc.entity.postal_code || '';
    kyc.entity.country_code = newData.country_code || kyc.entity.country_code || '';
    kyc.entity.industry = newData.industry || kyc.entity.industry || '';
    kyc.entity.registration_number = newData.registration_number || kyc.entity.registration_number || '';
    kyc.entity.website_url = newData.website_url || kyc.entity.website_url || '';
    kyc.entity.industry = newData.industry || kyc.entity.industry || '';
    kyc.entity.status = newData.entity_status || kyc.entity.status || '';
    return kyc;
}

/**
 * set entity SG information
 * @param kyc
 * @param newData
 * @returns {*}
 */
function setEntitySG (kyc, newData) {
    kyc.entitySG = kyc.entitySG || {};
    kyc.entitySG.eci_exempt = newData.eci_exempt || kyc.entitySG.eci_exempt || 0;
    kyc.entitySG.audit_exempt = newData.audit_exempt || kyc.entitySG.audit_exempt || 0;
    kyc.entitySG.gst_registered = newData.gst_registered || kyc.entitySG.gst_registered || 0;
    kyc.entitySG.mas_rfmc = newData.mas_rfmc || kyc.entitySG.mas_rfmc || 0;
    kyc.entitySG.year_end = newData.year_end || kyc.entitySG.year_end || 0;
    kyc.entitySG.next_agm = newData.next_agm || kyc.entitySG.next_agm || 0;
    kyc.entitySG.next_ar = newData.next_ar || kyc.entitySG.next_ar || 0;
    kyc.entitySG.next_tr = newData.next_tr || kyc.entitySG.next_tr || 0;
    kyc.entitySG.next_eci = newData.next_eci || kyc.entitySG.next_eci || 0;
    kyc.entitySG.incorporated = newData.incorporated || kyc.entitySG.incorporated || 0;
    kyc.entitySG.incorporation_date = newData.incorporation_date || kyc.entitySG.incorporation_date || 0;
    kyc.entitySG.ssic_fk = newData.ssic_fk || kyc.entitySG.ssic_fk || 0;
    kyc.entitySG.share_par_value = newData.share_par_value || kyc.entitySG.share_par_value || 0;
    kyc.entitySG.share_count = newData.share_count || kyc.entitySG.share_count || 0;
    return kyc;
}

/**
 * set entity shareholder information
 * @param countryCode
 * @param kyc
 * @param newData
 * @returns {*}
 */
function setShareholder (countryCode, kyc, newData) {
    switch (countryCode) {
        default:
        case 'SG':
            // generic shareholder information
            kyc.shareholderSG = kyc.shareholderSG || {};
            kyc.shareholderSG.shareholder_id = newData.company_entity_shareholder_sg_shareholder_id || kyc.shareholderSG.shareholder_id || '';
            kyc.shareholderSG.share_count = newData.company_entity_shareholder_sg_share_count || kyc.shareholderSG.share_count || '';
            kyc.shareholderSG.paid_up_capital = newData.company_entity_shareholder_sg_paid_up_capital || kyc.shareholderSG.paid_up_capital || '';
            kyc.shareholderSG.type = newData.company_entity_shareholder_sg_type || kyc.shareholderSG.type || '';
            kyc.shareholderSG.status = newData.company_entity_shareholder_sg_status || kyc.shareholderSG.status || '';
            kyc.shareholderSG.is_nominee = newData.company_entity_shareholder_sg_is_nominee || kyc.shareholderSG.is_nominee || '';
            // individual shareholder information
            kyc.shareholderSG.security_type = newData.company_entity_shareholder_individual_sg_security_type || kyc.shareholderSG.security_type || '';
            kyc.shareholderSG.first_name = newData.company_entity_shareholder_individual_sg_first_name || kyc.shareholderSG.first_name || '';
            kyc.shareholderSG.middle_name = newData.company_entity_shareholder_individual_sg_middle_name || kyc.shareholderSG.middle_name || '';
            kyc.shareholderSG.last_name = newData.company_entity_shareholder_individual_sg_last_name || kyc.shareholderSG.last_name || '';
            kyc.shareholderSG.nationality = newData.company_entity_shareholder_individual_sg_nationality || kyc.shareholderSG.nationality || '';
            kyc.shareholderSG.email = newData.company_entity_shareholder_individual_sg_email || kyc.shareholderSG.email || '';
            kyc.shareholderSG.contact_number = newData.company_entity_shareholder_individual_sg_contact_number || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.date_of_birth = newData.company_entity_shareholder_individual_sg_date_of_birth || kyc.shareholderSG.date_of_birth || '';
            kyc.shareholderSG.country_of_birth = newData.company_entity_shareholder_individual_sg_country_of_birth || kyc.shareholderSG.country_of_birth || '';
            kyc.shareholderSG.occupation = newData.company_entity_shareholder_individual_sg_occupation || kyc.shareholderSG.occupation || '';
            kyc.shareholderSG.citizenship_status = newData.company_entity_shareholder_individual_sg_citizenship_status || kyc.shareholderSG.citizenship_status || '';
            kyc.shareholderSG.passport_photo_page = newData.company_entity_shareholder_individual_sg_passport_photo_page || kyc.shareholderSG.passport_photo_page || '';
            kyc.shareholderSG.proof_of_address = newData.company_entity_shareholder_individual_sg_proof_of_address || kyc.shareholderSG.proof_of_address || '';
            kyc.shareholderSG.nric_front = newData.company_entity_shareholder_individual_sg_nric_front || kyc.shareholderSG.nric_front || '';
            kyc.shareholderSG.nric_back = newData.company_entity_shareholder_individual_sg_nric_back || kyc.shareholderSG.nric_back || '';
            // corporate shareholder information
            kyc.shareholderSG.seniority = newData.company_entity_shareholder_corporate_sg_seniority || kyc.shareholderSG.seniority || '';
            kyc.shareholderSG.company_name = newData.company_entity_shareholder_corporate_sg_company_name || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.country_code = newData.company_entity_shareholder_corporate_sg_country_code || kyc.shareholderSG.country_code || '';
            kyc.shareholderSG.auth_rep_first_name = newData.company_entity_shareholder_corporate_sg_auth_rep_first_name || kyc.shareholderSG.auth_rep_first_name || '';
            kyc.shareholderSG.auth_rep_last_name = newData.company_entity_shareholder_corporate_sg_auth_rep_last_name || kyc.shareholderSG.auth_rep_last_name || '';
            kyc.shareholderSG.auth_rep_email = newData.company_entity_shareholder_corporate_sg_auth_rep_email || kyc.shareholderSG.auth_rep_email || '';
            kyc.shareholderSG.acra_bizfile = newData.company_entity_shareholder_corporate_sg_acra_bizfile || kyc.shareholderSG.acra_bizfile || '';
            kyc.shareholderSG.company_constitution = newData.company_entity_shareholder_corporate_sg_company_constitution || kyc.shareholderSG.company_constitution || '';
            kyc.shareholderSG.certificate_of_incorporation = newData.company_entity_shareholder_corporate_sg_certificate_of_incorporation || kyc.shareholderSG.certificate_of_incorporation || '';
            kyc.shareholderSG.certificate_of_incumbency = newData.company_entity_shareholder_corporate_sg_certificate_of_incumbency || kyc.shareholderSG.certificate_of_incumbency || '';
            kyc.shareholderSG.company_constitution_bylaws = newData.company_entity_shareholder_corporate_sg_company_constitution_bylaws || kyc.shareholderSG.company_constitution_bylaws || '';       
            break;
        case 'AU':
            // generic shareholder information
            kyc.shareholderSG = kyc.shareholderSG || {};
            kyc.shareholderSG.shareholder_id = newData.company_entity_shareholder_au_shareholder_id || kyc.shareholderSG.shareholder_id || '';
            kyc.shareholderSG.share_count = newData.company_entity_shareholder_au_share_count || kyc.shareholderSG.share_count || '';
            kyc.shareholderSG.paid_up_capital = newData.company_entity_shareholder_au_paid_up_capital || kyc.shareholderSG.paid_up_capital || '';
            kyc.shareholderSG.type = newData.company_entity_shareholder_au_type || kyc.shareholderSG.type || '';
            kyc.shareholderSG.status = newData.company_entity_shareholder_au_status || kyc.shareholderSG.status || '';
            kyc.shareholderSG.is_nominee = newData.company_entity_shareholder_au_is_nominee || kyc.shareholderSG.is_nominee || '';
            // individual shareholder information
            kyc.shareholderSG.security_type = newData.company_entity_shareholder_individual_au_security_type || kyc.shareholderSG.security_type || '';
            kyc.shareholderSG.first_name = newData.company_entity_shareholder_individual_au_first_name || kyc.shareholderSG.first_name || '';
            kyc.shareholderSG.middle_name = newData.company_entity_shareholder_individual_au_middle_name || kyc.shareholderSG.middle_name || '';
            kyc.shareholderSG.last_name = newData.company_entity_shareholder_individual_au_last_name || kyc.shareholderSG.last_name || '';
            kyc.shareholderSG.nationality = newData.company_entity_shareholder_individual_au_nationality || kyc.shareholderSG.nationality || '';
            kyc.shareholderSG.email = newData.company_entity_shareholder_individual_au_email || kyc.shareholderSG.email || '';
            kyc.shareholderSG.contact_number = newData.company_entity_shareholder_individual_au_contact_number || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.date_of_birth = newData.company_entity_shareholder_individual_au_date_of_birth || kyc.shareholderSG.date_of_birth || '';
            kyc.shareholderSG.country_of_birth = newData.company_entity_shareholder_individual_au_country_of_birth || kyc.shareholderSG.country_of_birth || '';
            kyc.shareholderSG.occupation = newData.company_entity_shareholder_individual_au_occupation || kyc.shareholderSG.occupation || '';
            kyc.shareholderSG.citizenship_status = newData.company_entity_shareholder_individual_au_citizenship_status || kyc.shareholderSG.citizenship_status || '';
            kyc.shareholderSG.passport_photo_page = newData.company_entity_shareholder_individual_au_passport_photo_page || kyc.shareholderSG.passport_photo_page || '';
            kyc.shareholderSG.proof_of_address = newData.company_entity_shareholder_individual_au_proof_of_address || kyc.shareholderSG.proof_of_address || '';
            kyc.shareholderSG.nric_front = newData.company_entity_shareholder_individual_au_nric_front || kyc.shareholderSG.nric_front || '';
            kyc.shareholderSG.nric_back = newData.company_entity_shareholder_individual_au_nric_back || kyc.shareholderSG.nric_back || '';
            // corporate shareholder information
            kyc.shareholderSG.seniority = newData.company_entity_shareholder_corporate_au_seniority || kyc.shareholderSG.seniority || '';
            kyc.shareholderSG.company_name = newData.company_entity_shareholder_corporate_au_company_name || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.country_code = newData.company_entity_shareholder_corporate_au_country_code || kyc.shareholderSG.country_code || '';
            kyc.shareholderSG.auth_rep_first_name = newData.company_entity_shareholder_corporate_au_auth_rep_first_name || kyc.shareholderSG.auth_rep_first_name || '';
            kyc.shareholderSG.auth_rep_last_name = newData.company_entity_shareholder_corporate_au_auth_rep_last_name || kyc.shareholderSG.auth_rep_last_name || '';
            kyc.shareholderSG.auth_rep_email = newData.company_entity_shareholder_corporate_au_auth_rep_email || kyc.shareholderSG.auth_rep_email || '';
            kyc.shareholderSG.acra_bizfile = newData.company_entity_shareholder_corporate_au_acra_bizfile || kyc.shareholderSG.acra_bizfile || '';
            kyc.shareholderSG.company_constitution = newData.company_entity_shareholder_corporate_au_company_constitution || kyc.shareholderSG.company_constitution || '';
            kyc.shareholderSG.certificate_of_incorporation = newData.company_entity_shareholder_corporate_au_certificate_of_incorporation || kyc.shareholderSG.certificate_of_incorporation || '';
            kyc.shareholderSG.certificate_of_incumbency = newData.company_entity_shareholder_corporate_au_certificate_of_incumbency || kyc.shareholderSG.certificate_of_incumbency || '';
            kyc.shareholderSG.company_constitution_bylaws = newData.company_entity_shareholder_corporate_au_company_constitution_bylaws || kyc.shareholderSG.company_constitution_bylaws || '';
            break;
        case 'HK':
            // generic shareholder information
            kyc.shareholderSG = kyc.shareholderSG || {};
            kyc.shareholderSG.shareholder_id = newData.company_entity_shareholder_hk_shareholder_id || kyc.shareholderSG.shareholder_id || '';
            kyc.shareholderSG.share_count = newData.company_entity_shareholder_hk_share_count || kyc.shareholderSG.share_count || '';
            kyc.shareholderSG.paid_up_capital = newData.company_entity_shareholder_hk_paid_up_capital || kyc.shareholderSG.paid_up_capital || '';
            kyc.shareholderSG.type = newData.company_entity_shareholder_hk_type || kyc.shareholderSG.type || '';
            kyc.shareholderSG.status = newData.company_entity_shareholder_hk_status || kyc.shareholderSG.status || '';
            kyc.shareholderSG.is_nominee = newData.company_entity_shareholder_hk_is_nominee || kyc.shareholderSG.is_nominee || '';
            // individual shareholder information
            kyc.shareholderSG.security_type = newData.company_entity_shareholder_individual_hk_security_type || kyc.shareholderSG.security_type || '';
            kyc.shareholderSG.first_name = newData.company_entity_shareholder_individual_hk_first_name || kyc.shareholderSG.first_name || '';
            kyc.shareholderSG.middle_name = newData.company_entity_shareholder_individual_hk_middle_name || kyc.shareholderSG.middle_name || '';
            kyc.shareholderSG.last_name = newData.company_entity_shareholder_individual_hk_last_name || kyc.shareholderSG.last_name || '';
            kyc.shareholderSG.nationality = newData.company_entity_shareholder_individual_hk_nationality || kyc.shareholderSG.nationality || '';
            kyc.shareholderSG.email = newData.company_entity_shareholder_individual_hk_email || kyc.shareholderSG.email || '';
            kyc.shareholderSG.contact_number = newData.company_entity_shareholder_individual_hk_contact_number || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.date_of_birth = newData.company_entity_shareholder_individual_hk_date_of_birth || kyc.shareholderSG.date_of_birth || '';
            kyc.shareholderSG.country_of_birth = newData.company_entity_shareholder_individual_hk_country_of_birth || kyc.shareholderSG.country_of_birth || '';
            kyc.shareholderSG.occupation = newData.company_entity_shareholder_individual_hk_occupation || kyc.shareholderSG.occupation || '';
            kyc.shareholderSG.citizenship_status = newData.company_entity_shareholder_individual_hk_citizenship_status || kyc.shareholderSG.citizenship_status || '';
            kyc.shareholderSG.passport_photo_page = newData.company_entity_shareholder_individual_hk_passport_photo_page || kyc.shareholderSG.passport_photo_page || '';
            kyc.shareholderSG.proof_of_address = newData.company_entity_shareholder_individual_hk_proof_of_address || kyc.shareholderSG.proof_of_address || '';
            kyc.shareholderSG.nric_front = newData.company_entity_shareholder_individual_hk_nric_front || kyc.shareholderSG.nric_front || '';
            kyc.shareholderSG.nric_back = newData.company_entity_shareholder_individual_hk_nric_back || kyc.shareholderSG.nric_back || '';
            // corporate shareholder information
            kyc.shareholderSG.seniority = newData.company_entity_shareholder_corporate_hk_seniority || kyc.shareholderSG.seniority || '';
            kyc.shareholderSG.company_name = newData.company_entity_shareholder_corporate_hk_company_name || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.country_code = newData.company_entity_shareholder_corporate_hk_country_code || kyc.shareholderSG.country_code || '';
            kyc.shareholderSG.auth_rep_first_name = newData.company_entity_shareholder_corporate_hk_auth_rep_first_name || kyc.shareholderSG.auth_rep_first_name || '';
            kyc.shareholderSG.auth_rep_last_name = newData.company_entity_shareholder_corporate_hk_auth_rep_last_name || kyc.shareholderSG.auth_rep_last_name || '';
            kyc.shareholderSG.auth_rep_email = newData.company_entity_shareholder_corporate_hk_auth_rep_email || kyc.shareholderSG.auth_rep_email || '';
            kyc.shareholderSG.acra_bizfile = newData.company_entity_shareholder_corporate_hk_acra_bizfile || kyc.shareholderSG.acra_bizfile || '';
            kyc.shareholderSG.company_constitution = newData.company_entity_shareholder_corporate_hk_company_constitution || kyc.shareholderSG.company_constitution || '';
            kyc.shareholderSG.certificate_of_incorporation = newData.company_entity_shareholder_corporate_hk_certificate_of_incorporation || kyc.shareholderSG.certificate_of_incorporation || '';
            kyc.shareholderSG.certificate_of_incumbency = newData.company_entity_shareholder_corporate_hk_certificate_of_incumbency || kyc.shareholderSG.certificate_of_incumbency || '';
            kyc.shareholderSG.company_constitution_bylaws = newData.company_entity_shareholder_corporate_hk_company_constitution_bylaws || kyc.shareholderSG.company_constitution_bylaws || '';
            break;
        case 'VG':
            // generic shareholder information
            kyc.shareholderSG = kyc.shareholderSG || {};
            kyc.shareholderSG.shareholder_id = newData.company_entity_shareholder_vg_shareholder_id || kyc.shareholderSG.shareholder_id || '';
            kyc.shareholderSG.share_count = newData.company_entity_shareholder_vg_share_count || kyc.shareholderSG.share_count || '';
            kyc.shareholderSG.paid_up_capital = newData.company_entity_shareholder_vg_paid_up_capital || kyc.shareholderSG.paid_up_capital || '';
            kyc.shareholderSG.type = newData.company_entity_shareholder_vg_type || kyc.shareholderSG.type || '';
            kyc.shareholderSG.status = newData.company_entity_shareholder_vg_status || kyc.shareholderSG.status || '';
            kyc.shareholderSG.is_nominee = newData.company_entity_shareholder_vg_is_nominee || kyc.shareholderSG.is_nominee || '';
            // individual shareholder information
            kyc.shareholderSG.security_type = newData.company_entity_shareholder_individual_vg_security_type || kyc.shareholderSG.security_type || '';
            kyc.shareholderSG.first_name = newData.company_entity_shareholder_individual_vg_first_name || kyc.shareholderSG.first_name || '';
            kyc.shareholderSG.middle_name = newData.company_entity_shareholder_individual_vg_middle_name || kyc.shareholderSG.middle_name || '';
            kyc.shareholderSG.last_name = newData.company_entity_shareholder_individual_vg_last_name || kyc.shareholderSG.last_name || '';
            kyc.shareholderSG.nationality = newData.company_entity_shareholder_individual_vg_nationality || kyc.shareholderSG.nationality || '';
            kyc.shareholderSG.email = newData.company_entity_shareholder_individual_vg_email || kyc.shareholderSG.email || '';
            kyc.shareholderSG.contact_number = newData.company_entity_shareholder_individual_vg_contact_number || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.date_of_birth = newData.company_entity_shareholder_individual_vg_date_of_birth || kyc.shareholderSG.date_of_birth || '';
            kyc.shareholderSG.country_of_birth = newData.company_entity_shareholder_individual_vg_country_of_birth || kyc.shareholderSG.country_of_birth || '';
            kyc.shareholderSG.occupation = newData.company_entity_shareholder_individual_vg_occupation || kyc.shareholderSG.occupation || '';
            kyc.shareholderSG.citizenship_status = newData.company_entity_shareholder_individual_vg_citizenship_status || kyc.shareholderSG.citizenship_status || '';
            kyc.shareholderSG.passport_photo_page = newData.company_entity_shareholder_individual_vg_passport_photo_page || kyc.shareholderSG.passport_photo_page || '';
            kyc.shareholderSG.proof_of_address = newData.company_entity_shareholder_individual_vg_proof_of_address || kyc.shareholderSG.proof_of_address || '';
            kyc.shareholderSG.nric_front = newData.company_entity_shareholder_individual_vg_nric_front || kyc.shareholderSG.nric_front || '';
            kyc.shareholderSG.nric_back = newData.company_entity_shareholder_individual_vg_nric_back || kyc.shareholderSG.nric_back || '';
            // corporate shareholder information
            kyc.shareholderSG.seniority = newData.company_entity_shareholder_corporate_vg_seniority || kyc.shareholderSG.seniority || '';
            kyc.shareholderSG.company_name = newData.company_entity_shareholder_corporate_vg_company_name || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.country_code = newData.company_entity_shareholder_corporate_vg_country_code || kyc.shareholderSG.country_code || '';
            kyc.shareholderSG.auth_rep_first_name = newData.company_entity_shareholder_corporate_vg_auth_rep_first_name || kyc.shareholderSG.auth_rep_first_name || '';
            kyc.shareholderSG.auth_rep_last_name = newData.company_entity_shareholder_corporate_vg_auth_rep_last_name || kyc.shareholderSG.auth_rep_last_name || '';
            kyc.shareholderSG.auth_rep_email = newData.company_entity_shareholder_corporate_vg_auth_rep_email || kyc.shareholderSG.auth_rep_email || '';
            kyc.shareholderSG.acra_bizfile = newData.company_entity_shareholder_corporate_vg_acra_bizfile || kyc.shareholderSG.acra_bizfile || '';
            kyc.shareholderSG.company_constitution = newData.company_entity_shareholder_corporate_vg_company_constitution || kyc.shareholderSG.company_constitution || '';
            kyc.shareholderSG.certificate_of_incorporation = newData.company_entity_shareholder_corporate_vg_certificate_of_incorporation || kyc.shareholderSG.certificate_of_incorporation || '';
            kyc.shareholderSG.certificate_of_incumbency = newData.company_entity_shareholder_corporate_vg_certificate_of_incumbency || kyc.shareholderSG.certificate_of_incumbency || '';
            kyc.shareholderSG.company_constitution_bylaws = newData.company_entity_shareholder_corporate_vg_company_constitution_bylaws || kyc.shareholderSG.company_constitution_bylaws || '';
            break;
        case '00':
            // generic shareholder information
            kyc.shareholderSG = kyc.shareholderSG || {};
            kyc.shareholderSG.shareholder_id = newData.company_entity_shareholder_00_shareholder_id || kyc.shareholderSG.shareholder_id || '';
            kyc.shareholderSG.share_count = newData.company_entity_shareholder_00_share_count || kyc.shareholderSG.share_count || '';
            kyc.shareholderSG.paid_up_capital = newData.company_entity_shareholder_00_paid_up_capital || kyc.shareholderSG.paid_up_capital || '';
            kyc.shareholderSG.type = newData.company_entity_shareholder_00_type || kyc.shareholderSG.type || '';
            kyc.shareholderSG.status = newData.company_entity_shareholder_00_status || kyc.shareholderSG.status || '';
            kyc.shareholderSG.is_nominee = newData.company_entity_shareholder_00_is_nominee || kyc.shareholderSG.is_nominee || '';
            // individual shareholder information
            kyc.shareholderSG.security_type = newData.company_entity_shareholder_individual_00_security_type || kyc.shareholderSG.security_type || '';
            kyc.shareholderSG.first_name = newData.company_entity_shareholder_individual_00_first_name || kyc.shareholderSG.first_name || '';
            kyc.shareholderSG.middle_name = newData.company_entity_shareholder_individual_00_middle_name || kyc.shareholderSG.middle_name || '';
            kyc.shareholderSG.last_name = newData.company_entity_shareholder_individual_00_last_name || kyc.shareholderSG.last_name || '';
            kyc.shareholderSG.nationality = newData.company_entity_shareholder_individual_00_nationality || kyc.shareholderSG.nationality || '';
            kyc.shareholderSG.email = newData.company_entity_shareholder_individual_00_email || kyc.shareholderSG.email || '';
            kyc.shareholderSG.contact_number = newData.company_entity_shareholder_individual_00_contact_number || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.date_of_birth = newData.company_entity_shareholder_individual_00_date_of_birth || kyc.shareholderSG.date_of_birth || '';
            kyc.shareholderSG.country_of_birth = newData.company_entity_shareholder_individual_00_country_of_birth || kyc.shareholderSG.country_of_birth || '';
            kyc.shareholderSG.occupation = newData.company_entity_shareholder_individual_00_occupation || kyc.shareholderSG.occupation || '';
            kyc.shareholderSG.citizenship_status = newData.company_entity_shareholder_individual_00_citizenship_status || kyc.shareholderSG.citizenship_status || '';
            kyc.shareholderSG.passport_photo_page = newData.company_entity_shareholder_individual_00_passport_photo_page || kyc.shareholderSG.passport_photo_page || '';
            kyc.shareholderSG.proof_of_address = newData.company_entity_shareholder_individual_00_proof_of_address || kyc.shareholderSG.proof_of_address || '';
            kyc.shareholderSG.nric_front = newData.company_entity_shareholder_individual_00_nric_front || kyc.shareholderSG.nric_front || '';
            kyc.shareholderSG.nric_back = newData.company_entity_shareholder_individual_00_nric_back || kyc.shareholderSG.nric_back || '';
            // corporate shareholder information
            kyc.shareholderSG.seniority = newData.company_entity_shareholder_corporate_00_seniority || kyc.shareholderSG.seniority || '';
            kyc.shareholderSG.company_name = newData.company_entity_shareholder_corporate_00_company_name || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.country_code = newData.company_entity_shareholder_corporate_00_country_code || kyc.shareholderSG.country_code || '';
            kyc.shareholderSG.auth_rep_first_name = newData.company_entity_shareholder_corporate_00_auth_rep_first_name || kyc.shareholderSG.auth_rep_first_name || '';
            kyc.shareholderSG.auth_rep_last_name = newData.company_entity_shareholder_corporate_00_auth_rep_last_name || kyc.shareholderSG.auth_rep_last_name || '';
            kyc.shareholderSG.auth_rep_email = newData.company_entity_shareholder_corporate_00_auth_rep_email || kyc.shareholderSG.auth_rep_email || '';
            kyc.shareholderSG.acra_bizfile = newData.company_entity_shareholder_corporate_00_acra_bizfile || kyc.shareholderSG.acra_bizfile || '';
            kyc.shareholderSG.company_constitution = newData.company_entity_shareholder_corporate_00_company_constitution || kyc.shareholderSG.company_constitution || '';
            kyc.shareholderSG.certificate_of_incorporation = newData.company_entity_shareholder_corporate_00_certificate_of_incorporation || kyc.shareholderSG.certificate_of_incorporation || '';
            kyc.shareholderSG.certificate_of_incumbency = newData.company_entity_shareholder_corporate_00_certificate_of_incumbency || kyc.shareholderSG.certificate_of_incumbency || '';
            kyc.shareholderSG.company_constitution_bylaws = newData.company_entity_shareholder_corporate_00_company_constitution_bylaws || kyc.shareholderSG.company_constitution_bylaws || '';
            break;
        case '01':
            // generic shareholder information
            kyc.shareholderSG = kyc.shareholderSG || {};
            kyc.shareholderSG.shareholder_id = newData.company_entity_shareholder_01_shareholder_id || kyc.shareholderSG.shareholder_id || '';
            kyc.shareholderSG.share_count = newData.company_entity_shareholder_01_share_count || kyc.shareholderSG.share_count || '';
            kyc.shareholderSG.paid_up_capital = newData.company_entity_shareholder_01_paid_up_capital || kyc.shareholderSG.paid_up_capital || '';
            kyc.shareholderSG.type = newData.company_entity_shareholder_01_type || kyc.shareholderSG.type || '';
            kyc.shareholderSG.status = newData.company_entity_shareholder_01_status || kyc.shareholderSG.status || '';
            kyc.shareholderSG.is_nominee = newData.company_entity_shareholder_01_is_nominee || kyc.shareholderSG.is_nominee || '';
            // individual shareholder information
            kyc.shareholderSG.security_type = newData.company_entity_shareholder_individual_01_security_type || kyc.shareholderSG.security_type || '';
            kyc.shareholderSG.first_name = newData.company_entity_shareholder_individual_01_first_name || kyc.shareholderSG.first_name || '';
            kyc.shareholderSG.middle_name = newData.company_entity_shareholder_individual_01_middle_name || kyc.shareholderSG.middle_name || '';
            kyc.shareholderSG.last_name = newData.company_entity_shareholder_individual_01_last_name || kyc.shareholderSG.last_name || '';
            kyc.shareholderSG.nationality = newData.company_entity_shareholder_individual_01_nationality || kyc.shareholderSG.nationality || '';
            kyc.shareholderSG.email = newData.company_entity_shareholder_individual_01_email || kyc.shareholderSG.email || '';
            kyc.shareholderSG.contact_number = newData.company_entity_shareholder_individual_01_contact_number || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.date_of_birth = newData.company_entity_shareholder_individual_01_date_of_birth || kyc.shareholderSG.date_of_birth || '';
            kyc.shareholderSG.country_of_birth = newData.company_entity_shareholder_individual_01_country_of_birth || kyc.shareholderSG.country_of_birth || '';
            kyc.shareholderSG.occupation = newData.company_entity_shareholder_individual_01_occupation || kyc.shareholderSG.occupation || '';
            kyc.shareholderSG.citizenship_status = newData.company_entity_shareholder_individual_01_citizenship_status || kyc.shareholderSG.citizenship_status || '';
            kyc.shareholderSG.passport_photo_page = newData.company_entity_shareholder_individual_01_passport_photo_page || kyc.shareholderSG.passport_photo_page || '';
            kyc.shareholderSG.proof_of_address = newData.company_entity_shareholder_individual_01_proof_of_address || kyc.shareholderSG.proof_of_address || '';
            kyc.shareholderSG.nric_front = newData.company_entity_shareholder_individual_01_nric_front || kyc.shareholderSG.nric_front || '';
            kyc.shareholderSG.nric_back = newData.company_entity_shareholder_individual_01_nric_back || kyc.shareholderSG.nric_back || '';
            // corporate shareholder information
            kyc.shareholderSG.seniority = newData.company_entity_shareholder_corporate_01_seniority || kyc.shareholderSG.seniority || '';
            kyc.shareholderSG.company_name = newData.company_entity_shareholder_corporate_01_company_name || kyc.shareholderSG.contact_number || '';
            kyc.shareholderSG.country_code = newData.company_entity_shareholder_corporate_01_country_code || kyc.shareholderSG.country_code || '';
            kyc.shareholderSG.auth_rep_first_name = newData.company_entity_shareholder_corporate_01_auth_rep_first_name || kyc.shareholderSG.auth_rep_first_name || '';
            kyc.shareholderSG.auth_rep_last_name = newData.company_entity_shareholder_corporate_01_auth_rep_last_name || kyc.shareholderSG.auth_rep_last_name || '';
            kyc.shareholderSG.auth_rep_email = newData.company_entity_shareholder_corporate_01_auth_rep_email || kyc.shareholderSG.auth_rep_email || '';
            kyc.shareholderSG.acra_bizfile = newData.company_entity_shareholder_corporate_01_acra_bizfile || kyc.shareholderSG.acra_bizfile || '';
            kyc.shareholderSG.company_constitution = newData.company_entity_shareholder_corporate_01_company_constitution || kyc.shareholderSG.company_constitution || '';
            kyc.shareholderSG.certificate_of_incorporation = newData.company_entity_shareholder_corporate_01_certificate_of_incorporation || kyc.shareholderSG.certificate_of_incorporation || '';
            kyc.shareholderSG.certificate_of_incumbency = newData.company_entity_shareholder_corporate_01_certificate_of_incumbency || kyc.shareholderSG.certificate_of_incumbency || '';
            kyc.shareholderSG.company_constitution_bylaws = newData.company_entity_shareholder_corporate_01_company_constitution_bylaws || kyc.shareholderSG.company_constitution_bylaws || '';
            break;
    }
    return kyc;
}

/**
 * set entity director
 * @param countryCode
 * @param returnData
 * @param director
 * @returns {*}
 */
function setEntityDirector (countryCode, returnData, director) {
    returnData = returnData || {};
    switch (countryCode) {
        default:
        case 'SG':
            returnData.director_id = director.company_entity_director_sg_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_sg_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_sg_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_sg_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_sg_nationality || returnData.nationality || '';
            returnData.email = director.company_entity_director_sg_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_sg_contact_number || returnData.contact_number || '';
            director.company_entity_director_sg_date_of_birth = h.cmpStr(director.company_entity_director_sg_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_sg_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_sg_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_sg_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = director.company_entity_director_sg_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_sg_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_sg_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_sg_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_sg_nric_back || returnData.nric_back || '';
            returnData.status = director.company_entity_director_sg_status || returnData.status || '';
            returnData.is_nominee = director.company_entity_director_sg_is_nominee || returnData.is_nominee || 0;
            break;
        case 'AU':
            returnData.director_id = director.company_entity_director_au_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_au_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_au_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_au_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_au_nationality || returnData.nationality || '';
            returnData.email = director.company_entity_director_au_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_au_contact_number || returnData.contact_number || '';
            director.company_entity_director_au_date_of_birth = h.cmpStr(director.company_entity_director_au_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_au_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_au_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_au_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = director.company_entity_director_au_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_au_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_au_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_au_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_au_nric_back || returnData.nric_back || '';
            returnData.status = director.company_entity_director_au_status || returnData.status || '';
            returnData.is_nominee = director.company_entity_director_au_is_nominee || returnData.is_nominee || 0;
            break;
        case 'HK':
            returnData.director_id = director.company_entity_director_hk_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_hk_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_hk_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_hk_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_hk_nationality || returnData.nationality || '';
            returnData.email = director.company_entity_director_hk_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_hk_contact_number || returnData.contact_number || '';
            director.company_entity_director_hk_date_of_birth = h.cmpStr(director.company_entity_director_hk_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_hk_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_hk_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_hk_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = director.company_entity_director_hk_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_hk_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_hk_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_hk_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_hk_nric_back || returnData.nric_back || '';
            returnData.status = director.company_entity_director_hk_status || returnData.status || '';
            returnData.is_nominee = director.company_entity_director_hk_is_nominee || returnData.is_nominee || 0;
            break;
        case 'VG':
            returnData.director_id = director.company_entity_director_vg_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_vg_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_vg_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_vg_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_vg_nationality || returnData.nationality || '';
            returnData.email = director.company_entity_director_vg_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_vg_contact_number || returnData.contact_number || '';
            director.company_entity_director_vg_date_of_birth = h.cmpStr(director.company_entity_director_vg_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_vg_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_vg_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_vg_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = director.company_entity_director_vg_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_vg_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_vg_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_vg_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_vg_nric_back || returnData.nric_back || '';
            returnData.status = director.company_entity_director_vg_status || returnData.status || '';
            returnData.is_nominee = director.company_entity_director_vg_is_nominee || returnData.is_nominee || 0;
            break;
        case '00':
            returnData.director_id = director.company_entity_director_00_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_00_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_00_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_00_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_00_nationality || returnData.nationality || '';
            returnData.email = director.company_entity_director_00_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_00_contact_number || returnData.contact_number || '';
            director.company_entity_director_00_date_of_birth = h.cmpStr(director.company_entity_director_00_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_00_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_00_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_00_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = director.company_entity_director_00_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_00_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_00_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_00_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_00_nric_back || returnData.nric_back || '';
            returnData.status = director.company_entity_director_00_status || returnData.status || '';
            returnData.is_nominee = director.company_entity_director_00_is_nominee || returnData.is_nominee || 0;
            break;
        case '01':
            returnData.director_id = director.company_entity_director_01_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_01_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_01_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_01_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_01_nationality || returnData.nationality || '';
            returnData.email = director.company_entity_director_01_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_01_contact_number || returnData.contact_number || '';
            director.company_entity_director_01_date_of_birth = h.cmpStr(director.company_entity_director_01_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_01_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_01_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_01_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = director.company_entity_director_01_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_01_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_01_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_01_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_01_nric_back || returnData.nric_back || '';
            returnData.status = director.company_entity_director_01_status || returnData.status || '';
            returnData.is_nominee = director.company_entity_director_01_is_nominee || returnData.is_nominee || 0;
            break;
    }
    return returnData;
}

/**
 * set entity shareholder
 * @param countryCode
 * @param returnData
 * @param shareholder
 * @returns {*}
 */
function setEntityShareholder (countryCode, returnData, shareholder) {
    returnData = returnData || {};
    switch (countryCode) {
        default:
        case 'SG':
            // generic shareholder information
            returnData.shareholder_id = shareholder.company_entity_shareholder_sg_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_sg_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_sg_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_sg_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_sg_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_sg_is_nominee || returnData.is_nominee || 0;
            // individual shareholder information
            returnData.security_type = shareholder.company_entity_shareholder_individual_sg_security_type || returnData.security_type || '';
            returnData.first_name = shareholder.company_entity_shareholder_individual_sg_first_name || returnData.first_name || '';
            returnData.middle_name = shareholder.company_entity_shareholder_individual_sg_middle_name || returnData.middle_name || '';
            returnData.last_name = shareholder.company_entity_shareholder_individual_sg_last_name || returnData.last_name || '';
            returnData.nationality = shareholder.company_entity_shareholder_individual_sg_nationality || returnData.nationality || '';
            returnData.email = shareholder.company_entity_shareholder_individual_sg_email || returnData.email || '';
            returnData.contact_number = shareholder.company_entity_shareholder_individual_sg_contact_number || returnData.contact_number || '';
            shareholder.company_entity_shareholder_individual_sg_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_sg_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_sg_date_of_birth;
            returnData.date_of_birth = shareholder.company_entity_shareholder_individual_sg_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = shareholder.company_entity_shareholder_individual_sg_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = shareholder.company_entity_shareholder_individual_sg_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_sg_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = shareholder.company_entity_shareholder_individual_sg_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = shareholder.company_entity_shareholder_individual_sg_nric_front || returnData.nric_front || '';
            returnData.nric_back = shareholder.company_entity_shareholder_individual_sg_nric_back || returnData.nric_back || '';
            // corporate shareholder information
            returnData.seniority = shareholder.company_entity_shareholder_corporate_sg_seniority || returnData.seniority || '';
            returnData.company_name = shareholder.company_entity_shareholder_corporate_sg_company_name || returnData.contact_number || '';
            returnData.country_code = shareholder.company_entity_shareholder_corporate_sg_country_code || returnData.country_code || '';
            returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_sg_auth_rep_first_name || returnData.auth_rep_first_name || '';
            returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_sg_auth_rep_last_name || returnData.auth_rep_last_name || '';
            returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_sg_auth_rep_email || returnData.auth_rep_email || '';
            returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_sg_acra_bizfile || returnData.acra_bizfile || '';
            returnData.company_constitution = shareholder.company_entity_shareholder_corporate_sg_company_constitution || returnData.company_constitution || '';
            returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_sg_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
            returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_sg_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
            returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_sg_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
            break;
        case 'AU':
            // generic shareholder information
            returnData.shareholder_id = shareholder.company_entity_shareholder_au_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_au_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_au_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_au_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_au_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_au_is_nominee || returnData.is_nominee || 0;
            // individual shareholder information
            returnData.security_type = shareholder.company_entity_shareholder_individual_au_security_type || returnData.security_type || '';
            returnData.first_name = shareholder.company_entity_shareholder_individual_au_first_name || returnData.first_name || '';
            returnData.middle_name = shareholder.company_entity_shareholder_individual_au_middle_name || returnData.middle_name || '';
            returnData.last_name = shareholder.company_entity_shareholder_individual_au_last_name || returnData.last_name || '';
            returnData.nationality = shareholder.company_entity_shareholder_individual_au_nationality || returnData.nationality || '';
            returnData.email = shareholder.company_entity_shareholder_individual_au_email || returnData.email || '';
            returnData.contact_number = shareholder.company_entity_shareholder_individual_au_contact_number || returnData.contact_number || '';
            shareholder.company_entity_shareholder_individual_au_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_au_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_au_date_of_birth;
            returnData.date_of_birth = shareholder.company_entity_shareholder_individual_au_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = shareholder.company_entity_shareholder_individual_au_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = shareholder.company_entity_shareholder_individual_au_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_au_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = shareholder.company_entity_shareholder_individual_au_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = shareholder.company_entity_shareholder_individual_au_nric_front || returnData.nric_front || '';
            returnData.nric_back = shareholder.company_entity_shareholder_individual_au_nric_back || returnData.nric_back || '';
            // corporate shareholder information
            returnData.seniority = shareholder.company_entity_shareholder_corporate_au_seniority || returnData.seniority || '';
            returnData.company_name = shareholder.company_entity_shareholder_corporate_au_company_name || returnData.contact_number || '';
            returnData.country_code = shareholder.company_entity_shareholder_corporate_au_country_code || returnData.country_code || '';
            returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_au_auth_rep_first_name || returnData.auth_rep_first_name || '';
            returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_au_auth_rep_last_name || returnData.auth_rep_last_name || '';
            returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_au_auth_rep_email || returnData.auth_rep_email || '';
            returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_au_acra_bizfile || returnData.acra_bizfile || '';
            returnData.company_constitution = shareholder.company_entity_shareholder_corporate_au_company_constitution || returnData.company_constitution || '';
            returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_au_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
            returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_au_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
            returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_au_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
            break;
        case 'HK':
            // generic shareholder information
            returnData.shareholder_id = shareholder.company_entity_shareholder_hk_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_hk_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_hk_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_hk_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_hk_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_hk_is_nominee || returnData.is_nominee || 0;
            // individual shareholder information
            returnData.security_type = shareholder.company_entity_shareholder_individual_hk_security_type || returnData.security_type || '';
            returnData.first_name = shareholder.company_entity_shareholder_individual_hk_first_name || returnData.first_name || '';
            returnData.middle_name = shareholder.company_entity_shareholder_individual_hk_middle_name || returnData.middle_name || '';
            returnData.last_name = shareholder.company_entity_shareholder_individual_hk_last_name || returnData.last_name || '';
            returnData.nationality = shareholder.company_entity_shareholder_individual_hk_nationality || returnData.nationality || '';
            returnData.email = shareholder.company_entity_shareholder_individual_hk_email || returnData.email || '';
            returnData.contact_number = shareholder.company_entity_shareholder_individual_hk_contact_number || returnData.contact_number || '';
            shareholder.company_entity_shareholder_individual_hk_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_hk_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_hk_date_of_birth;
            returnData.date_of_birth = shareholder.company_entity_shareholder_individual_hk_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = shareholder.company_entity_shareholder_individual_hk_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = shareholder.company_entity_shareholder_individual_hk_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_hk_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = shareholder.company_entity_shareholder_individual_hk_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = shareholder.company_entity_shareholder_individual_hk_nric_front || returnData.nric_front || '';
            returnData.nric_back = shareholder.company_entity_shareholder_individual_hk_nric_back || returnData.nric_back || '';
            // corporate shareholder information
            returnData.seniority = shareholder.company_entity_shareholder_corporate_hk_seniority || returnData.seniority || '';
            returnData.company_name = shareholder.company_entity_shareholder_corporate_hk_company_name || returnData.contact_number || '';
            returnData.country_code = shareholder.company_entity_shareholder_corporate_hk_country_code || returnData.country_code || '';
            returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_hk_auth_rep_first_name || returnData.auth_rep_first_name || '';
            returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_hk_auth_rep_last_name || returnData.auth_rep_last_name || '';
            returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_hk_auth_rep_email || returnData.auth_rep_email || '';
            returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_hk_acra_bizfile || returnData.acra_bizfile || '';
            returnData.company_constitution = shareholder.company_entity_shareholder_corporate_hk_company_constitution || returnData.company_constitution || '';
            returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_hk_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
            returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_hk_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
            returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_hk_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
            break;
        case 'VG':
            // generic shareholder information
            returnData.shareholder_id = shareholder.company_entity_shareholder_vg_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_vg_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_vg_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_vg_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_vg_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_vg_is_nominee || returnData.is_nominee || 0;
            // individual shareholder information
            returnData.security_type = shareholder.company_entity_shareholder_individual_vg_security_type || returnData.security_type || '';
            returnData.first_name = shareholder.company_entity_shareholder_individual_vg_first_name || returnData.first_name || '';
            returnData.middle_name = shareholder.company_entity_shareholder_individual_vg_middle_name || returnData.middle_name || '';
            returnData.last_name = shareholder.company_entity_shareholder_individual_vg_last_name || returnData.last_name || '';
            returnData.nationality = shareholder.company_entity_shareholder_individual_vg_nationality || returnData.nationality || '';
            returnData.email = shareholder.company_entity_shareholder_individual_vg_email || returnData.email || '';
            returnData.contact_number = shareholder.company_entity_shareholder_individual_vg_contact_number || returnData.contact_number || '';
            shareholder.company_entity_shareholder_individual_vg_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_vg_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_vg_date_of_birth;
            returnData.date_of_birth = shareholder.company_entity_shareholder_individual_vg_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = shareholder.company_entity_shareholder_individual_vg_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = shareholder.company_entity_shareholder_individual_vg_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_vg_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = shareholder.company_entity_shareholder_individual_vg_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = shareholder.company_entity_shareholder_individual_vg_nric_front || returnData.nric_front || '';
            returnData.nric_back = shareholder.company_entity_shareholder_individual_vg_nric_back || returnData.nric_back || '';
            // corporate shareholder information
            returnData.seniority = shareholder.company_entity_shareholder_corporate_vg_seniority || returnData.seniority || '';
            returnData.company_name = shareholder.company_entity_shareholder_corporate_vg_company_name || returnData.contact_number || '';
            returnData.country_code = shareholder.company_entity_shareholder_corporate_vg_country_code || returnData.country_code || '';
            returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_vg_auth_rep_first_name || returnData.auth_rep_first_name || '';
            returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_vg_auth_rep_last_name || returnData.auth_rep_last_name || '';
            returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_vg_auth_rep_email || returnData.auth_rep_email || '';
            returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_vg_acra_bizfile || returnData.acra_bizfile || '';
            returnData.company_constitution = shareholder.company_entity_shareholder_corporate_vg_company_constitution || returnData.company_constitution || '';
            returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_vg_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
            returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_vg_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
            returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_vg_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
            break;
        case '00':
            // generic shareholder information
            returnData.shareholder_id = shareholder.company_entity_shareholder_00_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_00_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_00_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_00_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_00_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_00_is_nominee || returnData.is_nominee || 0;
            // individual shareholder information
            returnData.security_type = shareholder.company_entity_shareholder_individual_00_security_type || returnData.security_type || '';
            returnData.first_name = shareholder.company_entity_shareholder_individual_00_first_name || returnData.first_name || '';
            returnData.middle_name = shareholder.company_entity_shareholder_individual_00_middle_name || returnData.middle_name || '';
            returnData.last_name = shareholder.company_entity_shareholder_individual_00_last_name || returnData.last_name || '';
            returnData.nationality = shareholder.company_entity_shareholder_individual_00_nationality || returnData.nationality || '';
            returnData.email = shareholder.company_entity_shareholder_individual_00_email || returnData.email || '';
            returnData.contact_number = shareholder.company_entity_shareholder_individual_00_contact_number || returnData.contact_number || '';
            shareholder.company_entity_shareholder_individual_00_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_00_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_00_date_of_birth;
            returnData.date_of_birth = shareholder.company_entity_shareholder_individual_00_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = shareholder.company_entity_shareholder_individual_00_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = shareholder.company_entity_shareholder_individual_00_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_00_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = shareholder.company_entity_shareholder_individual_00_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = shareholder.company_entity_shareholder_individual_00_nric_front || returnData.nric_front || '';
            returnData.nric_back = shareholder.company_entity_shareholder_individual_00_nric_back || returnData.nric_back || '';
            // corporate shareholder information
            returnData.seniority = shareholder.company_entity_shareholder_corporate_00_seniority || returnData.seniority || '';
            returnData.company_name = shareholder.company_entity_shareholder_corporate_00_company_name || returnData.contact_number || '';
            returnData.country_code = shareholder.company_entity_shareholder_corporate_00_country_code || returnData.country_code || '';
            returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_00_auth_rep_first_name || returnData.auth_rep_first_name || '';
            returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_00_auth_rep_last_name || returnData.auth_rep_last_name || '';
            returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_00_auth_rep_email || returnData.auth_rep_email || '';
            returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_00_acra_bizfile || returnData.acra_bizfile || '';
            returnData.company_constitution = shareholder.company_entity_shareholder_corporate_00_company_constitution || returnData.company_constitution || '';
            returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_00_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
            returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_00_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
            returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_00_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
            break;
        case '01':
            // generic shareholder information
            returnData.shareholder_id = shareholder.company_entity_shareholder_01_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_01_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_01_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_01_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_01_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_01_is_nominee || returnData.is_nominee || 0;
            // individual shareholder information
            returnData.security_type = shareholder.company_entity_shareholder_individual_01_security_type || returnData.security_type || '';
            returnData.first_name = shareholder.company_entity_shareholder_individual_01_first_name || returnData.first_name || '';
            returnData.middle_name = shareholder.company_entity_shareholder_individual_01_middle_name || returnData.middle_name || '';
            returnData.last_name = shareholder.company_entity_shareholder_individual_01_last_name || returnData.last_name || '';
            returnData.nationality = shareholder.company_entity_shareholder_individual_01_nationality || returnData.nationality || '';
            returnData.email = shareholder.company_entity_shareholder_individual_01_email || returnData.email || '';
            returnData.contact_number = shareholder.company_entity_shareholder_individual_01_contact_number || returnData.contact_number || '';
            shareholder.company_entity_shareholder_individual_01_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_01_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_01_date_of_birth;
            returnData.date_of_birth = shareholder.company_entity_shareholder_individual_01_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = shareholder.company_entity_shareholder_individual_01_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = shareholder.company_entity_shareholder_individual_01_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_01_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = shareholder.company_entity_shareholder_individual_01_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = shareholder.company_entity_shareholder_individual_01_nric_front || returnData.nric_front || '';
            returnData.nric_back = shareholder.company_entity_shareholder_individual_01_nric_back || returnData.nric_back || '';
            // corporate shareholder information
            returnData.seniority = shareholder.company_entity_shareholder_corporate_01_seniority || returnData.seniority || '';
            returnData.company_name = shareholder.company_entity_shareholder_corporate_01_company_name || returnData.contact_number || '';
            returnData.country_code = shareholder.company_entity_shareholder_corporate_01_country_code || returnData.country_code || '';
            returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_01_auth_rep_first_name || returnData.auth_rep_first_name || '';
            returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_01_auth_rep_last_name || returnData.auth_rep_last_name || '';
            returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_01_auth_rep_email || returnData.auth_rep_email || '';
            returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_01_acra_bizfile || returnData.acra_bizfile || '';
            returnData.company_constitution = shareholder.company_entity_shareholder_corporate_01_company_constitution || returnData.company_constitution || '';
            returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_01_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
            returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_01_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
            returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_01_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
            break;
    }
    return returnData;
}