/*======================================================================================================================
 * import main libraries
 *====================================================================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { general, api, validate, form } from '../../../helpers/index';
import Subheader from 'material-ui/Subheader/Subheader';
import List from 'material-ui/List';
import { ListItem } from 'material-ui/List';
import * as taskActions from '../../../actions/task';
import PartialTaskChatItem from './PartialTaskChatItem';
import PartialTaskChatInput from './PartialTaskChatInput';
import * as TaskModel from '../../../models/task';
import * as UploadModel from '../../../models/upload';
import { helpers, h } from "../../../helpers";
import FileDrop from 'react-file-drop';
import CommonOverlay from '../../Common/CommonOverlay';
import FontAwesome from "react-fontawesome";
import { config } from '../../../configs/config';

/*======================================================================================================================
 * define react component
 *====================================================================================================================*/
export class PartialTaskChatList extends React.Component {

    constructor () {
        super();
        var additionalFields = {
            companyVitals: {},
            topExpenses: [],
            // react-table checkboxes
            selected: {}, selectAll: 0, data: [], 
            // approval and reject buttons
            disableApproval: true,
            disableReject: true,
            // material-ui dialog
            open: false,
            // material-ui popover
            isPopoverOpen: false,
            anchorEl: '',
            // dialog selected receipt
            selectedReceipt: {},
            // react-tether
            isOpen: true,
            // drag-and-drop file upload overlay
            showUploadOverlay: false,
            // drag-and-drop files
            droppedFiles: [],
            // flag to determine whether to show chat input
            showChatInput: true,
            fields: {
                message: { value: '' }
            }
        };
        this.state = general.initState(additionalFields);
    }

    componentDidMount () {
        this.getTaskMessages();
    }

    getTaskMessages () {
        var self = this;
        self.props.refreshStatuses();
        const { currentEntity, taskId, messageType } = this.props;
        var query = {
            task_id: taskId
        };
        helpers.logHelper.setExtra({taskId: taskId});
        helpers.logHelper.log({subPath: 'add-task'});
        TaskModel.getTaskMessages(query, function (data) {
            var apiRes = api.handleApiResponse(data, false);
            if (apiRes.status === 'ok') {
                self.props.setTaskMessages(apiRes.data.task_messages || []);
                // to reposition dialog to center of screen
                window.dispatchEvent(h.general.createDomEvent('resize'));
            }
        });
    }

    render () {
        const { currentUser, currentEntity, task, taskId, taskMessages, constants } = this.props;
        var status = '';
        if (h.notEmpty(taskMessages) &&
            h.notEmpty(taskMessages[0]) &&
            h.notEmpty(taskMessages[0].task_status)) {
            status = taskMessages[0].task_status;
        }

        return (
            <FileDrop onDrop={this.handleDrop.bind(this)}
                      onFrameDragEnter={this.handleDragOver.bind(this)}
                      onFrameDragLeave={this.handleDragLeave.bind(this)}
                      onFrameDrop={this.handleDragLeave.bind(this)}>
                <div id="chat-container" ref={(el) => {this.chatList = el;}}>
                    <List className="chat-list" key={taskId}>
                        <Subheader>Conversation<a href="#" className="float-right" onClick={this.getTaskMessages.bind(this)}>Refresh</a></Subheader>
                        {taskMessages && taskMessages.map((message, index) => (
                            <PartialTaskChatItem
                                messageIndex={index}
                                index={message.task_message_message_id}
                                profilePic={message.profile_picture}
                                name={message.first_name + ' ' + message.last_name}
                                message={message.task_message_message}
                                date={message.time_ago}
                                isSelf={message.is_self}
                                userFrom={message.task_message_user_from}
                                taskMessage={message}
                                task={task}
                                toggleChatInputDisplay={this.toggleChatInputDisplay.bind(this)}
                                reloadTaskMessages={() => this.getTaskMessages()}
                                taskSwimLaneStatus={status}
                                closeDialog={this.props.closeDialog}
                                droppedFiles={this.state.droppedFiles}
                                resetDroppedFiles={this.resetDroppedFiles.bind(this)}
                            />
                        ))}
                        {
                            status &&
                            !general.cmpStr(status, constants.TASK_STATUS.DONE) &&
                            h.cmpBool(this.state.showChatInput, true) &&
                            <div className="chat-list-item">
                                <PartialTaskChatInput
                                    taskId={taskId}
                                    taskMessages={taskMessages}
                                    droppedFiles={this.state.droppedFiles}
                                    resetDroppedFiles={this.resetDroppedFiles.bind(this)}
                                    reloadTaskMessages={() => this.getTaskMessages()}
                                />
                            </div>
                        }
                    </List>
                </div>
                {h.cmpBool(this.state.showUploadOverlay, true) &&
                <CommonOverlay content={<div>
                    <FontAwesome
                        name='upload'
                        size='2x'
                    /><br/><br/>
                    Upload attachment to task
                </div>}/>}
            </FileDrop>
        );
    }

    handleDrop (files, event) {
        this.setState({droppedFiles: files});
    }

    handleDragOver (event) {
        this.setState({showUploadOverlay: true});
    }

    handleDragLeave (event) {
        this.setState({showUploadOverlay: false});
    }

    resetDroppedFiles () {
        this.setState({droppedFiles: []});
    }

    /**
     * Toggles chat input display
     * @param {boolean} isChatInputVisible
     */
    toggleChatInputDisplay (isChatInputVisible) {
        this.setState({showChatInput: isChatInputVisible});
    }
}

PartialTaskChatList.propTypes = {
    currentUser: PropTypes.object.isRequired,
    currentCompany: PropTypes.object.isRequired,
    currentEntity: PropTypes.object.isRequired,
    constants: PropTypes.object.isRequired,
    userPermissions: PropTypes.object.isRequired,
    taskId: PropTypes.string.isRequired,
    messageType: PropTypes.string,
    taskMessages: PropTypes.array
};

const mapStateToProps = state => ({
    currentUser: state.user,
    currentCompany: state.company.company,
    currentEntity: state.company.currentEntity,
    constants: state.constants,
    userPermissions: state.permission,
    taskMessages: state.task.taskMessages
});

const mapDispatchToProps = {
    setTaskMessages: taskActions.setTaskMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PartialTaskChatList));
