import React from 'react';
import { Step, Stepper, StepLabel, StepContent } from 'material-ui/Stepper';
/**
 * @callback OnStageClickedCallback
 * @param {Stage} prevStage
 * @param {Stage} newStage
 */
/**
 * @typedef {object} CommonStepNavigatorProps
 * @property {Stage[]} stages
 * @property {string} current_stage_id
 * @property {OnStageClickedCallback} onStageClicked
 *
 * @extends {React.Component<CommonStepNavigatorProps>}
 */
export class CommonStepNavigator extends React.Component {
    render() {
        /** @type {Stage[]} */
        let stages = this.props.stages || [];
        let current_stage_id = this.props.current_stage_id;
        let onStageClicked = this.props.onStageClicked || (() => {});
        
        //Derived data
        let stages_idx = stages.reduce((state, stage) => {
            stage[stage.id] = stage;
            return state;
        }, {});

        let current_stage = stages_idx[current_stage_id];

        let activeStep = stages.reduce((state, cur, idx) => {
            if(cur.id === current_stage_id) {
                return idx;
            }
            return state;
        }, 0);

        let visibleStages = stages.filter(stage => ((stage && !stage.hidden) ? true : false));

        //Render
        return <div className="p-3" style={{ overflow: 'scroll' }}>
            <Stepper orientation='horizontal'
                activeStep={activeStep}>
                {visibleStages
                    .map(stage => { //Render the step labels for stages
                        return (
                            <Step
                                key={stage.id}
                                className="common-stepnav"
                                style={{ cursor: 'pointer', borderRadius: '1em' }}>
                                <StepLabel
                                    onClick={() => {
                                        onStageClicked(current_stage, stage);
                                    }}
                                    icon={stepLabelIcon(stage.isComplete)}
                                >{stage.displayName}</StepLabel>
                            </Step>);
                    })}
            </Stepper>
        </div>;
    }
}
function stepLabelIcon(isComplete) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '24px', width: '24px'}}>
        {isComplete ? 
            <i style={{ color: 'green', height: '24px', width: '24px', fontSize: '24px' }} className="material-icons">check_circle_outline</i> :
            <div style={{ height: '20px', width: '20px', borderRadius: '10px', border: '2px solid grey' }} />
        }
    </div>;
}
/**
 * @typedef Stage
 * @property {string} id
 * @property {string} displayName
 * @property {boolean} hidden
 * @property {boolean} isComplete
 */
export default CommonStepNavigator;